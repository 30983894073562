import React from 'react';
import PropTypes from 'prop-types';
import { ARTICLES } from '../../constants/index.js';
import { translateResourceString } from '../../../util/translationUtility';

const ProductSearchResultsNonFound = ({
    zeroProductResultsMessage,
    popularCategories,
    suggestions,
    articlePaginationViewModel,
    onChangeView,
    searchString
}) =>
{ 
    return (
        <div>
            {articlePaginationViewModel.ResultsTotal > 0 ? <h4 class="headline headline-sm pb-2">{translateResourceString("/SearchApp/AlternativeResourcesText")} <a href style={{ cursor: 'pointer' }} onClick={() => onChangeView(ARTICLES)}>{articlePaginationViewModel.ResultsTotal} {articlePaginationViewModel.ResultsTotal > 1 ? "resource results" : "resource result"}</a> for '<span class="search-string">{searchString}</span>'</h4> : ""}
            <div class="pb-3" dangerouslySetInnerHTML={{ __html: zeroProductResultsMessage }} />
            {suggestions && suggestions.length > 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div id="did-you-mean">
                                    <p>
                                        Did you mean {suggestions.map((suggestion, i) => {
                                        return (
                                            <span key={i}>
                                                        <a href={suggestion.Url}>{suggestion.Text}</a>
                                                {i + 1 < suggestions.length ? ", " : ""}
                                                    </span>
                                        );
                                    })}
                                    </p>
                                </div>
                            </div>
                        </div>
            }
            <div class="bg-light-gray related-categories-block py-3">
            <div class="container">
                <h3 class="headline headline-lg color-blue-light pb-2">{translateResourceString("/SearchApp/PopularCategoriesHeadline")}</h3>
                <div class="row">
                    {popularCategories.map((category, i) => 
                    (  <div class="col-md-4 mb-2">
                            <a href={category.PageUrl} class="category-link">
                                <div class="bg-white category-tile border-radius-5">
                                    <h3 class="headline headline-md color-red">{category.Name}</h3>
                                    <span>{category.Description}</span>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div>
        );
};

ProductSearchResultsNonFound.propType = {
    onChangeView: PropTypes.func,
};

export default ProductSearchResultsNonFound;

