import React from 'react';
import { translateResourceString } from '../../../util/translationUtility';

const FeaturedResource = ({
    resource
    }) =>
{ 
    var tileClass = resource.FullSize ? "resource-tile--full" : null;
    var buttonSize = resource.FullSize ? "btn-r-red-outline--lg" : null;
    var textWeight = resource.FullSize ? "fw-600" : null;
    var headerSize = resource.FullSize ? "lg" : "md";
    var headerColor = resource.FullSize ? "color-blue-light" : "color-blue-heading";

    var buttonText = "";
    if (resource.Category)
    {
        buttonText = resource.ButtonText ? translateResourceString("/SearchApp/Resources/ViewNonResource") : translateResourceString("/SearchApp/Resources/ViewResource");
    }
    else
    {
        buttonText = resource.ButtonText;
    }

    return (
            <a href={resource.PageUrl} class="resource-link link-wrapper">
                <div class={"resource-tile "+tileClass+" bg-white h-100"}>
                    <h2 class={"headline headline-"+ headerSize +" "+ headerColor}>{resource.Title}</h2>
                    {resource && resource.Category &&
                        <div class="d-flex justify-content-start pb-2">
                        <img class="icon-image " src={"/static/img/"+resource.IconImage}/>
                        <div class={"text-sm-left pl-1 "+textWeight}>{resource.Category}</div>
                        </div>
                    }
                        <div class="pb-2" dangerouslySetInnerHTML={{__html: resource.Description}} ></div>
                    <div class={"btn btn-r-red-outline "+buttonSize}>{buttonText}</div>
                </div>
            </a>
    );
}

FeaturedResource.propType = {};

export default FeaturedResource;