import React from 'react'
import { connect } from 'react-redux'
import CartItem from './CartItem'
import { CheckoutSection } from '../../components'
import { translateResourceString } from '../../../util/translationUtility';
import { deleteItemAsync, updateItemQuantityAsync, updateItemQuantity } from './cartActionCreators'

const CartList = ({ items, onQuantityChange, onDelete }) => {
    const cartItems = items.map((item) => {
        return (
            <CartItem
                key={item.Id}
                item={item}
                onQuantityChange={onQuantityChange}
                onDelete={onDelete}

            />
        );
    });
    return (
        <CheckoutSection label={'Shopping Cart'}>
            {cartItems}
        </CheckoutSection>
    );
}

const cartListMapStateToProps = (state) => {
    return {
        items: state.entities.CartItems
    }
}

const cartListMapDispatchToProps = (dispatch) => {
    return {
        onQuantityChange: (id, value) => {
            dispatch(updateItemQuantityAsync({ id, qty: value }));
        },
        onDelete: (id, prevQty) => {
            if (typeof window !== 'undefined' && typeof window.confirm !== 'undefined' && window.confirm(translateResourceString("/Checkout/Cart/DeleteItemConfirmation"))) {
                dispatch(deleteItemAsync({ id }))
            } else {
                dispatch(updateItemQuantity(id, prevQty > 0 ? prevQty : 1))
            }
        }
    }
}

const CartListContainer = connect(cartListMapStateToProps, cartListMapDispatchToProps)(CartList);

export default CartListContainer
