import React from 'react';

class AddToCartButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addingToCart: false,
            error: false,
        };

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleAddToCartError = this.handleAddToCartError.bind(this);
        this.handleAddToCartSuccess = this.handleAddToCartSuccess.bind(this);
    }

    handleAddToCart() {
        const {
            code,
            addToCartEndpoint,
            quantity,
        } = this.props;

        this.setState({
            addingToCart: true
        });

        fetch(addToCartEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'fetch'
            },
            body: JSON.stringify({
                code: code,
                quantity: quantity
            })
        })
        .then(response => {
            this.setState({addingToCart: false});
            return response.json();
        })
        .then(json => {
            if(json && json.hasError === false) {
                this.handleAddToCartSuccess();
            } else {
                if(json && json.errorMessage) {
                    console.log(json.errorMessage);
                }
                this.handleAddToCartError();
            }
        })
        .catch(
            error => {
                console.log(error);
                this.handleAddToCartError();
            }
        )
    }

    handleAddToCartError() {
        this.setState({
            error: true,
            addingToCart: false
        });
    }

    handleAddToCartSuccess() {
        this.setState({
            error: false,
            addingToCart: false
        });

        if(window.MiniCart && window.MiniCart.refreshAndShow) {
            window.MiniCart.refreshAndShow();
        }
    }

    render() {
        const {
            text
        } = this.props;
        const {
            addingToCart,
        } = this.state;
        return (
            <button disabled={addingToCart} className="btn btn-r-red" onClick={this.handleAddToCart}>{text}</button>
        );
    }
}

export default AddToCartButton;
