
import {
  NEW_SHIPPING_OPTIONS,
  SELECT_SHIPPING_OPTION,
  NOTIFY_REFRESHING_SHIPPING_OPTIONS,
  NOTIFY_SAVE_SHIPPING_ACCOUNT,
  SAVE_SHIPPING_ACCOUNT_CARRIER
} from '../../GlobalStateContainer'

export const ShippingOptions = function (state = [], action) {
  switch (action.type) {
    case NEW_SHIPPING_OPTIONS:
      return action.shippingOptions;
    default:
      return state;
  }
}

export const AccountNumber = function(state = '', action) {
  switch (action.type) {
    case SAVE_SHIPPING_ACCOUNT_CARRIER:
      return action.shippingAccountNumber;
    default:
      return state;
  }
}

export const SelectedCarrier = function(state = '', action) {
  switch (action.type) {
    case SAVE_SHIPPING_ACCOUNT_CARRIER:
      return action.carrierCode;
    default:
      return state;
  }
}

export const Carriers = function(state = '', action) {
  return state;
}

export const UseMyAccountShippingMethodId = function(state = '', action) {
  return state;
}

export const isUseMyShippingInitializing = function(state = false, action){
  switch (action.type){
    case NOTIFY_SAVE_SHIPPING_ACCOUNT:
      return true;
    case SAVE_SHIPPING_ACCOUNT_CARRIER:
      return false;
    default:
      return state;
  }
}

export const SelectedShippingOptionId = function (state = '', action) {
  switch (action.type) {
    case SELECT_SHIPPING_OPTION:
      return action.id;
    default:
      return state;
  }
}

export const isRefreshingShippingOptions = function (state = '', action) {
  switch (action.type) {
    case NOTIFY_REFRESHING_SHIPPING_OPTIONS:
      return true;
    case NEW_SHIPPING_OPTIONS:
      return false;
    default:
      return state;
  }
}

