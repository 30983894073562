import React from 'react';
import ProductTile from './ProductTile';
import PropTypes from 'prop-types';
import WithClickTracking from '../../components/WithClickTracking';

const ProductTileWithClickTracking = WithClickTracking(ProductTile);

const ProductTileContainer = ({ products, onResultClick }) =>
    <div className="product-list pt-3 pb-3">
            {products.map((product, i) => {
                return (
                    <div key={i} className="row">
                        <div className="col-12">
                            <ProductTileWithClickTracking 
                                {...product}
                                onResultClick={onResultClick}
                            />
                        </div>
                    </div>
                );
            })}
    </div>;

ProductTileContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string,
        SKU: PropTypes.string,
    }))
};

export default ProductTileContainer;