import React from 'react';
import SortByDropdown from '../../components/SortByDropdown';
import PropTypes from 'prop-types';
import { translateResourceString } from '../../../util/translationUtility';

class PaginationContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            attributes,
            paginationViewModel,
            onCurrentSortByChange,
            selectedCategory,
            onSelectCategory,
            onAttributeChange,
            view,
            sortOptions,
        } = this.props;

        const lastItemNumber = paginationViewModel.CurrentItemsOnPage;
        const paginationText = translateResourceString(`/SearchApp/Pagination/${view}/ItemCountFormatted`, lastItemNumber, paginationViewModel.ResultsTotal);

        const FilterBubble = ({displayName, onClick}) =>
            (<div class="selected-category m-1"><span className="mr-1">{displayName}</span> <i className="rockland-icon-close-circle" aria-hidden="true" onClick={onClick} /></div>);

        const selectedAttributesSize = 3;

        const selectedAttributes = [];
        attributes.filter(attribute => attribute.Values.filter(x => x.Checked).map(checkedValue => {
            if (selectedAttributes.length < selectedAttributesSize) {
                selectedAttributes.push(
                    {
                        name: attribute.Name,
                        displayName: attribute.DisplayName,
                        value: checkedValue.Name
                    })
            }
        }));

        const sortBy =
            <div className="form-inline pagination-dropdown-wrapper">
                <SortByDropdown
                    {...paginationViewModel}
                    onCurrentSortByChange={onCurrentSortByChange}
                    sortOptions={sortOptions}
                />
            </div>

        return (
            <div className="pagination-wrapper">
                {selectedAttributes.length > 0 || selectedCategory
                    ?
                    <React.Fragment>
                        <div className="row mb-2">
                            <div className="col-12">
                                <div className="form-inline pagination-dropdown-wrapper">
                                    <label className="total-results hidden-sm hidden-xs">
                                        {paginationText}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 filter-sort-wrapper">
                                <div className="filter-bubble-container mb-1 mb-lg-0">
                                    <span className="filter-by">{translateResourceString("/SearchApp/FilterBy")}</span>:
                                        {selectedCategory && <FilterBubble
                                            displayName={`${selectedCategory.ParentCategory ? selectedCategory.ParentCategory.DisplayName + ": " : ""}${selectedCategory.DisplayName}`}
                                            onClick={() => onSelectCategory(null)}
                                        />}
                                        {selectedAttributes && selectedAttributes.map(attribute => <FilterBubble
                                            displayName={`${attribute.displayName}: ${attribute.value}`}
                                            onClick={() => onAttributeChange(attribute.name, attribute.value)}
                                            />
                                        )}
                                </div>
                                {sortBy}
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between">
                            <label className="total-results hidden-sm hidden-xs">
                                {paginationText}
                            </label>
                            {sortBy}
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
};

PaginationContainer.propTypes = {
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentSortByChange: PropTypes.func,
    onCurrentPageIndexChange: PropTypes.func,
    onShowMobileFacetsChange: PropTypes.func.isRequired,
    selectedCategory: PropTypes.shape({
        DisplayName: PropTypes.string,
    }),
    onSelectCategory: PropTypes.func
};

export default PaginationContainer;
