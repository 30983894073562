import React from 'react';
import PropTypes from 'prop-types';

const MoreButton = (props) =>
<button
className="btn btn-r-red-outline btn-r-red-outline--lg mb-3"
    onClick={props.onClick}
    disabled={props.disabled}
>
    {props.children}
</button>;

MoreButton.propType = {
    onClick: PropTypes.func.isRequired
};

export default MoreButton;
