import React from 'react';
import ArticleSearchResults from '../Articles/ArticleSearchResults';
import ProductSearchResults from '../Products/ProductSearchResults';
import PropTypes from 'prop-types';
import {
    ARTICLES,
    PRODUCTS
} from '../../constants/index.js';
import WithLoading from '../../components/WithLoading';
import { TabContent, TabPane } from 'reactstrap';
import { translateResourceString } from '../../../util/translationUtility';

const CombinedSearchResults = ({ articles,
    articlePaginationViewModel,
    onArticleCurrentItemsPerPageChange,
    onArticleCurrentPageIndexChange,
    onResultClick,
    attributes,
    onAttributeChange,
    onClearAttributes,
    onProductCurrentItemsPerPageChange,
    onProductCurrentSortByChange,
    onArticleCurrentSortByChange,
    onProductCurrentPageIndexChange,
    productPaginationViewModel,
    products,
    view,
    onChangeView,
    isLoading,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    showMoreFacets,
    onToggleShowMoreFacet,
    categories,
    onCategoryPage,
    onCategorySelect,
    selectedCategory,
    openCategories,
    onToggleCategoryOpen,
    searchString,
    articleAttributes,
    onArticleAttributeChange,
    expandedArticleFacets,
    zeroProductResultsMessage,
    popularCategories,
    zeroResourceResultsMessage,
    featuredResources,
    resourceTypes,
    suggestions,
    onCurrentProductsPerPageChangeHref,
    onCurrentArticlesPerPageChangeHrf
}) => {
    const showArticles = view === ARTICLES;
    const showProducts = !showArticles;

    return (
        <div className="combined-search-wrapper">
                <div className="tab-container">
                        <div className="container">
                            <button 
                                className={`tab ${showProducts ? "active" : ""}`}
                                onClick={()=>onChangeView(PRODUCTS)}
                            >
                                {translateResourceString("/SearchApp/ProductsTabText")} <span className="count">({productPaginationViewModel.ResultsTotal})</span>
                            </button>
                            <button 
                                className={`tab ${showArticles ? "active" : ""}`}
                                onClick={()=>onChangeView(ARTICLES)}
                            >
                                {translateResourceString("/SearchApp/ArticlesTabText")} <span className="count">({articlePaginationViewModel.ResultsTotal})</span>
                            </button>
                        </div>
                    </div>
                <div className="container">
                    <TabContent activeTab={view}>
                        <TabPane tabId={PRODUCTS}>
                            <ProductSearchResultsWithLoading
                                    attributes={attributes}
                                    onAttributeChange={onAttributeChange}
                                    onClearAttributes={onClearAttributes}
                                    onCurrentItemsPerPageChange={onProductCurrentItemsPerPageChange}
                                    onCurrentSortByChange={onProductCurrentSortByChange}
                                    onCurrentPageIndexChange={onProductCurrentPageIndexChange}
                                    paginationViewModel={productPaginationViewModel}
                                    articlePaginationViewModel={articlePaginationViewModel}
                                    products={products}
                                    onResultClick={onResultClick}
                                    onChangeView={onChangeView}
                                    isLoading={isLoading}
                                    onShowMobileFacetsChange={onShowMobileFacetsChange}
                                    expandedFacets={expandedFacets}
                                    onToggleFacetExpanded={onToggleFacetExpanded}
                                    showMoreFacets={showMoreFacets}
                                    onToggleShowMoreFacet={onToggleShowMoreFacet}
                                    categories={categories}
                                    onCategoryPage={onCategoryPage}
                                    onCategorySelect={onCategorySelect}
                                    selectedCategory={selectedCategory}
                                    openCategories={openCategories}
                                    onToggleCategoryOpen={onToggleCategoryOpen}
                                    searchString={searchString}
                                    zeroProductResultsMessage={zeroProductResultsMessage}
                                    popularCategories={popularCategories}
                                    suggestions={suggestions}
                                    onCurrentItemsPerPageChangeHref={onCurrentProductsPerPageChangeHref}
                                />
                        </TabPane>
                        <TabPane tabId={ARTICLES}>
                            <ArticleSearchResultsWithLoading
                                    articles={articles}
                                    paginationViewModel={articlePaginationViewModel}
                                    onCurrentItemsPerPageChange={onArticleCurrentItemsPerPageChange}
                                    onCurrentPageIndexChange={onArticleCurrentPageIndexChange}
                                    onCurrentSortByChange={onArticleCurrentSortByChange}
                                    onResultClick={onResultClick}
                                    isLoading={isLoading}
                                    searchString={searchString}
                                    attributes={articleAttributes}
                                    onAttributeChange={onArticleAttributeChange}
                                    expandedArticleFacets={expandedArticleFacets}
                                    zeroResourceResultsMessage={zeroResourceResultsMessage}
                                    featuredResources={featuredResources}
                                    resourceTypes={resourceTypes}
                                    onCurrentItemsPerPageChangeHref={onCurrentArticlesPerPageChangeHrf}
                                />
                        </TabPane>
                    </TabContent>
                </div>
        </div>
    );
};

const ArticleSearchResultsWithLoading = WithLoading(ArticleSearchResults);
const ProductSearchResultsWithLoading = WithLoading(ProductSearchResults);

CombinedSearchResults.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    articlePaginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onArticleCurrentItemsPerPageChange: PropTypes.func,
    onArticleCurrentPageIndexChange: PropTypes.func,
    onResultClick: PropTypes.func,
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    onProductCurrentItemsPerPageChange: PropTypes.func, 
    onProductCurrentSortByChange: PropTypes.func, 
    onProductCurrentPageIndexChange: PropTypes.func, 
    productPaginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    view: PropTypes.string,
    onChangeView: PropTypes.func,
    onShowMobileFacetsChange: PropTypes.func.isRequired,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    onCategoryPage: PropTypes.bool,
    onCategorySelect: PropTypes.func,
    onCurrentProductsPerPageChangeHref: PropTypes.string
};

export default CombinedSearchResults;
