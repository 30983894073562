export function closeKeyboardOnScroll(e) {
    document.activeElement.blur();
    scrollUp();
    document.addEventListener("touchend", scrollUp);
}
export function scrollUp() {
    //window.ontouchmove = "preventDefault"; // mobile
    window.scrollTo(0, 0);
    document.removeEventListener("touchend", scrollUp);

}