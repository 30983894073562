// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import find from 'lodash/find'

import { saveAddress } from "./addressActionCreators"
import { AddressActionCreatorsShape as AddressActionCreators } from "./propTypes"
import AddressEditor from './AddressEditor'

const mapStateToProps = (state, ownProps) => {
  return {
    countriesStatesMap: state.constants.countriesStatesMap,
    countries: state.constants.countries,
    address: find(state.entities.Addresses, 
      address => address.Id === ownProps.addressId),
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onEditOk: address => {
      let addressAction;
      let newId = 0;
      if (typeof (address.Id) !== 'undefined' && address.Id) {
        addressAction = ownProps.addressActionCreators.update(address.Id, address);
        ownProps.onAddressUpdated(address.Id);
      } else {
        addressAction = ownProps.addressActionCreators.add(address);
        newId = addressAction.address && addressAction.address.Id;
        ownProps.onAddressCreated(address.Id);
      }
      dispatch(addressAction);
      newId && dispatch(ownProps.addressActionCreators.select(newId));
      dispatch(saveAddress(Object.assign({}, { Id: newId }, address)));
    },
    onEditCancel: ownProps.onEditCancel
  }
}

const AddressEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressEditor);

AddressEditorContainer.propTypes = {
  addressId: PropTypes.string,
  addressActionCreators: AddressActionCreators.propTypes.isRequired,
  onAddressCreated: PropTypes.func.isRequired,
  onAddressUpdated: PropTypes.func.isRequired
}

export default AddressEditorContainer;