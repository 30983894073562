import React from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'

import {
    selectPaymentOption,
    sendPaymentMethodToGtmDatalayer
} from './paymentMethodActionCreators'

import {
    OptionListItemRadio,
    CheckoutSection,
    labelWithIconStyle,
    iconContainerStyle,
    iconTextStyle,
    iconStyle
} from '../../components'

import { Fade } from '../../componentAnimations'

const getById = (methods, id) => {
    return methods.filter(pm => pm.Id === id)[0];
}

class CheckoutPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isCollapsed: false }
        this.onItemSelected = this.onItemSelected.bind(this)
        this.onClickChange = this.onClickChange.bind(this)
        this.onPaymentIsValid = this.onPaymentIsValid.bind(this)
    }

    onPaymentIsValid() {
        this.props.sendPaymentMethodToGtmDatalayer();
        this.setState({ isCollapsed: true });
    }

    onItemSelected(id) {
        const paymentOption = getById(this.props.paymentOptions, id);
        if (!paymentOption) {
            console.error(`No payment method with id '${id}'`);
            return;
        }
        if (!paymentOption.paymentOptionComponent) {
            this.setState({ isCollapsed: true });
        }
        this.props.onSelected(id, paymentOption.Name);
    }

    onClickChange(e) {
        e.preventDefault();
        this.setState({ isCollapsed: !this.state.isCollapsed });
    }

    render() {
        const paymentMethodIcons = {
            'Purchase Order': null,
            'Credit Card': 'fas fa-xs fa-credit-card',
        };
        const items = this.props.paymentOptions.map(pm => {
            let selected = pm.Id === this.props.selectedPaymentOptionId;
            const visible = !this.props.selectedPaymentOptionId || selected;
            const iconClass = paymentMethodIcons[pm.Name] || '';
            const labelWithIcon = (
                <span style={labelWithIconStyle}>
                    <span style={iconContainerStyle}>
                        {iconClass ? (
                            <i className={iconClass} style={iconStyle} aria-hidden="true"></i>
                        ) : (<span style={iconTextStyle}>PO</span>)}
                    </span>
                    {pm.Name}
                </span>
            );
            if (pm.paymentOptionComponent) {
                const PaymentOptionComponent = pm.paymentOptionComponent;

                return (
                    <OptionListItemRadio
                        key={pm.Id}
                        id={pm.Id}
                        isSelected={selected}
                        onClick={this.onItemSelected}
                        label={labelWithIcon}
                        hidden={!visible}>
                        <div className='row'>
                            <PaymentOptionComponent
                                isSelected={selected}
                                onPaymentIsValid={this.onPaymentIsValid}
                                onPaymentCancel={this.props.onCancel}
                            />
                        </div>
                    </OptionListItemRadio>
                )
            } else {
                return (<OptionListItemRadio
                    key={pm.Id}
                    id={pm.Id}
                    isSelected={selected}
                    onClick={this.onItemSelected}
                    label={labelWithIcon}
                    hidden={!visible} />)
            }
        });
        const canChange = true;
        return (
            <CheckoutSection label={"Payment Information"} hidden={false}>
                <div className="d-flex justify-content-between" onClick={(e) => this.onClickChange(e)} style={{ cursor: 'pointer' }}>
                    {this.props.selectedDisplay ? <span>{this.props.selectedDisplay}</span> : <span>Select Payment Method</span>}
                        {canChange &&
                        (this.state.isCollapsed ? (<a href=""><i className="fas fa-caret-down"></i></a>)
                            : (<a href=""><i className="fas fa-caret-up"></i></a>))}
                </div>
                <Fade inProp={!this.state.isCollapsed}>
                    <div className="mt-1" style={{ display: this.state.isCollapsed ? 'none' : 'inherit' }}>
                        <hr/>
                        {items}
                    </div>
                </Fade>
            </CheckoutSection>
        );
    }
}

const formatPayment = ({ constants, entities }) => {

    const formatPaypalPayloadDetailsForSummary = (det) => {
        if (det) {
            if (typeof (det.firstName) !== 'undefined' && typeof (det.lastName) !== 'undefined' && typeof (det.email) !== 'undefined') {
                return `${det.firstName.length > 0 ? det.firstName + " " : ""}${det.lastName.length > 0 ? det.lastName + " " : ""} (${det.email})`;
            } else if (typeof (det.email) !== 'undefined' && det.email.length > 0) {
                return det.email;
            }
        }
        return "unspecified";
    }

    let formatters = [
        {
            Id: constants.purchaseOrderPaymentId,
            format: ({ POValue }) =>
                (`Purchase Order ${((POValue && POValue.length > 0) ? " #" : "")}${POValue || ""}`)
        },
        {
            Id: constants.braintreeSettings.braintreePaymentId,
            format: ({ BraintreePaymentPayload }) => {
                if (!BraintreePaymentPayload) {
                    return "Pending";
                } else {
                    if (BraintreePaymentPayload.type === "PayPalAccount") {
                        return `Paypal: ${formatPaypalPayloadDetailsForSummary(BraintreePaymentPayload.details)}`;
                    } else if (BraintreePaymentPayload.type === "CreditCard") {
                        return `Card: ${BraintreePaymentPayload.description}`
                    } else {
                        return "Unknown payment method";
                    }

                }
            }
        }
    ];
    const formatter = find(formatters, x => x.Id === entities.SelectedPaymentOptionId);
    if (formatter) {
        return formatter.format(entities.PaymentRecord);
    }
}

const mapCheckoutPaymentStateToProps = (state) => {
    return {
        paymentOptions: state.entities.PaymentOptions,
        selectedPaymentOptionId: state.entities.SelectedPaymentOptionId,
        selectedDisplay: formatPayment(state)
    }
}

const mapCheckoutPaymentDispatchToProps = (dispatch) => {
    return {
        onSelected: (id, displayName) => dispatch(selectPaymentOption(id, displayName)),
        onCancel: () => dispatch(selectPaymentOption('')),
        sendPaymentMethodToGtmDatalayer: () => dispatch(sendPaymentMethodToGtmDatalayer())
    }
}

const CheckoutPaymentContainer = connect(
    mapCheckoutPaymentStateToProps,
    mapCheckoutPaymentDispatchToProps
)(CheckoutPayment);


export default CheckoutPaymentContainer
