export function PhoneNumber(state = 'tel:+14144254069', action) {
  return state;
}

export function PhoneText(state = '(414) 425-4069', action) {
  return state;
}

export function completeCheckoutButtonText(state = '', action) {
  return state;
}
