import find from 'lodash/find'

export const mapStateToPlaceOrderDetails = (state) => {
  const entities = state.entities;
  const shippingAddress = find(entities.Addresses, a => a.Id === entities.SelectedShippingAddressId);
  const billingAddress = (state.ui.useShippingForBilling)
    ? shippingAddress
    : find(entities.Addresses,
        address =>
          address.Id === entities.SelectedBillingAddressId
          && entities.BillingAddresses.includes(address.Id));
  const shippingOption = entities.ShippingOptions.filter(so => so.Id === entities.SelectedShippingOptionId)[0];
    const isUseMyAccountShipping = (shippingOption && shippingOption.Id == entities.useMyAccountShippingSettings.UseMyAccountShippingMethodId);
    const userComments = state.entities.Comments.userCommentsValue;

    let carrier = entities.useMyAccountShippingSettings.SelectedCarrier;
  let accountNumber = entities.useMyAccountShippingSettings.AccountNumber;
  let shippingAccountCarrierNumber = null;
  if(isUseMyAccountShipping && accountNumber && accountNumber !== ''){
      shippingAccountCarrierNumber = carrier + '-' + accountNumber;
  }
  return {
    SelectedShippingAddress: shippingAddress,
    SelectedBillingAddress: billingAddress,
    SelectedShippingOption: shippingOption,
    ShippingAccountCarrierNumber: shippingAccountCarrierNumber,
    Coupons: state.entities.Coupons,
    SelectedPayment: {
      Id: entities.SelectedPaymentOptionId,
      POValue: entities.PaymentRecord.POValue,
      BraintreePaymentNonce: entities.PaymentRecord.BraintreePaymentNonce
    },
      BillingEqualsShipping: state.ui.useShippingForBilling,
      UserCheckOutComments: userComments
  };
}
