import React from 'react';
import PropTypes from 'prop-types';

const MoreLink = (props) =>
    <a
        className="btn btn-r-red-outline btn-r-red-outline--lg mb-3"
        onClick={props.onClick}
        disabled={props.disabled}
        href={props.href}
    >
        {props.children}
    </a>;

MoreLink.propType = {
    onClick: PropTypes.func.isRequired
};

export default MoreLink;
