import React from 'react'
import { Transition } from 'react-transition-group'

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

// BBS DDD 2017-11-28 VS reports an error between the "Transition" 
// element name and the "in" attribute in the openting tag  b/ c "in"
// is an ES6 reserved word, but it appears to "just work" despite the error
export const Fade = ({ inProp, children, disableAnimation }) => (
  (disableAnimation)
    ? (<div display='none'>{children}</div>)
    : (<Transition in={inProp} timeout={duration} mountOnEnter={true} unmountOnExit={true} appear={true}>
      {(state) => (
        <div style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
        {children}
        </div>
      )}
    </Transition>)
  );