import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { translateResourceString } from '../../util/translationUtility';

class SortByDropdown extends React.Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const { CurrentSortBy, onCurrentSortByChange, sortOptions } = this.props;
        
        // Need to go through array and find when it matches
        const found = sortOptions.find(option => {
            return option.value === CurrentSortBy;
        });

        const selectedSort = found ? found : sortOptions[0];
        const title = 
        <div className="d-flex justify-content-between align-items-center">
            <span>
                <span className="hidden-xxs sort-by d-block">{translateResourceString("/SearchApp/SortBy")}</span> 
                <span className="sort-by-selection">{selectedSort.displayName} </span>
            </span>
            <i className="fa fa-angle-down" />
        </div>;
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                id={`sort-by-dropdown`}
            >
                <DropdownToggle>
                    {title}
                </DropdownToggle>
                <DropdownMenu>
                    {sortOptions.map((option, i) => {
                        return (
                            <DropdownItem
                                key={i}
                                active={selectedSort === option}
                                onClick={() => onCurrentSortByChange(option.value)}
                            >
                                {option.displayName}
                            </DropdownItem>
                        );
                    })

                    }
                </DropdownMenu>
            </Dropdown>
        );
    }
};

SortByDropdown.propTypes = {
    CurrentSortBy: PropTypes.string,
    onCurrentSortByChange: PropTypes.func
};

export default SortByDropdown;