import {
  BRAINTREEE_PAYMENT_DATA_UPDATE,
  PURCHASE_ORDER_PAYMENT_DATA_UPDATE,
  SELECT_PAYMENT_OPTION,
  NOTIFY_COMPLETE_CHECKOUT_ERROR
} from '../../GlobalStateContainer'

export const PaymentOptions = function (state = [], action) {
  return state;
}

export const SelectedPaymentOptionId = function(state = '', action) {
  switch (action.type) {
    case SELECT_PAYMENT_OPTION:
      return action.paymentMethodId || '';
    case NOTIFY_COMPLETE_CHECKOUT_ERROR:
      return '';
    default:
      return state;
  }
}

export const PaymentRecord = function (state = {}, action) {
  switch (action.type) {
    case BRAINTREEE_PAYMENT_DATA_UPDATE:
      return Object.assign({}, state,
        {
          BraintreePaymentNonce: action.payload && action.payload.nonce,
          BraintreePaymentPayload: action.payload
        });
    case PURCHASE_ORDER_PAYMENT_DATA_UPDATE:
      return Object.assign({}, state, { POValue: action.number });
    case NOTIFY_COMPLETE_CHECKOUT_ERROR:
      return Object.assign({}, state, { POValue: null, BraintreePaymentNonce: null, BraintreePaymentPayload: null });
    default:
      return state;
  }
}