import merge from 'lodash/merge'

const validateArguments = (
  {
    notify,
    extract,
    onSuccess,
    onError,
    init
  }, endpoint) => {
  // Check that we have what we expect
  if (typeof notify !== 'function') {
    throw new Error("expected notify to of type 'function'");
  }
  if (typeof extract !== 'function') {
    throw new Error("expected extract to of type 'function'");
  }
  if (typeof onSuccess !== 'function') {
    throw new Error("expected success to of type 'function'");
  }
  if (typeof onError !== 'function') {
    throw new Error("expected success to of type 'function'");
  }
  if (typeof endpoint !== 'string') {
    throw new Error("expected endpoint to be a string or return one when passed the store");
  }
  if (init && typeof init !== 'object') {
    throw new Error("expected init to be an object");
  }
}


/*---------------------------------------------------------
 * 
 *   function: fetchJson(spec)
 *
 *---------------------------------------------------------
 * 
 *   For example, in action.js:
 *
 *      var myAsyncDataFetchSpec = {
 *         // Get the endpoint path from state (or simply specify a string for endpoint)
 *         endpoint: (state) => state.myEndpointPath, 
 *
 *		     // The fetch should post and include cookies, and
 *         // include our headers (not necessarily custom)
 *         init: { method: 'POST', credentials: 'same-origin', headers: { "X-Custom-Header": "ServerHelpMe"} }

 *         // Tell everyone we started my action
 *         notify: data => ( { type: FETCH_MY_DATA, data } ),
 *
 *         // Get the data from the response
 *         extract: r => r.json(),
 *
 *		     // When we get the data, send it to those concerned
 *         onSuccess: (json, data) => ({ type: MY_JSON_DATA_RECEIVED, json, data.Id }),
 *
 *		     // If we fail, tell someone
 *         onError: (msg, data)  => {
 *  				  console.log(msg);
 *	    		  console.log(data);
 *		    	  `We were trying my action for data with id '${data.Id}', but '${msg}' happened`;
 *				 },
 *      };
 *      export const fetchMyData = fetchJson(myAsyncDataFetchSpec);
 *
 *
 *  A factory for creating async action creators for the redux-thunk 
 *  library.
 *  https://redux.js.org/docs/advanced/AsyncActions.html
 *  https://github.com/gaearon/redux-thunk
 *  With redux-thunk, when store.dispatch() is called with a function 
 *  parameter (instead of calling store.dispatch() with an 
 *  action parameter, as usual), the function is called with
 *  the parameters store.dispatch() and store.getState(). So, we can 
 *  dispatch additional actions and inspect the current state.
 *
 *  We also use the fetch api 
 *  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 *  To create an async action creator, pass a object specifying:
 *    * the actions to take when
 *      1) the async flow begins,
 *      2) the async flow succeeds
 *      3) the async flow fails.
 *    * How to extract the data from the response body
 *      (see using_fetch above from MDN)
 *    * How to initalize the fetch request, beyond the content type of json
 *      (see using_fetch above)
 *
 *   A spec object is:
 *   {
 *      init,       // an object to deep merge with the init
 *  						    // object for a fetch call headers:
 *  						    // { "Content-Type", "application/json "}, body }
 *  						    // where body is the data transformed with
 *  						    // JSON.stringify(data) if data is truthy.
 *  						    // If data is falsy, then body is omitted
 *  						    // from the init object passed to fetch
 *                  
 *      endpoint,    // either 1) a string specifying the location
 *  						    // of the fetch call to make or 2) a funciton
 *  						    // that returns a string specifying the location
 *  						    // of the fetch call to make when passed the
 *  						    // result of store.getState().
 *                  
 *      notify,     // The action creator to call when starting
 *  					      // an async action. We pass the data to be 
 *  						    // sent in the request as a parameter. 
 *  						    // Generally a notification action that the
 *  						    // sync request has started.
 *
 *      extract,    // The function to call on the response which
 *   						    // extracts the data. Usually something like
 *                  //   r => r.json()
 *                  // or one of: arrayBuffer, blob, json, text,
 *                  // or formData. See
 *                  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Body
 *
 *      onSuccess,  // The "action creator" to call when the 
 *						      // response is successfully parsed. Both the 
 *						      // successfully parsed response and the 
 *						      // original data that was sent in the request 
 *					        // are passed as parameters. Here, you might
 *                  // validate your data and generate an error
 *                  // action if validation fails, or an update-ish
 *                  // action if validation succeeds
 *                  
 *      onError,    // The "action creator" to call when the 
 *					        // response cannot be retrieved. Passed
 *                  // a diagnostic (not appropriate to show 
 *					        // to end users, but shouldn't contain
 *                  // sensitive information).
 *   }
 * 
 *   The result of passing spec to fetchJson is a function that
 *   dispatches an action when passed the request data. The action will
 *   trigger the dispatch of other actions before and after it executes
 *   an asynchronous call to the endpoint.
 *
 *
 *   Doing a GET
 *   -----------
 *
 *   Omitting the data and doing a simple http GET to an endpoint:
 *   If data is omitted from the call to the async function, then no
 *   body is passed. The default fetch http action is "GET". (note that
 *   the default fetch behavior is to NOT send cookies, so you won't be
 *   authorized). 
 * 
 *   Getting data should be as easy as:
 *
 *     var page = getPageNumber()
 *     var getMyData = {
 *       init: {},
 *       endpoint: "https://example.com/api/serviceName" + (page ? "?p=" + page : ""),
 *       notify: { type: FETCH_MY_DATA, page },
 *       extract: r => r.json(),
 *       onSuccess: json => {type: UPDATE_MY_DATA_TYPE, json, page},
 *       onError: msg => notifyError("sorry, but something didn't go right on page " + page, msg)
 *     }
 *
 *  Then, in the reducer listening to the action UPDATE_MY_DATA update the
 *  redux state based on the json received. See redux examples in more
 *  authoritative locations for smart ways to update your state after
 *  a call to an api.
 *
 *  There are probably better ways of doing this
 *  https://stackoverflow.com/questions/34930735/pros-cons-of-using-redux-saga-with-es6-generators-vs-redux-thunk-with-es2017-asy?rq=1
 *  https://stackoverflow.com/questions/34570758/why-do-we-need-middleware-for-async-flow-in-redux?rq=1
 * 
 */
export const fetchJson = spec => data => (dispatch, getState) => {
  const {
    notify,
    extract,
    onSuccess,
    onError,
    init
	} = spec;
  let {
    endpoint
  } = spec;
  
  if (typeof endpoint === 'function') {
    // Get the endpoint if we want to compute it
    endpoint = endpoint(getState());
  }
  
  validateArguments(spec, endpoint);

  // Notify async action starting
  dispatch(notify(data));

  // Configure the fetch
  let fetchInit = (typeof data !== 'undefined') 
    ? merge(
      {
        headers: { "Content-Type": "application/json",
        "X-Requested-With": "XmlHttpRequest"
        },
        body: JSON.stringify(data)
      },
      init)
    : Object.assign({}, init);

  // Make the call
  return fetch(endpoint, fetchInit)
    .then(  // Get the response if we have one
      response => {
        if (!response.ok) {
          dispatch(onError(`Got server error when calling '${endpoint}': (${response.status}):   ${response.statusText}`, data));
          return null;
        } else {
          return extract(response);
        }
      },
      // handle errors that don't have a status code
      error => dispatch(onError(`Got network error when calling '${endpoint}': ${error}`, data))
    )
    .then(  // Do something with whatever was extracted from the response
      responseExtracted => {
        console.log(responseExtracted);
        if (responseExtracted === null || typeof responseExtracted !== "undefined")
          dispatch(onSuccess(responseExtracted, data, getState));
      },
      // notify of other errors 
      error => dispatch(onError(`Error getting response: ${error}`, data))
    );
}
