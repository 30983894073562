import React from 'react'
import PropTypes from 'prop-types'
import { translateResourceString } from '../../../util/translationUtility';

const NewListModalLink = ({
    ToggleNewListModal
}) => {

    return (
        <button className="create-list" onClick={(e) => {
            e.preventDefault()
            ToggleNewListModal(true)
        }}>
            <div className="list-option" role="group">
                <h4 className="headline headline-sm">+ {translateResourceString("/Wishlists/CreateAList")}</h4>
            </div>
        </button>
    );
};

NewListModalLink.propType = {
    ToggleNewListModal: PropTypes.func.isRequired
};

export default NewListModalLink;
