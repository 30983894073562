class DropdownOption {
    constructor(displayName, value, title) {
        this.displayName = displayName;
        this.value = value;
        this.title = title;
    }

    title() {
        return this.title;
    }

    displayName() {
        return this.displayName;
    }

    value() {
        return this.value;
    }
}

export default DropdownOption;