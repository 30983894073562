// eslint-disable-next-line
import React from 'react'
import { connect } from 'react-redux'

import { dismissMessage, dismissAllMessages } from './messagesListActionCreators'

import MessagesList from './MessagesList'

const mapStateToProps = state => ({
    messages: state.ui.messages
});

const mapDispatchToProps = dispatch => ({
    onDismiss: (id) => dispatch(dismissMessage(id)),
    onDismissAll: () => dispatch(dismissAllMessages())
})

const MessagesListContainer = connect(mapStateToProps, mapDispatchToProps)(MessagesList)

export default MessagesListContainer