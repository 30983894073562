import { connect } from 'react-redux'

import CheckoutComments from './CheckoutComments'
import { updateCheckoutUserComments } from './commentsActionCreators'

const mapCheckoutCommentsStateToProps = (state, ownProps) => {
    return {
        userCheckoutComments: state.entities.Comments.userCommentsValue,
        ...ownProps
    };
}

const mapCheckoutCommentsDispatchToProps = dispatch => ({
    onCheckoutCommentsChange: (e) => (e && e.target && dispatch(updateCheckoutUserComments(e.target.value)))
})

const CheckoutCommentsContainer = connect(mapCheckoutCommentsStateToProps, mapCheckoutCommentsDispatchToProps)(CheckoutComments);

export default CheckoutCommentsContainer
