import React from 'react';
import FeaturedResources from '../Resources/FeaturedResources';
import { translateResourceString } from '../../../util/translationUtility';
import Flickity from 'react-flickity-component'

const ArticleSearchResultsNonFound = ({
    zeroResourceResultsMessage,
    featuredResources,
    resourceTypes
}) =>
{ 
    const flickityOptions = {
        wrapAround: true,
        contain: true,
        initialIndex: 2,
        pageDots: false,
        prevNextButtons: true, 
        arrowShape : "M 60,10 L 50,0 L 3,51 L 50,100 L 60,90 L 29,59 H 119 V 43 H 28 Z",
        visibleItemsCount: 5
    }
    return (
        <div>
            <div class="pb-3" dangerouslySetInnerHTML={{__html: zeroResourceResultsMessage}} />
            
            {resourceTypes && resourceTypes.length > 0 && 
                <div class="bg-light-gray related-categories-block py-3 mb-3">
                    <div class="container">
                            <h3 class="headline headline-lg color-blue-light pb-2">{translateResourceString("/SearchApp/ViewAllResourcesByType")}</h3>
                            
                            <Flickity
                                className={'resource-type__carousel'} // default ''
                                elementType={'div'} // default 'div'
                                options={flickityOptions} // takes flickity options {}
                                disableImagesLoaded={false} // default false
                                reloadOnUpdate // default false
                                static // default false
                            >
                                {resourceTypes.map((resource, i) => 
                                <div class="m-2 col-12 col-sm-6 col-md-2 text-center card resource-tile">
                                        <a class="text-lg" href={resource.PageUrl}>
                                            <div class={"rockland-icon-" + resource.Icon}></div>
                                            <div class="pt-1 ">{resource.Category}</div>      
                                        </a>      
                                </div>                
                                )} 
                            </Flickity>
                          
                    </div>
                </div>
             }
            
            {featuredResources && 
                     <FeaturedResources 
                        featuredResources={featuredResources}
                     />           
             }

        </div>
        );
};

ArticleSearchResultsNonFound.propType = {};

export default ArticleSearchResultsNonFound;
