const miniCart = window && window.MiniCart || {}
const client = {
    refresh() {
        return miniCart.refresh && miniCart.refresh();
    },
    show() {
        miniCart.show && miniCart.show();
    },
    hide() {
        miniCart.hide && miniCart.hide();
    },
    refreshAndShow() {
        miniCart.refreshAndShow && miniCart.refreshAndShow();
    }
}

export default client;