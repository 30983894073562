import React from 'react';
import PropTypes from 'prop-types';
import Facet from './Facet';

const Facets = ({
    attributes,
    onAttributeChange,
    onClearAttributes,
    expandedFacets,
    onToggleFacetExpanded,
    updateOnToggle,
    showMoreFacets,
    onToggleShowMoreFacet,
    disableToggle
}) =>
    <React.Fragment>
        {attributes && attributes.length > 0 &&
            <div className="facets">
                {
                    attributes.map((attribute, i) => {
                        const expanded = expandedFacets.indexOf(attribute.Name) > -1;
                        const showMore = showMoreFacets.indexOf(attribute.Name) > -1;
                        return (
                            <Facet
                                key={i}
                                name={attribute.Name}
                                displayName={attribute.DisplayName}
                                values={attribute.Values}
                                onAttributeChange={onAttributeChange}
                                expanded={expanded}
                                onToggleFacetExpanded={onToggleFacetExpanded}
                                updateOnToggle={updateOnToggle}
                                showMore={showMore}
                                onToggleShowMore={onToggleShowMoreFacet}
                                disableToggle={disableToggle}
                            />
                        );
                    })
                }
            </div>
        }
    </React.Fragment>;

Facets.propTypes = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    updateOnToggle: PropTypes.bool,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
};

export default Facets;