export const hiddenAnchorStyle = {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 'normal',
    padding: 0,
    display: 'flex',
    verticalAlign: 'middle',
    textAlign: 'left',
    justifyContent: 'space-between',
    width: '100%'
};

export const optionListItemStyle = {
    listStyle: 'none',
    lineHeight: 2
}

export const pretendAnchorStyle = {
    color: '#c72c2c',
    textDecoration: 'none',
    fontWeight: 600
}

export const flexMiddle = {
  display: 'flex',
  verticalAlign: 'middle'
}

export const labelWithIconStyle = {
    display: 'flex',
    alignItems: 'center',
};

export const iconContainerStyle = {
    backgroundColor: 'white',
    width: '35px',
    height: '25px',
    marginRight: '10px',
    marginLeft: '4px',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
};

export const iconTextStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000',
    display: 'block',
    textAlign: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    minWidth: 0,
};

export const iconStyle = {
    color: '#000',
};

