import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'

import { AddressShape, AddressActionCreatorsShape as AddressActionCreators } from './propTypes'

import AddressDisplay from './AddressDisplay';
import { RadioInput } from '../../components';
import ActiveAddressInAddressSelectorListContainer from './ActiveAddressInAddressSelectorListContainer'
import { translateResourceString } from '../../../util/translationUtility';

class AddressSelectorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAddressId: ''
        };

        this.onDeleted = this.onDeleted.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onRadioSelected = this.onRadioSelected.bind(this);
    }

    beginEdit(Id) {
        if (this.state.activeAddressId !== '') {
            // Only set active if we had an active before
            this.setState({ activeAddressId: Id });
        }
        this.props.onBeginEdit(Id);
    }

    onRadioSelected(id) {
        this.setState({ activeAddressId: id });
        this.props.onAddressSelected(id);
    }

    onSelected(id) {
        this.props.onAddressSelected(id);
        this.props.onDoneSelecting(id);
        this.setState({ activeAddressId: id });
    }

    onDeleted(id) {
        this.setState({ activeAddressId: '' });
    }

    onItemClick(id) {
        this.setState({ activeAddressId: id });
        this.props.onAddressSelected(id);
    }

    render() {
        const listComponent = this;
        const props = this.props;
        const listItems = this.props.addresses.map((address) => {
            const isSelected = props.selectedAddressId === address.Id;
            const focused = props.selectedAddressId === address.Id;
            if (focused) {
                return (
                    <ActiveAddressInAddressSelectorListContainer
                        key={address.Id}
                        address={address}
                        selectAddressActionCreator={props.addressActionCreators.select}
                        deleteAddressActionCreator={props.addressActionCreators.remove}
                        selectAddressMessage={props.selectAddressMessage}
                        onBeginEdit={(id) => listComponent.beginEdit(id)}
                        isSelected={isSelected}
                        onSelected={() => listComponent.onSelected(address.Id)}
                        onRadioSelected={() => listComponent.onRadioSelected(address.Id)}
                        onDeleted={() => listComponent.onDeleted(address.Id)}
                    />
                );
            } else {
                return (
                    <div
                        key={address.Id}
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingTop: '1rem', paddingBottom: '1rem' }}
                    >

                        <div
                            className={`address ${isSelected ? 'selected' : ''}`}
                            style={{ display: 'flex' }}
                        ><hr/>
                            <RadioInput
                                selected={isSelected}
                                onClick={() => listComponent.onRadioSelected(address.Id, listComponent.props.useForAnotherSelected)}
                            />
                            <span style={{ cursor: 'pointer' }}
                                onClick={(e) => { e.preventDefault(); listComponent.onItemClick(address.Id); }}
                            >
                                <AddressDisplay address={address} compact />
                            </span>
                        </div>
                    </div>
                );
            }
        });

        return (
            <div>
                {listItems}
                {props.allowAdding && <button
                    href='#'
                    className='btn btn-r-red-outline'
                    style={{ marginTop: 15 }}
                    onClick={() => this.beginEdit()}>{translateResourceString("/Checkout/Address/AddNewButton")}</button>}
            </div>
        )
    }
}

AddressSelectorList.propTypes = {
    addressActionCreators: PropTypes.shape(AddressActionCreators).isRequired,
    addresses: PropTypes.arrayOf(AddressShape.propTypes).isRequired,
    allowAdding: PropTypes.bool.isRequired,
    onAddressSelected: PropTypes.func.isRequired,
    onDoneSelecting: PropTypes.func.isRequired,
    selectAddressMessage: PropTypes.string.isRequired,
    selectedAddressId: PropTypes.string
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onAddressSelected: (id, address) => dispatch(ownProps.addressActionCreators.select(id, address))
})

const AddressSelectorListContainer = connect(
    null,
    mapDispatchToProps
)(AddressSelectorList)

export default AddressSelectorListContainer;
