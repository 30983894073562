
/* SHIPPING_METHOD */
export const SELECT_SHIPPING_OPTION              = 'SELECT_SHIPPING_METHOD_ACTION'
export const SELECT_SHIPPING_OPTION_ASYNC        = 'SELECT_SHIPPING_OPTION_ASYNC_ACTION'
export const NOTIFY_REFRESHING_SHIPPING_OPTIONS  = 'NOTIFY_REFRESHING_SHIPPING_METHODS_ACTION'
export const NEW_SHIPPING_OPTIONS                = 'NEW_SHIPPING_METHODS_ACTION'
export const NOTIFY_SAVE_SHIPPING_ACCOUNT        = 'NOTIFY_SAVE_SHIPPING_ACCOUNT_ACTION'
export const SAVE_SHIPPING_ACCOUNT_CARRIER       = 'SAVE_SHIPPING_ACCOUNT_CARRIER_ACTION'
/* PAYMENT_METHOD */
export const SELECT_PAYMENT_OPTION               = 'SELECT_PAYMENT_OPTION_ACTION'
export const BRAINTREEE_PAYMENT_DATA_UPDATE      = 'BRAINTREEE_PAYMENT_DATA_UPDATE_ACTION'
export const PURCHASE_ORDER_PAYMENT_DATA_UPDATE  = 'PURCHASE_ORDER_PAYMENT_DATA_UPDATE_ACTION'
/* COMPLETE CHECKOUT */
export const COMPLETE_CHECKOUT                   = 'COMPLETE_CHECKOUT_ACTION'
export const PLACE_ORDER_ASYNC                   = 'PLACE_ORDER_ASYNC_ACTION'
/* ERROR */
export const NOTIFY_ERROR                        = 'NOTIFY_ERROR_ACTION'
export const NOTIFY_COMPLETE_CHECKOUT_ERROR      = 'NOTIFY_COMPLETE_CHECKOUT_ERROR_ACTION'
export const LOG_ERROR                           = 'LOG_ERROR_ACTION'
/* UI */
export const USE_BILLING_FOR_SHIPPING_TOGGLE     = 'USE_BILLING_FOR_SHIPPING_TOGGLE_ACTION'
export const DISMISS_MESSAGE                     = 'DISMISS_MESSAGE_ACTION'
export const DISMISS_ALL_MESSAGES                = 'DISMISS_ALL_MESSAGES_ACTION'
/* CART */
export const UPDATE_ITEM_QUANTITY                = 'UPDATE_ITEM_QUANTITY'
export const DELETE_ITEM                         = 'DELETE_ITEM'
export const UPDATING_ITEM_QUANTITY_ASYNC        = 'UPDATING_ITEM_QUANTITY_ASYNC'
export const DELETING_ITEM_ASYNC                 = 'DELETING_ITEM_ASYNC'

/* ADDRESS */
export const ADD_SHIPPING_ADDRESS                = 'ADD_SHIPPING_ADDRESS_ACTION'
export const ADD_BILLING_ADDRESS                 = 'ADD_BILLING_ADDRESS_ACTION'
export const REMOVE_SHIPPING_ADDRESS             = 'REMOVE_SHIPPING_ADDRESS_ACTION'
export const REMOVE_BILLING_ADDRESS              = 'REMOVE_BILLING_ADDRESS_ACTION'
export const UPDATE_ADDRESS                      = 'UPDATE_ADDRESS_ACTION'
export const SELECT_BILLING_ADDRESS              = 'SELECT_BILLING_ADDRESS_ACTION'
export const SELECT_SHIPPING_ADDRESS             = 'SELECT_SHIPPING_ADDRESS_ACTION'
export const SAVE_ADDRESS_ASYNC                  = 'SAVE_ADDRESS_ASYNC_ACTION'
export const DELETE_ADDRESS_ASYNC                = 'DELETE_ADDRESS_ASYNC_ACTION'
export const SELECT_SHIPPING_AND_BILLING_ADDRESS = 'SELECT_BILLING_AND_SHIPPING_ADDRESS_ACTION'

/* SUMMARY */
export const CHECKOUT_SUMMARY_RECEIVED           = 'CHECKOUT_SUMMARY_RECEIVED_ACTION'
export const NOTIFY_REFRESHING_CHECKOUT_SUMMARY  = 'NOTIFY_REFRESHING_CHECKOUT_SUMMARY_ACTION'
export const NEW_CHECKOUT_SUMMARY                = 'NEW_CHECKOUT_SUMMARY_ACTION'

/* COUPONS */
export const ADD_COUPON = 'ADD_COUPON_ACTION'
export const ADD_COUPON_ASYNC = 'ADD_COUPON_ASYNC_ACTION'
export const REMOVE_COUPON = 'REMOVE_COUPON'
export const REMOVE_COUPON_ASYNC = 'REMOVE_COUPON_ACTION'
export const UPDATING_COUPONS = 'UPDATING_COUPONS'
export const UPDATING_COUPONS_ASYNC = 'UPDATING_COUPONS_ASYNC'

/* COMMENTS */
export const UPDATING_COMMENTS = 'UPDATING_COMMENTS'

