import React from 'react'
import PropTypes from 'prop-types'

const StateInput = ({ states, value, onChange, isRequired }) => {
    const name = "state";

    // Check in case the current value that was passed is actually a state Name rather than a Code (i.e. "Virginia" instead of "VA").
    // If so, use the corresponding code instead.
    if (value && typeof value === 'string' && !states.find(s => s.Code.toLowerCase() === value.toLowerCase())) {
        const matchedByName = states.find(s => s.Name.toLowerCase() === value.toLowerCase());
        if (matchedByName) {
            value = matchedByName.Code;
        }
    }

    if (states && states.length > 0) {
        return (
            <select
                name={name}
                value={value}
                onChange={onChange}
                className='form-control'
                required={isRequired(name)}
            >
                <option key="no state" value="">Select</option>
                {states.map((state) =>
                    <option key={state.Name} value={state.Code || state.Name}>{state.Name}</option>
                )}
            </select>
        );
    } else {
        return (
            <input type="text" name={name} value={value} onChange={onChange} className="form-control" />
        );
    }
}

StateInput.propTypes = {
    states: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

class StateDropdownWrapper extends React.Component {
    _handleChange(event) {
        let eventValue = event.target.value;
        let states = this.props.countriesStatesMap[this.props.CountryCode];
        if (!states || states.length === 0 || this.statesListContains(states, eventValue)) {
            this.props.onChange(event);
        }
        event.preventDefault();
    }

    statesListContains(states, val) {
        for (let i = 0; i < states.length; i++) {
            if (states[i].Code === val) {
                return true;
            }
        }

        return false;
    }

    render() {
        return (
            <StateInput
                onChange={(event) => this._handleChange(event)}
                states={this.props.countriesStatesMap[this.props.CountryCode] || []}
                value={this.props.value}
                isRequired={this.props.isRequired}
            />
        );
    }
}

StateDropdownWrapper.propTypes = {
    countryCode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    countriesStatesMap: PropTypes.object.isRequired,
    value: PropTypes.string
}

export default StateDropdownWrapper;
