import {
  SELECT_SHIPPING_OPTION,
  SELECT_SHIPPING_OPTION_ASYNC,
  NOTIFY_REFRESHING_SHIPPING_OPTIONS,
  NEW_SHIPPING_OPTIONS,
  NOTIFY_SAVE_SHIPPING_ACCOUNT,
  SAVE_SHIPPING_ACCOUNT_CARRIER,
  fetchJson
} from '../../GlobalStateContainer'
import { notifyError } from "../UserMessaging";
import { translateResourceString } from '../../../util/translationUtility';
import { checkoutSteps, sendCheckoutStepToDataLayer } from "../../../util/gtm";
import { UseMyAccountComponent } from '../../Features/ShippingMethod'

/* SHIPPING METHOD */

const onSelectShippingSuccess = ({ id,error }) => {
  if(error) notifyError('An error occurred selecting a shipping method', 'There was a problem obtaining shipment information.');
  return { type: SELECT_SHIPPING_OPTION, id: id }
}

const onSaveShippingCarrierSuccess = ({shippingAccountNumber, carrierCode}) => {
  return { type: SAVE_SHIPPING_ACCOUNT_CARRIER, shippingAccountNumber, carrierCode }
}

export function notifySelectingShippingOption() {
  return { type: SELECT_SHIPPING_OPTION_ASYNC }
}

export function notifySaveAccountShippingNumber() {
    return { type: NOTIFY_SAVE_SHIPPING_ACCOUNT }
}

const selectShippingMethodAsyncSpec = {
  endpoint: (state) => state.constants.navigation.setShippingMethodEndpoint,
  init: { method: 'POST', credentials: 'same-origin' },
  notify: notifySelectingShippingOption,
  extract: (response) => response.json(),
  onSuccess: onSelectShippingSuccess,
  onError: (diagMsg) => notifyError('An error occurred refreshing the shipping methods', diagMsg)
}

export const sendShippingMethodToGtmDatalayer = (shippingMethodName) => (dispatch, getState) => {
  const state = getState();
  sendCheckoutStepToDataLayer(
    checkoutSteps.selectShippingMethod,
    state.entities.CartItems.map(ci => ci.GoogleTagManagerProductFieldObject),
    state.constants.navigation.checkoutUrl,
    shippingMethodName);
}
export const selectShippingMethod = fetchJson(selectShippingMethodAsyncSpec);

export function notifyRefreshingShippingOptions() {
    return { type: NOTIFY_REFRESHING_SHIPPING_OPTIONS }
}

const saveShippingAccountCarrierNumberSpec = {
    endpoint: (state) => state.constants.navigation.setShippingAccountCarrierEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: notifySaveAccountShippingNumber,
    extract: (response) => response.json(),
    onSuccess: onSaveShippingCarrierSuccess,
    onError: (msg) => notifyError('An error occurred saving the shipping account carrier', msg)
}

export const saveShippingAccountCarrierAsync = fetchJson(saveShippingAccountCarrierNumberSpec);

const isAllUniqueValues = (arr) => [...new Set(arr)].length === arr.length

const shippingOptionsAreInvalid = (shippingOptions) => {
    if (!isAllUniqueValues(shippingOptions.map(so => so.Id))) {
        console.error();
        return 'not all Ids unique for ShippingOptions';
    }
    return null;
}

const onRefreshShippingOptionsSuccess = ({ shippingOptions, useMyOwnAccountShippingId }) => {
    const invalidMessage = shippingOptionsAreInvalid(shippingOptions);
    if (invalidMessage)
        return notifyError(translateResourceString("/Checkout/ShippingMethod/Errors/ErrorRetrieving"), invalidMessage)
        shippingOptions = shippingOptions.map(so => {
          if(so.Id === useMyOwnAccountShippingId){
               so.UseMyAccountComponent = UseMyAccountComponent;
          }
          else {
              so.UseMyAccountComponent = null;
          }
          return so;
       });
    return { type: NEW_SHIPPING_OPTIONS, shippingOptions }
}

const refreshShippingOptionsAsyncSpec = {
    endpoint: (state) => state.constants.navigation.refreshShippingOptionsEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: notifyRefreshingShippingOptions,
    extract: (response) => response.json(),
    onSuccess: onRefreshShippingOptionsSuccess,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/ShippingMethod/Errors/ErrorRefreshing"), diagMsg)
}

export const refreshShippingOptionsAsync = fetchJson(refreshShippingOptionsAsyncSpec)
