// BBS DDD from https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const isValidGuid = (s) => guidRegex.test(s);

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const createGuid = () => {
    try {
        if (typeof crypto === "undefined") {
            crypto = window.msCrypto || window.crypto;
        }
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => 
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    } catch(error) {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
};