import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

const TypeaheadEntry = ({ href, name, selected, imageUrl, currentSearchTerm, highlight }) => {
    const typeaheadEntryClass = classNames(
        'typeahead-result-wrapper',
        { 'selected': selected },
    );

    const textFragments = getHighlightedSearchTerms(currentSearchTerm, name, highlight);

    return <div className={typeaheadEntryClass}>
        <a href={href}>
            {imageUrl &&
                <img 
                    src={imageUrl} 
                    alt={name}
                />
            }
            {textFragments.map(fragment => {
                return <span className={fragment.highlight ? 'highlight' : ''}>{fragment.text}</span>
            })}
        </a>
    </div>
}

const getHighlightedSearchTerms = (currentSearchTerm, name, highlight) => {
    const tokenizedSearchTerms = currentSearchTerm
        .replace(/\*|\"|\'|\(|\)/gi, "")
        .split(' ')
        .filter(term => term.length > 0);

    const textFragments = [];

    let toHighlight = [];

    if (highlight) {
        tokenizedSearchTerms.map(term => {
            const lower = term.toLowerCase();
            const nameLower = name.toLowerCase();

            let index = -1;
            do {
                if(index === -1) {
                    if(nameLower.indexOf(lower, index + 1) === 0) {
                        toHighlight.push({
                            index: 0,
                            length: term.length
                        });
                        index = 0;
                        continue;
                    }
                }

                index = nameLower.indexOf(` ${lower}`, index + 1);

                if (index > -1) {
                    toHighlight.push({
                        index: index + 1,
                        length: term.length
                    });
                }
            } while (index !== -1)
        });
    }

    toHighlight = toHighlight            
        .sort((a, b) => {
            if (a.index < b.index) {
                return -1;
            }
            if (a.index > b.index) {
                return 1;
            }
            return b.length - a.length;
        });

    const nonIgnoredTerms =  _.uniqBy(toHighlight, "index")
        .filter(term => {
            const maxLength = Math.max(...toHighlight.filter(other => other.index === term.index).map(other => other.length));
            return term.length === maxLength;
        });
    let lastIndex = 0;

    nonIgnoredTerms.map(term => {
        if(lastIndex < term.index) {
            textFragments.push({
                text: name.substring(lastIndex, term.index),
                highlight: false
            });
        }

        lastIndex = term.index + term.length;

        textFragments.push({
            text: name.substring(term.index, lastIndex),
            highlight: true
        });
    })

    if(lastIndex < name.length) {
        textFragments.push({
            text: name.substring(lastIndex),
            highlight: false
        });
    }

    return textFragments;
}

TypeaheadEntry.propTypes = {
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    imageUrl: PropTypes.string
};

export default TypeaheadEntry;