import React from 'react';


const CategoryDetails = ({ name, description }) => {

  return (
    <div>
        <h1 data-epi-edit="Name" class="color-blue-light headline headline-xl mt-2">
            {name}
        </h1>
        <div data-epi-edit="Description" dangerouslySetInnerHTML={{__html: description}}></div>
    </div>

  );
}

export default CategoryDetails;
