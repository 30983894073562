import React from 'react';
import { AddressShape } from './propTypes';
import { translateResourceString } from '../../../util/translationUtility';

const spanStyle = {
    margin: 0,
    display: "block",
}

const addressStyleCompact = {
    margin: 0,
    display: 'inline-block',
    marginLeft: 5,
    marginBottom: 5,
    cursor: 'pointer',
}

export default function AddressDisplay(props) {
    if (!props.address) {
        return (<div className="address missing">No address provided.</div>);
    }
    const min = props.compact;
    const nameEmph = props.compact ? ' strong' : '';
    const addr = props.address;
    const recipientName = addr.AddressType === 2 && addr.Name !== "" ? `, ${addr.Name}` : "";
    const cityStateZip = `${addr.City}, ${addr.State}  ${addr.PostalCode}`;
    const si = Object.assign({}, spanStyle);
    const sj = Object.assign({}, si, { fontWeight: 'bold' });
    return (
        <div className={`address ${min ? 'address-compact' : ''}`} style={addressStyleCompact}>
            <div style={min ? {} : {}}>
                <span className={`name ${nameEmph}`} style={sj}>{addr.OrganizationName}{recipientName}</span>
                <span className="line1" style={si}>{addr.AddressLine1}</span>
                <span className="line2" hidden={!addr.AddressLine2}>{addr.AddressLine2}</span>
            </div>
            <div style={min ? {} : {}}>
                <span className="cityStateZip" style={si}>{cityStateZip}</span>
                <span className="country" hidden={min} style={spanStyle}>{addr.CountryName}</span>
                <span hidden={min || !addr.Phone}>
                    <span className="phone" hidden={min} style={spanStyle}>{addr.Phone}</span>
                </span>
            </div>
        </div>
    );
}

AddressDisplay.propTypes = {
    address: AddressShape.propTypes
}
