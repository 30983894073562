import React from 'react';
import Collapse from 'reactstrap/lib/Collapse';

const Category = ({ item, onSelect, isOpen, toggleOpen, onCategoryPage }) => {
    const Count = ({number}) => <sup className="count">({number})</sup>
    return (
        <div className={`category${isOpen ? " open" : ""}`}>
            <div className="category-parent">
                <div className="category-parent-inner">
                    {onCategoryPage ?
                        <a className="link-wrapper" href={item.RelativeUrl} title={item.DisplayName}>
                            {item.DisplayName}
                        </a>
                        : <span className="category-item" onClick={() => onSelect(item.Selected ? null : item)}>
                        {item.DisplayName}<Count number={item.Count} />
                        </span>
                    }
                    {item.Children && item.Children.length > 0 &&
                        <React.Fragment>
                            {isOpen
                                ? <i className="accordion-icon-minus" aria-hidden="true" onClick={toggleOpen}></i>
                                : <i className="accordion-icon-plus" aria-hidden="true" onClick={toggleOpen}></i>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
            {item.Children && item.Children.length > 0 &&
                <Collapse isOpen={isOpen} >
                    <div className="category-children">
                        {item.Children.map(c =>
                            <div key={`category-${c.CategoryContentLinkId}`} className={`category-child${c.Selected ? " selected" : ""}`} >
                                {onCategoryPage ?
                                    <a className="link-wrapper" href={c.RelativeUrl} title={c.DisplayName}>
                                        {c.DisplayName}
                                    </a>
                                    : <span className="category-item" onClick={() => onSelect(c.Selected ? null : c)}>
                                        {c.DisplayName}<Count number={c.Count} />
                                    </span>
                                }
                            </div>
                        )}
                    </div>
                </Collapse>
            }
        </div>
    )
}

export default Category;