import React from 'react';
import PropTypes from 'prop-types';
import ArticleSearchResultsContainer from './ArticleSearchResultsContainer';
import { translateResourceString } from '../../../util/translationUtility';
import ArticleSearchResultsNonFound from './ArticleSearchResultsNonFound';

const ArticleSearchResults = ({ articles,
    paginationViewModel,
    onCurrentItemsPerPageChange,
    onCurrentPageIndexChange,
    onResultClick,
    searchString,
    attributes,
    onAttributeChange,
    expandedArticleFacets,
    onCurrentSortByChange,
    zeroResourceResultsMessage,
    featuredResources,
    resourceTypes,
    onCurrentItemsPerPageChangeHref
}) => {
    return (
        <div className="pt-4">
            {searchString &&
                <h3 className="search-results-text headline headline-xl mb-4">{paginationViewModel.ResultsTotal} {translateResourceString("/SearchApp/ArticleResultsText")} '<span className="search-string">{searchString}</span>'</h3>
            }
            {articles.length > 0 ?
                <ArticleSearchResultsContainer
                    articles={articles}
                    paginationViewModel={paginationViewModel}
                    onCurrentItemsPerPageChange={onCurrentItemsPerPageChange}
                    onCurrentPageIndexChange={onCurrentPageIndexChange}
                    onResultClick={onResultClick}
                    searchString={searchString}
                    attributes={attributes}
                    onAttributeChange={onAttributeChange}
                    expandedArticleFacets={expandedArticleFacets}
                    onCurrentSortByChange={onCurrentSortByChange}
                    onCurrentItemsPerPageChangeHref={onCurrentItemsPerPageChangeHref}
                />
                : <ArticleSearchResultsNonFound 
                    zeroResourceResultsMessage={zeroResourceResultsMessage}
                    featuredResources={featuredResources}
                    resourceTypes={resourceTypes}
                />
                }
        </div>
    );
};

ArticleSearchResults.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    paginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentPageIndexChange: PropTypes.func,
    onResultClick: PropTypes.func,
    onCurrentItemsPerPageChangeHref: PropTypes.string
};

export default ArticleSearchResults;