import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'react-tabs'
import ModalTab from './ModalTab'
import ModalTabList from './ModalTabList'
import ModalTabPanels from './ModalTabPanels'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { translateResourceString } from '../../../util/translationUtility'

const ListsModal = ({
    Lists = [],
    UpdateLists,
    ListsModalIsOpen,
    ToggleListsModal,
    ActiveTabIndex = 0,
    SelectActiveTab = null,
    CreateListEndpoint
}) => {
    return (
        <Modal
            isOpen={ListsModalIsOpen}
            toggle={() => ToggleListsModal(false)}
            centered={true}
            className="modal-lg"
            contentClassName="list-modal"
            role="document">
            <ModalHeader toggle={() => ToggleListsModal(false)} wrapTag="div" tag="span" className="headline headline-sm">{translateResourceString("/Wishlists/ViewAndAddModal/Header")}</ModalHeader>
            <ModalBody className="list-sidebar pb-0">
                <div className="container-fluid">
                    <div className="row">
                        <Tabs className="tabs-container" selectedIndex={ActiveTabIndex} onSelect={index => { SelectActiveTab(index); ToggleListsModal(false); }}>
                            {ModalTabPanels({ Lists: Lists })}
                            {ModalTabList({ 
                                Lists: Lists, 
                                UpdateLists: UpdateLists,
                                ActiveTabIndex: ActiveTabIndex,
                                SelectActiveTab: SelectActiveTab,
                                CreateListEndpoint: CreateListEndpoint
                            })}
                        </Tabs>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

ModalTab.tabsRole = 'Tab';
ModalTabList.tabsRole = 'TabList';
ModalTabPanels.tabsRole = 'TabPanel';

ListsModal.propType = {
    Lists: PropTypes.array.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ListsModalIsOpen: PropTypes.bool.isRequired,
    ToggleListsModal: PropTypes.func.isRequired,
    ActiveTabIndex: PropTypes.number,
    SelectActiveTab: PropTypes.func,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default ListsModal;