import React from 'react';
import PropTypes from 'prop-types';

class QuickOrderEntry extends React.Component {
    constructor(props) {
        super(props);
    }

    onSelectSuggestedItem(sku) {
        this.props.onSkuSet(sku);
    }

    render() {
        const {
            translationUtility
        } = this.props;

        let quantityValue = this.props.quantity === -1 ? "" : this.props.quantity;

        let isSkuValidated = !!this.props.selectedSku && this.props.selectedSku === this.props.query;
        let showAutocomplete = this.props.hasFocus
            && this.props.searchCompleted
            && !!this.props.autocompleteItems
            && this.props.autocompleteItems.length > 0
            && !isSkuValidated;

        let itemLabel = translationUtility.translateResourceString("/QuickOrder/ItemNumberLabel");
        if (!!this.props.query) {
            if (!this.props.searchCompleted || showAutocomplete)
                itemLabel = translationUtility.translateResourceString("/QuickOrder/Searching");
            else if (isSkuValidated)
                itemLabel = this.props.itemName;
            else
                itemLabel = translationUtility.translateResourceString("/QuickOrder/ItemNotFound");
        }

        let searchedQueryLength = this.props.searchedQuery.length;

        return (
            <div className="quick-order-entry mb-2">
                <div className="row justify-content-end">
                    <div className="col-2 d-flex justify-content-end">

                     </div>
                </div>
                <div className="row">
                    <div className="col-7">
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder={translationUtility.translateResourceString("/QuickOrder/InputPlaceholder")}
                            onFocus={() => this.props.onFocusChanged(true)}
                            onBlur={(e) => this.props.onFocusChanged(false)}
                            value={this.props.query}
                            onChange={(e) => this.props.onQueryChange(e.target.value)}
                        />
                        <div className="easy-autocomplete-container"
                             style={{ width: "calc(100% - 30px)", left: "15px", top: "calc(100% - 8px)" }}>
                            <ul style={showAutocomplete ? { display: "block" } : {}}>
                                {this.props.autocompleteItems.map((item, index) => {
                                    return <li className="eac-item" key={item.Sku} onClick={() => this.onSelectSuggestedItem(item.Sku)} >
                                        <img className="eac-icon" src={item.UrlImage} alt={item.VariationName} />
                                        <b>{this.props.searchedQuery}</b>
                                        {item.MatchedField === "name"
                                            ? (item.VariationName.length > searchedQueryLength ? item.VariationName.substring(searchedQueryLength) : "")
                                            : (item.Sku.length > searchedQueryLength ? item.Sku.substring(searchedQueryLength) : "")
                                        }
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            className="form-control mb-1"
                            // disabled={!this.props.isValid}
                            value={quantityValue}
                            min={this.props.minQuantity}
                            max={this.props.maxQuantity}
                            onChange={(e) => { this.props.onQuantityChange(e.target.value); }}
                            onBlur={(e) => { this.props.onQuantityBlur(e.target.value); }} />
                    </div>
                    <div className="col-2">
                        {!this.props.hideCloseButton &&
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.deleteRow}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-7">
                        <span className="entry-label">{itemLabel}</span>
                    </div>
                    <div className="col">
                        <span className="entry-label">{translationUtility.translateResourceString("/QuickOrder/QuantityLabel")}</span>
                    </div>
                </div>
            </div>
        );
    }
}

QuickOrderEntry.propTypes = {
    deleteRow: PropTypes.func,
    onQueryChange: PropTypes.func,
    onSkuSet: PropTypes.func,
    onQuantityChange: PropTypes.func,
    onQuantityBlur: PropTypes.func,
    onRef: PropTypes.func,
    onFocusChanged: PropTypes.func,
    query: PropTypes.string,
    selectedSku: PropTypes.string,
    quantity: PropTypes.number,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    Name: PropTypes.string,
    isSearching: PropTypes.bool,
    translationUtility: PropTypes.object,
    autocompleteItems: PropTypes.arrayOf(PropTypes.object),
    hideCloseButton: PropTypes.bool,
};

export default QuickOrderEntry;