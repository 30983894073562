import React from 'react';
import CombinedSearchResults from './Features/Combined/CombinedSearchResults';
import createHistory from 'history/createBrowserHistory';
import fetch from 'cross-fetch';
import {
    ARTICLES,
    PRODUCTS
} from './constants/index.js';
import {
    buildQueryString,
} from './Infrastructure/urlBuilder.js';
import {
    buildStateFromModel,
    buildSelectedAttributesFromModel,
    buildFacetNamesFromModel,
    getSelectedCategory,
    getAttributes,
    expandedFacets,
    expandedArticleFacets
} from './Infrastructure/modelBuilder.js';
import { trackClick } from './Infrastructure/find.js';
import ProductSearchResults from './Features/Products/ProductSearchResults';
import CategoryDetails from './Features/Categories/CategoryDetails';
import WithLoading from './components/WithLoading';
import { addResourceStrings, translateResourceString } from '../util/translationUtility'

class SearchApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = buildStateFromModel(props);

        this.onAttributeChange = this.onAttributeChange.bind(this);
        this.onArticleAttributeChange = this.onArticleAttributeChange.bind(this);
        this.setProducts = this.updateState.bind(this);
        this.onClearAttributes = this.onClearAttributes.bind(this);
        this.onProductCurrentItemsPerPageChange = this.onProductCurrentItemsPerPageChange.bind(this);
        this.onProductCurrentSortByChange = this.onProductCurrentSortByChange.bind(this);
        this.onArticleCurrentSortByChange = this.onArticleCurrentSortByChange.bind(this);
        this.onProductCurrentPageIndexChange = this.onProductCurrentPageIndexChange.bind(this);
        this.onArticleCurrentItemsPerPageChange = this.onArticleCurrentItemsPerPageChange.bind(this);
        this.onArticleCurrentPageIndexChange = this.onArticleCurrentPageIndexChange.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.onResultClick = this.onResultClick.bind(this);
        this.onShowMobileFacetsChange = this.onShowMobileFacetsChange.bind(this);
        this.onShowMobileFacetsToggle = this.onShowMobileFacetsToggle.bind(this);
        this.onToggleFacetExpanded = this.onToggleFacetExpanded.bind(this);
        this.anyProductsOrArticles = this.anyProductsOrArticles.bind(this);
        this.hasSearchString = this.hasSearchString.bind(this);
        this.isCategory = this.isCategory.bind(this);
        this.apiEndpoint = props.ApiEndpoint;
        this.clickTrackingApiEndpoint = props.ClickTrackingApiEndpoint;
        this.trackId = props.TrackId;
        this.twoLetterLanguageCode = props.TwoLetterLanguageCode;
        this.siteDefinitionId = props.SiteDefinitionId;
        this.onToggleShowMoreFacet = this.onToggleShowMoreFacet.bind(this);
        this.onCategorySelect = this.onCategorySelect.bind(this);
        this.onToggleCategoryOpen = this.onToggleCategoryOpen.bind(this);
        this.getInitialProductHref = this.getInitialProductHref.bind(this);
        this.getCurrentItemsPerPageChangeRequestedPageIndex = this.getCurrentItemsPerPageChangeRequestedPageIndex.bind(this);
        this.getInitialArticleHref = this.getInitialArticleHref.bind(this);
        this.getInitialRequestedItemsPerPageValue = this.getInitialRequestedItemsPerPageValue.bind(this);

        this.state.onCurrentProductsPerPageChangeHref = this.getInitialProductHref();
        this.state.onCurrentArticlesPerPageChangeHrf = this.getInitialArticleHref();

        // Category - not in state
        this.categoryName = props.Name;
        this.contentLinkId = props.ContentLinkId;
        this.categoryImageUrl = props.ImageUrl;
        this.categoryImageAltText = props.ImageAltText;
        this.categoryDescription = props.Description;

        addResourceStrings(props.LocalizationItems);
    }

    componentDidMount() {
        require('./SearchApp.css');
        this.history =  createHistory();
        this.history.listen((location, action) => {
            if (location.state && location.state.view) {
                this.setState({ view: location.state.view });
            }
            this.fetchProductsUsingUrl(location.search);
        });
    }

    getInitialProductHref(){
        const {searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, selectedCategory, selectedArticleAttributes } = this.state;
        const requestedItemsPerPage = this.getInitialRequestedItemsPerPageValue(productPaginationViewModel);
        let tempProductPaginationViewModel = Object.assign({}, productPaginationViewModel);
        const requestedPageIndex = this.getCurrentItemsPerPageChangeRequestedPageIndex(tempProductPaginationViewModel, requestedItemsPerPage);
        tempProductPaginationViewModel.CurrentPageIndex = requestedPageIndex;
        tempProductPaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;

        return buildQueryString(searchString, selectedAttributes, tempProductPaginationViewModel, articlePaginationViewModel, PRODUCTS, selectedCategory, selectedArticleAttributes);
    }

    getInitialRequestedItemsPerPageValue(paginationViewModel) {
        const numberOfItemsToLoad = paginationViewModel?.ItemsPerPageIncrement || 10;
        if (paginationViewModel == null) {
            return numberOfItemsToLoad;
        }
        return Math.min(paginationViewModel.CurrentItemsOnPage + numberOfItemsToLoad, paginationViewModel.ResultsTotal);
    }

    getInitialArticleHref(){
        const {searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, selectedCategory, selectedArticleAttributes } = this.state;
        const requestedItemsPerPage = this.getInitialRequestedItemsPerPageValue(articlePaginationViewModel);
        let tempArticlePaginationViewModel = Object.assign({}, articlePaginationViewModel);
        const requestedPageIndex = this.getCurrentItemsPerPageChangeRequestedPageIndex(tempArticlePaginationViewModel, requestedItemsPerPage);
        tempArticlePaginationViewModel.CurrentPageIndex = requestedPageIndex;
        tempArticlePaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;

        return buildQueryString(searchString, selectedAttributes, productPaginationViewModel, tempArticlePaginationViewModel, ARTICLES, selectedCategory, selectedArticleAttributes);
    }

    updateState(searchResults) {
        const productAttributes = getAttributes(searchResults.Products);
        const articleAttributes = getAttributes(searchResults.Articles);

        const selectedAttributes = buildSelectedAttributesFromModel(productAttributes);

        this.setState({
            attributes: productAttributes,
            products: searchResults.Products.ProductItems ? searchResults.Products.ProductItems : [],
            productPaginationViewModel: searchResults.Products && searchResults.Products.PaginationViewModel ? searchResults.Products.PaginationViewModel : null,
            articlePaginationViewModel: searchResults.Articles && searchResults.Articles.PaginationViewModel ? searchResults.Articles.PaginationViewModel : null,
            selectedAttributes: selectedAttributes,
            expandedFacets: expandedFacets(productAttributes, selectedAttributes),
            isLoading: false,
            articles: searchResults.Articles && searchResults.Articles.ArticleItemViewModels ? searchResults.Articles.ArticleItemViewModels : [],
            categories: searchResults.Products.CategoryFilters ? searchResults.Products.CategoryFilters : [],
            selectedCategory: searchResults.Products.CategoryFilters && searchResults.Products.CategoryFilters.length > 0 ? getSelectedCategory(searchResults.Products.CategoryFilters) : null,
            articleAttributes: articleAttributes,
            selectedArticleAttributes: buildSelectedAttributesFromModel(articleAttributes),
            expandedArticleFacets: expandedArticleFacets(articleAttributes)
        });

        this.updateLoadMoreHrefStates(this.state.searchString, this.state.selectedAttributes, this.state.productPaginationViewModel,
            this.state.articlePaginationViewModel, this.state.view, this.state.selectedCategory, this.state.selectedArticleAttributes);

        this.updatePageCanonicals(this.state.view, this.state.productPaginationViewModel, this.state.articlePaginationViewModel);
    }

    updateLoadMoreHrefStates(searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view, selectedCategory, selectedArticleAttributes) {
        // Need to keep the load more "button" href values up to date with the latest values
        // creating temp values because the load more buttons are future state of button click
        let tempProductPaginationViewModel = Object.assign({}, productPaginationViewModel);
        tempProductPaginationViewModel.CurrentItemsPerPage += tempProductPaginationViewModel.ItemsPerPageIncrement;
        const productsQueryString = buildQueryString(searchString, selectedAttributes, tempProductPaginationViewModel, articlePaginationViewModel, PRODUCTS, selectedCategory, selectedArticleAttributes);
        let tempArticlePaginationViewModel = Object.assign({}, articlePaginationViewModel);
        tempArticlePaginationViewModel.CurrentItemsPerPage += tempArticlePaginationViewModel.ItemsPerPageIncrement;
        const articlesQueryString = buildQueryString(searchString, selectedAttributes, productPaginationViewModel, tempArticlePaginationViewModel, ARTICLES, selectedCategory, selectedArticleAttributes);
        this.setState({ onCurrentProductsPerPageChangeHref: productsQueryString, onCurrentArticlesPerPageChangeHrf: articlesQueryString });
    }

    updatePageCanonicals(view, productPaginationViewModel, articlePaginationViewModel) {
        if (view === ARTICLES) {
            this.updateCanonicals(articlePaginationViewModel, 'articlePageSize');
            this.cleanupCanonicalPaginationString('q');
        } else {
            this.updateCanonicals(productPaginationViewModel, 'q');
            this.cleanupCanonicalPaginationString('articlePageSize');
        }
    }

    updateUrl({ searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view, selectedCategory, selectedArticleAttributes }) {

        const queryString = buildQueryString(searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view, selectedCategory, selectedArticleAttributes);

        this.updateLoadMoreHrefStates(searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view, selectedCategory, selectedArticleAttributes);

        // Going to let history listener take care of it
        this.history.push({
            search: queryString,
            state: { view: view }
        });

        this.updatePageCanonicals(view, productPaginationViewModel, articlePaginationViewModel);
    }

    updateCanonicals({ CurrentItemsPerPage, ItemsPerPageIncrement, ResultsTotal }, paginationQueryString) {
        const canonicalElement = document.querySelector("link[rel='canonical']"),
            canonicalNextElement = document.querySelector("link[rel='next']"),
            canonicalPreviousElement = document.querySelector("link[rel='prev']");

        // canonical
        if (canonicalElement) {
            const canonicalHref = new URL(canonicalElement.getAttribute('href'));
            if (CurrentItemsPerPage > ItemsPerPageIncrement) {
                canonicalHref.searchParams.set(paginationQueryString, CurrentItemsPerPage);
            } else {
                canonicalHref.searchParams.delete(paginationQueryString);
            }
            canonicalElement.setAttribute('href', canonicalHref.toString());
        }

        // canonical next
        const nextPageItems = Math.min(CurrentItemsPerPage + ItemsPerPageIncrement, ResultsTotal);
        if (canonicalNextElement) {
            const nextHref = new URL(canonicalNextElement.getAttribute('href'));

            if (CurrentItemsPerPage >= ResultsTotal) {
                canonicalNextElement.remove();
            } else {
                nextHref.searchParams.set(paginationQueryString, nextPageItems);
                canonicalNextElement.setAttribute('href', nextHref.toString());
            }
        } else {
            if (CurrentItemsPerPage < ResultsTotal) {
                let newCanonicalNextElement = document.createElement('link');

                // get href for canonical to create previous href
                const nextHref = new URL(canonicalElement.getAttribute('href'));
                nextHref.searchParams.set(paginationQueryString, nextPageItems);

                newCanonicalNextElement.setAttribute('href', nextHref.toString());
                newCanonicalNextElement.setAttribute('rel', 'next');

                // add the next canonical before canonical
                canonicalElement.before(newCanonicalNextElement);
            }
        }

        // canonical prev
        const previousPageItems = Math.ceil(CurrentItemsPerPage / ItemsPerPageIncrement) * ItemsPerPageIncrement - ItemsPerPageIncrement;
        if (canonicalPreviousElement) {
            if (CurrentItemsPerPage <= ItemsPerPageIncrement) {
                canonicalPreviousElement.remove();
            } else {
                const previousHref = new URL(canonicalPreviousElement.getAttribute('href'));

                if (previousPageItems > ItemsPerPageIncrement) {
                    previousHref.searchParams.set(paginationQueryString, previousPageItems);
                } else {
                    previousHref.searchParams.delete(paginationQueryString);
                }

                canonicalPreviousElement.setAttribute('href', previousHref.toString());
            }
        } else {
            // no previous canonical link so add if we are on page 2 or higher
            if (previousPageItems >= ItemsPerPageIncrement) {
                let newCanonicalPreviousElement = document.createElement('link');

                // get href for canonical to create previous href
                const previousHref = new URL(canonicalElement.getAttribute('href'));

                // if we are on page 3 or higher then add search query
                if (previousPageItems >= (ItemsPerPageIncrement * 2)) {
                    previousHref.searchParams.set(paginationQueryString, previousPageItems);
                } else {
                    previousHref.searchParams.delete(paginationQueryString);
                }

                newCanonicalPreviousElement.setAttribute('href', previousHref.toString());
                newCanonicalPreviousElement.setAttribute('rel', 'prev');

                // add the previous canonical before next if it exist otherwise before canonical
                if (canonicalNextElement) {
                    canonicalNextElement.before(newCanonicalPreviousElement);
                } else {
                    canonicalElement.before(newCanonicalPreviousElement);
                }
            }
        }
    }

    cleanupCanonicalPaginationString(paginationQueryStringToRemove) {
        const canonicalElement = document.querySelector("link[rel='canonical']"),
            canonicalNextElement = document.querySelector("link[rel='next']"),
            canonicalPreviousElement = document.querySelector("link[rel='prev']"),
            removeQueryParam = (canonicalElement) => {
                if (canonicalElement) {
                    const canonicalHref = new URL(canonicalElement.getAttribute('href'));
                    canonicalHref.searchParams.delete(paginationQueryStringToRemove);
                    canonicalElement.setAttribute('href', canonicalHref.toString());
                }
            };

        
        removeQueryParam(canonicalElement);
        removeQueryParam(canonicalNextElement);
        removeQueryParam(canonicalPreviousElement);
    }

    fetchProductsUsingUrl(queryString) {
        if(this.contentLinkId)
        {
            if(queryString && queryString.indexOf("?") > -1)
            {
                queryString = `${queryString}&contentLinkId=${this.contentLinkId}`;
            }
            else
            {
                queryString = `?contentLinkId=${this.contentLinkId}`;
            }
        }
        this.setState({ isLoading: true });
        const url = `${this.apiEndpoint}${queryString}`;
        fetch(url)
            .then(response => response.json())
            .then(result => this.updateState(result));
    }

    onAttributeChange(name, value) {
        let found = false;
        let selectedAttributes = this.state.selectedAttributes;
        selectedAttributes.forEach((selectedAttribute, i) => {
            if (selectedAttribute.name === name && selectedAttribute.value === value) {
                found = true;

                // Remove it
                selectedAttributes.splice(i, 1);
            }
        });

        if (!found) {
            // Add it
            selectedAttributes.push({
                name: name,
                value: value
            });
        }

        const changes = {
            selectedAttributes: selectedAttributes
        };

        const newOptions = { ...this.state, ...changes };
        this.updateUrl(newOptions);
    }

    onArticleAttributeChange(name, value) {
        let found = false;
        const selectedArticleAttributes = this.state.selectedArticleAttributes;
        selectedArticleAttributes.forEach((selectedAttribute, i) => {
            if (selectedAttribute.name === name && selectedAttribute.value === value) {
                found = true;

                // Remove it
                selectedArticleAttributes.splice(i, 1);
            }
        });

        if (!found) {
            // Add it
            selectedArticleAttributes.push({
                name: name,
                value: value
            });
        }

        const newOptions = {
            ...this.state,
            selectedArticleAttributes: selectedArticleAttributes
        };

        this.updateUrl(newOptions);
    }

    onProductCurrentSortByChange(requestedSortBy) {
        const productPaginationViewModel = this.state.productPaginationViewModel;
        productPaginationViewModel.CurrentSortBy = requestedSortBy;
        const newOptions = { ...this.state, productPaginationViewModel };
        this.updateUrl(newOptions);
    }

    onArticleCurrentSortByChange(requestedSortBy) {
        const articlePaginationViewModel = this.state.articlePaginationViewModel;
        articlePaginationViewModel.CurrentSortBy = requestedSortBy;
        this.updateUrl({
            ...this.state,
            articlePaginationViewModel
        });
    }

    onClearAttributes() {
        const changes = { selectedAttributes: [] };
        const newOptions = { ...this.state, ...changes };
        this.updateUrl(newOptions);
    }

    getCurrentItemsPerPageChangeRequestedPageIndex(paginationViewModel, requestedItemsPerPage) {
        const { ResultsTotal = 0, CurrentPageIndex = 0 } = paginationViewModel || {};
        const firstItemNumberOnNewPage = (CurrentPageIndex || 0) * requestedItemsPerPage + 1;
        const requestedPageIndex = firstItemNumberOnNewPage > ResultsTotal
            ? Math.floor(ResultsTotal / requestedItemsPerPage)
            : CurrentPageIndex;

        return requestedPageIndex;
    }

    onProductCurrentItemsPerPageChange(requestedItemsPerPage) {
        // Need to possibly update the page request too
        const { productPaginationViewModel } = this.state;
        const requestedPageIndex = this.getCurrentItemsPerPageChangeRequestedPageIndex(productPaginationViewModel, requestedItemsPerPage);
        productPaginationViewModel.CurrentPageIndex = requestedPageIndex;
        productPaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;
        const newOptions = { ...this.state, productPaginationViewModel }

        this.updateUrl(newOptions);
    }

    onProductCurrentPageIndexChange(requestedPageIndex) {
        const { productPaginationViewModel } = this.state;
        productPaginationViewModel.CurrentPageIndex = requestedPageIndex;
        const newOptions = { ...this.state, productPaginationViewModel };
        this.updateUrl(newOptions);
    }

    onArticleCurrentItemsPerPageChange(requestedItemsPerPage) {
        // Need to possibly update the page request too
        const { articlePaginationViewModel } = this.state;
        const requestedPageIndex = this.getCurrentItemsPerPageChangeRequestedPageIndex(articlePaginationViewModel, requestedItemsPerPage);
        articlePaginationViewModel.CurrentPageIndex = requestedPageIndex;
        articlePaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;
        const newOptions = { ...this.state, articlePaginationViewModel }

        this.updateUrl(newOptions);
    }

    onArticleCurrentPageIndexChange(requestedPageIndex) {
        const { articlePaginationViewModel } = this.state;
        articlePaginationViewModel.CurrentPageIndex = requestedPageIndex;
        const newOptions = { ...this.state, articlePaginationViewModel };
        this.updateUrl(newOptions);
    }

    onCategorySelect(selectedCategory) {
        if(!this.isCategory()) {
            const changes = { selectedCategory: selectedCategory };
            if (this.state.productPaginationViewModel)
                changes.productPaginationViewModel = { ...this.state.productPaginationViewModel, 
                    CurrentItemsPerPage: this.state.productPaginationViewModel.ItemsPerPageIncrement };

            if(selectedCategory) {
                // reset open categories when setting since we only want selected category
                // to be open after selecting
                let newOpenCategories = [selectedCategory.CategoryContentLinkId];
                // check if selected category is a parent category
                if (this.state.categories &&
                   this.state.categories.findIndex(parent => 
                    parent.CategoryContentLinkId === selectedCategory.CategoryContentLinkId) < 0) {
                    // selected category is not a parent category so we need to find the parent
                    // category to make sure it stays open but close all other open categories
                    const parentCategory = this.state.categories.find(parent => 
                        parent.Children.findIndex(child => child.CategoryContentLinkId === selectedCategory.CategoryContentLinkId) >= 0);
                    if (parentCategory) {
                        newOpenCategories = [...newOpenCategories, parentCategory.CategoryContentLinkId];
                    }
                }
                this.setState((prevState) =>({
                    openCategories: newOpenCategories
                }));
            } else {
                changes.selectedAttributes = [];
            }
            const newOptions = { ...this.state, ...changes };
            this.updateUrl(newOptions);
        }
    }

    onToggleCategoryOpen(categoryContentLinkId) {
        this.setState((prevState) =>({
            openCategories: prevState.openCategories.indexOf(categoryContentLinkId) > -1
                ? prevState.openCategories.filter(id => id !== categoryContentLinkId)
                : [...prevState.openCategories, categoryContentLinkId]
        }));
    }

    /**
     * Updates current view (Products vs Articles)
     * @param {string} view Current View Name.
     * @returns {void}
     */
    onChangeView(view) {
        const changes = { view: view };
        const newOptions = { ...this.state, ...changes };
        this.updateUrl(newOptions);
    }

    /**
     * Tracking for Find
     * @param {string} hitId HitId for Find result.
     * @returns {void}
     */
    onResultClick(hitId) {
        // Call tracking api
        if (typeof this.state.findClickTrackingViewModel !== "undefined") {
            const { TrackId, TwoLetterLanguageCode, SiteDefinitionId, ClickTrackingApiEndpoint, SearchTerm } = this.state.findClickTrackingViewModel;
            trackClick(ClickTrackingApiEndpoint, hitId, TrackId, TwoLetterLanguageCode, SiteDefinitionId, SearchTerm);
        }
    }

    onShowMobileFacetsChange(showMobileFacets) {
        this.setState({ showMobileFacets });
    }

    onShowMobileFacetsToggle() {
        const { showMobileFacets } = this.state;
        this.setState({ showMobileFacets: !showMobileFacets });
    }

    onToggleFacetExpanded(facetName) {
        const { expandedFacets } = this.state;
        const index = expandedFacets.indexOf(facetName);
        if (index > -1) {
            expandedFacets.splice(index, 1);
        } else {
            expandedFacets.push(facetName);
        }
        this.setState({ expandedFacets });
    }

    onToggleShowMoreFacet(facetName) {
        const { showMoreFacets } = this.state;
        const index = showMoreFacets.indexOf(facetName);
        if (index > -1) {
            showMoreFacets.splice(index, 1);
        } else {
            showMoreFacets.push(facetName);
        }
        this.setState({ showMoreFacets });
    }

    anyProductsOrArticles() {
        const {
            articles,
            products
        } = this.state;

        const anyArticles = articles && articles.length > 0;
        const anyProducts = products && products.length > 0;
        return anyArticles || anyProducts;
    }

    hasSearchString() {
        const { searchString } = this.state;
        return searchString && searchString.length > 0;
    }

    isCategory() {
        return this.categoryName && this.categoryName.length > 0;
    }

    render() {
        const {
            searchString,
            attributes,
            products,
            productPaginationViewModel,
            isLoading,
            view,
            articles,
            articlePaginationViewModel,
            suggestions,
            showMobileFacets,
            expandedFacets,
            showMoreFacets,
            promotionalArea,
            categories,
            selectedCategory,
            openCategories,
            articleAttributes,
            expandedArticleFacets,
            zeroProductResultsMessage,
            popularCategories,
            zeroResourceResultsMessage,
            featuredResources,
            resourceTypes,
            onCurrentProductsPerPageChangeHref,
            onCurrentArticlesPerPageChangeHrf
        } = this.state;
        const isCategory = this.isCategory();
        const anyProductsOrArticles = this.anyProductsOrArticles();
        const hasSearchString = this.hasSearchString();

        return (
            <div className="search-app">
                {/* <div className="hidden-md hidden-lg">
                    {showMobileFacets &&
                        <div
                            className="overlay hidden-md hidden-lg"
                            onClick={() => this.onShowMobileFacetsChange(false)}
                        />
                    }
                </div> */}
                <div className="search-app-inner">
                    {isCategory ?
                        <React.Fragment>
                            <CategoryDetails
                                name={this.categoryName}
                                description={this.categoryDescription}
                            />
                            <ProductSearchResultsWithLoading
                                attributes={attributes}
                                onAttributeChange={this.onAttributeChange}
                                onClearAttributes={this.onClearAttributes}
                                onCurrentItemsPerPageChange={this.onProductCurrentItemsPerPageChange}
                                onCurrentSortByChange={this.onProductCurrentSortByChange}
                                onCurrentPageIndexChange={this.onProductCurrentPageIndexChange}
                                paginationViewModel={productPaginationViewModel}
                                products={products}
                                recommendedProducts={this.state.recommendedProducts}
                                onResultClick={this.onResultClick}
                                isLoading={isLoading}
                                onShowMobileFacetsChange={this.onShowMobileFacetsChange}
                                expandedFacets={expandedFacets}
                                onToggleFacetExpanded={this.onToggleFacetExpanded}
                                categoryImageUrl={this.categoryImageUrl}
                                categoryImageAltText={this.categoryImageAltText}
                                showMoreFacets={showMoreFacets}
                                onToggleShowMoreFacet={this.onToggleShowMoreFacet}
                                promotionalArea={promotionalArea}
                                categories={categories}
                                onCategoryPage={isCategory}
                                onCategorySelect={this.onCategorySelect}
                                openCategories={openCategories}
                                onToggleCategoryOpen={this.onToggleCategoryOpen}
                                onCurrentItemsPerPageChangeHref={onCurrentProductsPerPageChangeHref}
                            />
                        </React.Fragment>
                        :
                            <CombinedSearchResults
                                articles={articles}
                                articlePaginationViewModel={articlePaginationViewModel}
                                onArticleCurrentItemsPerPageChange={this.onArticleCurrentItemsPerPageChange}
                                onArticleCurrentPageIndexChange={this.onArticleCurrentPageIndexChange}
                                onResultClick={this.onResultClick}
                                attributes={attributes}
                                articleAttributes={articleAttributes}
                                onAttributeChange={this.onAttributeChange}
                                onArticleAttributeChange={this.onArticleAttributeChange}
                                onClearAttributes={this.onClearAttributes}
                                onProductCurrentItemsPerPageChange={this.onProductCurrentItemsPerPageChange}
                                onProductCurrentSortByChange={this.onProductCurrentSortByChange}
                                onArticleCurrentSortByChange={this.onArticleCurrentSortByChange}
                                onProductCurrentPageIndexChange={this.onProductCurrentPageIndexChange}
                                productPaginationViewModel={productPaginationViewModel}
                                products={products}
                                view={view}
                                onChangeView={this.onChangeView}
                                isLoading={isLoading}
                                onShowMobileFacetsChange={this.onShowMobileFacetsChange}
                                expandedFacets={expandedFacets}
                                expandedArticleFacets={expandedArticleFacets}
                                onToggleFacetExpanded={this.onToggleFacetExpanded}
                                showMoreFacets={showMoreFacets}
                                onToggleShowMoreFacet={this.onToggleShowMoreFacet}
                                categories={categories}
                                onCategoryPage={false}
                                onCategorySelect={this.onCategorySelect}
                                selectedCategory={selectedCategory}
                                openCategories={openCategories}
                                onToggleCategoryOpen={this.onToggleCategoryOpen}
                                searchString={searchString}
                                zeroProductResultsMessage={zeroProductResultsMessage}
                                popularCategories={popularCategories}
                                zeroResourceResultsMessage={zeroResourceResultsMessage}
                                featuredResources={featuredResources}
                                resourceTypes={resourceTypes}
                                suggestions={suggestions}
                                onCurrentProductsPerPageChangeHref={onCurrentProductsPerPageChangeHref}
                                onCurrentArticlesPerPageChangeHrf={onCurrentArticlesPerPageChangeHrf}
                            />
                    }
                </div>
            </div>
        );
    }
}

const ProductSearchResultsWithLoading = WithLoading(ProductSearchResults);

export default SearchApp;
