import React from 'react';
import PropTypes from 'prop-types';
import { translateResourceString } from '../../../util/translationUtility';

const Article = ({ Link, Title, Description, onClick, CategoryIconImage, CategoryButtonText, CategoryDisplayName }) => {
    return (
        <a href={Link} title={Title} onClick={onClick} className="d-block item-tile article-tile link-wrapper">
            <div className="article-inner">
                <h2 className="headline color-blue-light">{Title}</h2>
                <div className="d-flex justify-content-start pb-2">
                    {CategoryIconImage &&
                        <div class={`rockland-icon-${CategoryIconImage} color-blue-light`}></div>
                    }
                    <div className="text-sm-left pl-1 fw-600">{CategoryDisplayName}</div>
                </div>
                <div className="pb-2">{Description}</div>
                <button
                    className="btn btn-r-red-outline"
                >
                    {CategoryButtonText || translateResourceString("/SearchApp/ArticleTile/ReadMore")}
                </button>
            </div>
        </a>
    );
}

Article.propType = {
    Link: PropTypes.string,
    Title: PropTypes.string,
    Description: PropTypes.string,
    onClick: PropTypes.func,
};

export default Article;
