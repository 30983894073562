import React from 'react'
import PropTypes from 'prop-types';
import find from 'lodash/find'

import { CheckoutSection, hiddenAnchorStyle } from '../../components'
import { translateResourceString } from '../../../util/translationUtility';

import { Fade } from '../../componentAnimations'

import AddressSelectorList from './AddressSelectorList'
import AddressDisplay from './AddressDisplay'
import AddressEditorContainer from './AddressEditorContainer'

import { AddressShape, AddressActionCreatorsShape as AddressActionCreators } from './propTypes'

class CheckoutAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listCollapsed: props.selectedAddressId ? true : false,
            editId: ''
        };
        this.onClickCollapse = this.onClickCollapse.bind(this);
        this.onDoneSelecting = this.onDoneSelecting.bind(this);
        this.onBeginEdit = this.onBeginEdit.bind(this);
        this.onEndEdit = this.onEndEdit.bind(this);
        this.onEditCancel = this.onEditCancel.bind(this);
    }

    onClickCollapse(e) {
        e.preventDefault();
        this.setState({ listCollapsed: !this.state.listCollapsed });
    }

    onDoneSelecting() {
        this.setState({ listCollapsed: true });
    }

    onBeginEdit(Id) {
        this.setState({ editOpen: true, editId: Id, listCollapsed: true });
    }

    onEndEdit(Id) {
        this.setState({ editOpen: false });
        this.props.onEndEdit(Id);
    }

    onEditCancel() {
        this.setState({ editOpen: false, listCollapsed: false })
    }

    render() {
        const selectedAddress = find(this.props.addresses, address => address && address.Id === this.props.selectedAddressId);

        let editAddressButtonText = translateResourceString("/Checkout/Address/DoneButton");
        if (this.state.listCollapsed) {
            editAddressButtonText = this.props.allowAdding ? translateResourceString("/Checkout/Address/AddEditAddressesButton")
                                                           : translateResourceString("/Checkout/Address/EditAddressButton");
        }
        let hideDoneTextWhenEmptyAddress = (this.props.addresses && this.props.addresses.length === 0 && editAddressButtonText === translateResourceString("/Checkout/Address/DoneButton"));

        return (
            <Fade inProp={!this.props.hidden}>
                <CheckoutSection label={this.props.sectionLabel + ""} hidden={this.props.hidden}>

                    <div className='row' style={{ minHeight: '2em' }}>
                        <div className='col-8'>
                            <div onClick={(e) => this.onClickCollapse(e)} style={{cursor: 'pointer'}}>
                                <AddressDisplay
                                    address={selectedAddress}
                                    compact={true}
                                />
                            </div>
                        </div>
                        {!this.state.editOpen && (
                            <div className='col-4 pl-1 d-flex justify-content-end align-items-start' hidden={hideDoneTextWhenEmptyAddress}>
                                <button
                                    className={`btn ${this.state.listCollapsed ? 'btn-r-red-outline' : 'btn-r-red'}`}
                                    onClick={(e) => this.onClickCollapse(e)}
                                >
                                    {editAddressButtonText}
                                </button>
                            </div>
                            )}
                    </div>
                    {this.props.allowBillingEqualsShipping && this.props.onUseForAnotherClicked &&
                        <div className='row' style={{ minHeight: '2em' }}>
                            <div className='col-12'>
                                <div>
                                    <span
                                        onClick={this.props.onUseForAnotherClicked}
                                    >
                                        <input
                                            style={{ marginRight: 5 }}
                                            type='checkbox'
                                            checked={this.props.useForAnotherSelected}
                                            id='useForAnother'
                                            readOnly={true}
                                        />
                                        <label
                                            htmlFor='#useForAnother'
                                        >
                                            {this.props.useForAnotherMessage}
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.editOpen
                        ? (
                            <React.Fragment>
                                <hr/>
                                <AddressEditorContainer
                                    addressId={this.state.editId}
                                    addressActionCreators={this.props.addressActionCreators}
                                    addressType={this.props.addressType}
                                    onAddressCreated={this.onEndEdit}
                                    onAddressUpdated={this.onEndEdit}
                                    onEditCancel={this.onEditCancel}
                                />
                            </React.Fragment>
                        )
                        : (
                            <React.Fragment>
                                <Fade inProp={!this.state.listCollapsed}>
                                    <hr />
                                    <AddressSelectorList
                                        addressActionCreators={this.props.addressActionCreators}
                                        addresses={this.props.addresses}
                                        allowAdding={this.props.allowAdding}
                                        onBeginEdit={this.onBeginEdit}
                                        onDoneSelecting={this.onDoneSelecting}
                                        selectedAddressId={this.props.selectedAddressId} selectAddressMessage={this.props.selectAddressMessage} />
                                </Fade>
                            </React.Fragment>
                        )}
                </CheckoutSection>
            </Fade>
        );
    }
}

CheckoutAddressComponent.propTypes = {
    addressActionCreators: AddressActionCreators.propTypes,
    addressType: PropTypes.number.isRequired,
    addresses: PropTypes.arrayOf(AddressShape.propTypes).isRequired,
    allowAdding: PropTypes.bool.isRequired,
    onEndEdit: PropTypes.func.isRequired,
    onUseForAnotherClicked: PropTypes.func,
    sectionLabel: PropTypes.string.isRequired,
    selectAddressMessage: PropTypes.string.isRequired,
    selectedAddressId: PropTypes.string,
    useForAnotherMessage: PropTypes.string,
    useForAnotherSelected: PropTypes.bool,
    allowBillingEqualsShipping: PropTypes.bool
}

export default CheckoutAddressComponent;
