import React from 'react'
import { TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'
import ProductItem from '../../Components/ProductItem'

const ModalTabPanels = ({
    Lists = []
}) => {
    return (
        <div className="col-lg-4 d-none d-lg-block list-modal-product">
            {
                Lists.map((list, i) =>
                    <TabPanel key={i}>
                        {
                            list.Items.map((item, j) =>
                                <ProductItem key={j} {...item} />
                            )
                        }
                    </TabPanel>
                )
            }
        </div>
    );
};

ModalTabPanels.propType = {
    Lists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string,
            Items: PropTypes.array
        })
    ).isRequired
};

export default ModalTabPanels;