import React from 'react';
import AddToCartButton from '../shared/AddToCartButton';

const Product = ({ Name, Price, Quantity, ImageUrl, Url, Code, addToCartEndpoint, translationUtility }) => {

    return (
        <div className="col-md-12 col-lg-6 product-container">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <a href={Url} title={Name}>
                                <img src={ImageUrl} alt={Name} className="img-fluid mx-auto d-block" style={{maxHeight: '85px'}}/>
                            </a>
                        </div>
                        <div className="col-9">
                            <div className="float-right">
                                <AddToCartButton
                                    code={Code}
                                    addToCartEndpoint={addToCartEndpoint}
                                    quantity={Quantity}
                                    text={translationUtility.translateResourceString("/Reorder/ReorderButton")}
                                ></AddToCartButton>
                            </div>
                            <a href={Url} title={Name}><h4 className="product-name">{Name}</h4></a>
                            <div className="product-price">{Price}</div>
                            <div className="product-quantity">{translationUtility.translateResourceString('/Reorder/QuantityLabel')}: {Quantity}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product;