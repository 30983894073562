import React from 'react'
import PropTypes from 'prop-types'

import { CheckoutSection } from '../../components'
import { notifyError } from '../UserMessaging'
import { Fade } from '../../componentAnimations'

import isEqual from 'lodash/isEqual'
import { translateResourceString } from '../../../util/translationUtility';
import {
    UPDATING_COMMENTS
} from '../../GlobalStateContainer'


class CheckoutComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
            userCheckoutComments: ''
        };
    }

    render() {


        return (
            <CheckoutSection label={translateResourceString("/Checkout/Comments/SectionLabel")} hidden={false}>

                <Fade inProp={!this.state.isCollapsed}>
                    <div className="coupon-body form-gray">
                        <div className="d-sm-flex">
                            <input
                                id="userCheckoutComments"
                                name="userCheckoutComments"
                                type="text"
                                className="form-control"
                                onChange={e => this.props.onCheckoutCommentsChange(e)}
                                value={this.props.poNumber}
                            />
                            
                        </div>
                    </div>
                </Fade>
            </CheckoutSection>
        );
    }
}

CheckoutComments.propTypes = {
    userCheckoutComments: PropTypes.string
}

export default CheckoutComments
