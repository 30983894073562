import React from 'react'
import PropTypes from 'prop-types'
import { translateResourceString } from '../../../util/translationUtility';

class CheckoutComplete extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.onCompleteCheckout(this.props.placeOrderDetails, this.props.useMyOwnAccountShippingId);
  }

  render() {
    return (
      <div className={`summary-complete-checkout text-center ${this.props.additionalClassname && this.props.additionalClassname}`}>
        <button
                className='btn btn-r-red btn-r-red--lg w-100'
          disabled={this.props.isPlaceOrderPending}
          onClick={(e) => this.onClick(e)}
        >
          {this.props.buttonText}
          {this.props.isPlaceOrderPending && 
            <span className={`fas fa-spinner fa-spin ml-2`}/>}
            </button>
            <div className="text-xs mt-1">{translateResourceString("/Checkout/Summary/Terms")} <a href="/terms-and-conditions/" target="_blank">{translateResourceString("/Checkout/Summary/TermsLink")} <i className="fas fa-xs fa-external-link-alt"></i></a></div>
        </div>
        
    );
  }
}

CheckoutComplete.propTypes = {
  onCompleteCheckout: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  placeOrderDetails: PropTypes.object.isRequired,
  additionalClassname: PropTypes.string,
  isPlaceOrderPending: PropTypes.bool
}



export default CheckoutComplete
