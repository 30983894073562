import React from 'react';
import FeaturedResource from '../Resources/FeaturedResource';

const FeaturedResources = ({
    featuredResources
    }) =>
{ 
    var colSize = featuredResources.ResourcesPerLine > 0 && featuredResources.ResourcesPerLine <= 12 ? 12 / featuredResources.ResourcesPerLine : 4;

    return (
        <div class="row rl-featured-resources">
            {featuredResources.ResourcePageLinks && featuredResources.ResourcePageLinks.map((resource, i) => 
                <div class={"col-12 col-md-"+colSize+" mb-"+colSize+" mb-md-0"}>
                    <a href={resource.PageUrl}>
                        {featuredResources.TileTag && featuredResources.TileTag !== '' &&
                            <div class="featured-resource-tag d-flex align-items-center bg-blue-light color-white px-1 text-xs font-weight-bold">
                                {featuredResources.TileTag}
                            </div>
                         }
                    <FeaturedResource 
                        resource={resource}/> 
                    </a>
                </div>          
             )}
            </div>
    );
}

FeaturedResources.propType = {};

export default FeaturedResources;