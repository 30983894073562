import {
  NOTIFY_COMPLETE_CHECKOUT_ERROR,
  PLACE_ORDER_ASYNC
} from '../../GlobalStateContainer'

export function isPlaceOrderPending(state = false, action) {
  switch (action.type) {
    case PLACE_ORDER_ASYNC:
      return true;
    case NOTIFY_COMPLETE_CHECKOUT_ERROR:
      return false;
    default:
      return state;
  }
}
