import { NEW_CHECKOUT_SUMMARY, NOTIFY_REFRESHING_CHECKOUT_SUMMARY } from "../../GlobalStateContainer";

const emptySummary = { Total: 0, SubTotal: 0, Discount:0, ShippingTotal: null, Currency: 'USD', TotalRemaining: 0, DiscountTotal: 0}

export const Summary = function (state = emptySummary, action) {
  switch (action.type) {
    case NEW_CHECKOUT_SUMMARY:
      return action.summary;
    default: 
      return state;
  }
}

export const isRefreshingSummary = function (state = true, action) {
  switch (action.type) {
    case NOTIFY_REFRESHING_CHECKOUT_SUMMARY:
      return true;
    case NEW_CHECKOUT_SUMMARY:
      return false;
    default:
      return state;
  }
}
