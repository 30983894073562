import {
    fetchJson, NEW_CHECKOUT_SUMMARY, NOTIFY_REFRESHING_CHECKOUT_SUMMARY
} from '../../GlobalStateContainer'
import { notifyError } from "../UserMessaging";
import { translateResourceString } from '../../../util/translationUtility';

export function notifyRefreshingSummary() {
    return { type: NOTIFY_REFRESHING_CHECKOUT_SUMMARY }
}

function onRefreshSummary(summary) {
    return { type: NEW_CHECKOUT_SUMMARY, summary }
}

const refreshSummarySpec = {
    endpoint: (state) => state.constants.navigation.cartSummaryEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: notifyRefreshingSummary,
    extract: (response) => response.json(),
    onSuccess: onRefreshSummary,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/ShippingMethod/Errors/ErrorRefreshing"), diagMsg)
}
export const refreshSummary = fetchJson(refreshSummarySpec)

