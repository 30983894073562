import fetch from 'cross-fetch';
import React from 'react';
import PropTypes from 'prop-types';
import TypeaheadResults from './TypeaheadResults';
import { buildSearchTermUrl } from './urlBuilder.js';
import { closeKeyboardOnScroll } from './closeKeyboardOnScroll';
import { scrollUp } from './closeKeyboardOnScroll';
import { addResourceStrings, translateResourceString } from '../util/translationUtility';

const MIN_LENGTH = 0;

const TYPE_INTERVAL_IN_MS = 300;

class TypeaheadApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            searchTerm: '',
            searchKey: null,
            showSuggestions: false,
            showMobileSearch: false,
            requests: [],
        };

        this.onSearchChange = this.onSearchChange.bind(this);
        this.needsToSearch = this.needsToSearch.bind(this);
        this.fetchResults = this.fetchResults.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onFocusMobile = this.onFocusMobile.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.fetchIntervalId = null;
        this.onMobileSearchClick = this.onMobileSearchClick.bind(this);
        this.onCancelMobileSearchClick = this.onCancelMobileSearchClick.bind(this);
        this.hideDocumentOverflow = this.hideDocumentOverflow.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        addResourceStrings(props.LocalizationItems);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleMouseDown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleMouseDown(event) {
        if (this.wrapperRef && !this.isDescendant(event.target, this.wrapperRef.id)) {
            this.setState({ showSuggestions: false });
            document.removeEventListener("scroll", closeKeyboardOnScroll);
        } else {
            this.setState({ showSuggestions: true });
        }
    }

    isDescendant(el, parentId) {
        let isChild = false

        if (el.id === parentId) {
            isChild = true
        }

        while (el = el.parentNode) {
            if (el.id == parentId) {
                isChild = true
            }
        }

        return isChild
    }

    setResults(searchTerm, result) {
        this.setState(prevState => {
            const {
                results,
            } = prevState;

            results[searchTerm] = {
                searchTerms: (
                    result &&
                    result.SearchTerms
                ) || [],
                articles: (
                    result &&
                    result.Articles
                ) || {},
                categories: (
                    result &&
                    result.Categories
                ) || {},
                products: (
                    result &&
                    result.Products
                ) || {},
                total: result && result.Total,
            };

            return ({
                results
            });
        })
    }

    fetchResults(searchTerm) {
        const { ApiEndPoint } = this.props;
        fetch(buildSearchTermUrl({ searchSubmitLink: ApiEndPoint, searchTerm }))
            .then(response => response.json())
            .then(result => this.setResults(searchTerm, result));
    }

    needsToSearch(searchKey) {
        return searchKey && searchKey.length > MIN_LENGTH && (!this.state.results || !this.state.results[searchKey]);
    }

    onKeyPress(event) {
        if (event.key === 'Enter') {
            event.target.form.submit();
        }
    }

    onSearchChange(event) {
        const searchKey = event.target.value;
        const searchTerm = event.target.value;
        this.setState(prevState => {
            let { requests } = prevState;
            if (searchKey.trim() === "") {
                requests = [];
            } else {
                requests.push(searchTerm);
            }
            return ({ searchKey, searchTerm, requests });
        });
        if (this.needsToSearch(searchKey)) {
            // console.log(`needs to search for ${searchKey}`);
            clearTimeout(this.fetchIntervalId);
            this.fetchIntervalId = setTimeout(() => this.fetchResults(searchKey), TYPE_INTERVAL_IN_MS)
        }
    }

    flattenResults() {
        const {
            results,
            searchKey
        } = this.state;

        if (!results || !searchKey || searchKey.length === 0 || !results[searchKey]) {
            return [];
        }

        const currentResults = results[searchKey];
        return [
            ...currentResults.searchTerms,
            ...currentResults.categories,
            ...currentResults.products,
            ...currentResults.articles
        ];
    }

    onFocus() {
        this.setState({ showSuggestions: true });
    }

    onFocusMobile() {
        this.setState({ showSuggestions: true });
        document.addEventListener("scroll", closeKeyboardOnScroll);
    }

    hideDocumentOverflow(hide) {
        //prevents body scroll when search is open so that scrolling is only possible within search
        document.body.style.overflow = hide ? "hidden" : ""
    }

    onMobileSearchClick() {
        this.setState({ showMobileSearch: true });
        this.hideDocumentOverflow(true);
        document.body.classList.add('iphone-scroll-fix');
        document.documentElement.classList.add('iphone-scroll-fix');
        //TODO hide the header when this function is activated.
        document.addEventListener("scroll", closeKeyboardOnScroll);
    }

    onCancelMobileSearchClick() {
        document.body.classList.remove('iphone-scroll-fix');
        document.documentElement.classList.remove('iphone-scroll-fix');
        this.setState({ showMobileSearch: false });
        this.hideDocumentOverflow(false);
        document.removeEventListener("scroll", closeKeyboardOnScroll);
        document.removeEventListener("touchend", scrollUp);

    }

    render() {
        const {
            SearchSubmitLink,
        } = this.props;
        const {
            results,
            searchTerm,
            selected,
            searchKey,
            showSuggestions,
            showMobileSearch,
            requests
        } = this.state;

        let searchResultsToShow = null;
        if (searchTerm.trim() !== "") {
            searchResultsToShow = results[searchKey]; // Check for results

            // if there are none, let's look at most recent requests
            if (!searchResultsToShow) {
                for (var i = requests.length - 1; i >= 0; i--) {
                    const resultsKey = requests[i];
                    if (results[resultsKey]) {
                        searchResultsToShow = results[resultsKey];
                        break;
                    }
                }
            }
        }

        const searchTerms = (
            searchResultsToShow &&
            searchResultsToShow.searchTerms
        ) || [];

        const articles = (
            searchResultsToShow &&
            searchResultsToShow.articles
        ) || [];

        const categories = (
            searchResultsToShow &&
            searchResultsToShow.categories
        ) || [];

        const products = (
            searchResultsToShow &&
            searchResultsToShow.products
        ) || [];

        const anyResults = (
            searchTerms.length > 0 ||
            articles.Total > 0 ||
            categories.Total > 0 ||
            products.Total > 0
        );

        const total = searchResultsToShow ? searchResultsToShow.total : 0;

        return (
            <div id="typeahead-app-inner">
                <div className="d-none d-md-flex">
                    <form
                        id="typeahead-wrapper"
                        action={SearchSubmitLink}
                        method="get"
                        ref={this.setWrapperRef}
                    >
                        <div className="input-group w-100">
                            <input
                                type="text"
                                name="searchString"
                                id="navbar-search"
                                className="form-control"
                                placeholder={translateResourceString("/Typeahead/Index/SearchPlaceholder")}
                                onChange={this.onSearchChange}
                                value={searchTerm}
                                onFocus={this.onFocus}
                                autoComplete="off"
                            />
                            <div className="input-group-append">
                                <button type="submit">
                                    <span class="rockland-icon-search text-white"></span>
                                    <span className="sr-only">{translateResourceString("/Typeahead/Index/SearchButtonScreenReader")}</span>
                                </button>
                            </div>
                            {anyResults && showSuggestions &&
                                <TypeaheadResults
                                    searchTerms={searchTerms}
                                    articles={articles}
                                    categories={categories}
                                    products={products}
                                    searchSubmitLink={SearchSubmitLink}
                                    currentSearchTerm={searchTerm}
                                    selected={selected}
                                    total={total}
                                />
                            }
                        </div>
                    </form>
                </div>
                <div className="d-flex d-md-none">
                    <form
                        id="typeahead-wrapper"
                        action={SearchSubmitLink}
                        method="get"
                        ref={this.setWrapperRef}
                    >
                        <div className="input-group w-100">
                            <input
                                type="text"
                                name="searchString"
                                id="navbar-search"
                                className="form-control"
                                placeholder={translateResourceString("/Typeahead/Index/SearchPlaceholder")}
                                onChange={this.onSearchChange}
                                value={searchTerm}
                                onFocus={this.onFocus}
                                autoComplete="off"
                                onClick={this.onMobileSearchClick}
                            />
                            <div className="input-group-append">
                                <button
                                    className="float-right d-md-none color-white"
                                    id="navbar-mini-search-button"
                                    onClick={this.onMobileSearchClick}
                                    type="submit"
                                >
                                    <span class="rockland-icon-search text-white"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="d-block d-md-none">
                    {showMobileSearch &&
                        <div id="typeahead-mobile-wrapper">
                        <div id="typeahead-mobile-results-search-wrapper" className="bg-blue-heading">
                                <form
                                id="typeahead-wrapper"
                                    action={SearchSubmitLink}
                                    method="get"
                                    className="p-2"
                                >
                                    <div className="input-group w-100">
                                        <input
                                            type="text"
                                            name="searchString"
                                            id="navbar-search"
                                            className="form-control"
                                            placeholder={translateResourceString("/Typeahead/Index/SearchPlaceholder")}
                                            onChange={this.onSearchChange}
                                            value={searchTerm}
                                            onFocus={this.onFocusMobile}
                                            autoFocus={true}
                                            autoComplete="off"
                                            onKeyPress={this.onKeyPress}
                                        />
                                        <input type="submit" className="d-none" value="submit" />
                                        <div className="input-group-append">
                                            <button
                                                onClick={this.onCancelMobileSearchClick}
                                                className="btn"
                                                type="submit"
                                            >
                                                <i class="rockland-icon-close-w"></i>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                                {anyResults &&
                                    <TypeaheadResults
                                        searchTerms={searchTerms}
                                        articles={articles}
                                        categories={categories}
                                        products={products}
                                        searchSubmitLink={SearchSubmitLink}
                                        currentSearchTerm={searchTerm}
                                        selected={selected}
                                        total={total}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

TypeaheadApp.propTypes = {
    SearchSubmitLink: PropTypes.string,
    ApiEndPoint: PropTypes.string,
    LocalizationItems: PropTypes.arrayOf(
        PropTypes.shape({
            Key: PropTypes.string,
            Value: PropTypes.string
        })
    )
};

export default TypeaheadApp;
