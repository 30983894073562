import React from 'react';
import TypeaheadEntry from './TypeaheadEntry';
import { buildSearchTermUrl } from './urlBuilder.js';
import PropTypes from 'prop-types';
import { translateResourceString } from '../util/translationUtility';

const TypeaheadResults = ({
    searchTerms,
    categories,
    products,
    articles,
    currentSearchTerm,
    searchSubmitLink,
    selected,
    total
}) => {
    const showProducts = products.Total > 0;
    const showArticles = articles.Total > 0;
    const showCategories = categories.Total > 0;
    return (
        <div id="typeahead-results">
            <div class="row">
                <div class="col-md-6 left">
                    {showProducts &&
                        <div className="">
                            <h3 className="headline headline-sm">{translateResourceString('/Typeahead/Index/Products', products.Total)}</h3>
                            <div
                                id="typeahead-results-products"
                                className="typeahead-results-list"
                            >
                                {products.Items.map((product, key) => {
                                    return (
                                        <TypeaheadEntry 
                                            key={key} 
                                            href={product.Url} 
                                            name={product.Name} 
                                            selected={selected && product === selected} 
                                            imageUrl={product.ImageUrl} 
                                            highlight={true}    
                                            currentSearchTerm={currentSearchTerm}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                    }
                </div>
                {showProducts && (showArticles || showCategories) &&
                    <div className="d-md-none col-sm-12 mobile-spacer">
                        <div className="pl-2 pr-2">
                            <div className="border"></div>
                        </div>
                    </div>
                }
                <div class="col-md-6 right">
                    {showArticles &&
                        <div className="">
                            <h3 className="headline headline-sm">{translateResourceString('/Typeahead/Index/Resources', articles.Total)}</h3>
                            <div
                                id="typeahead-results-articles"
                                className="typeahead-results-list"
                            >
                                {articles.Items.map((article, key) => {
                                    return (
                                        <TypeaheadEntry 
                                            key={key} 
                                            href={article.Url} 
                                            name={article.Name} 
                                            selected={selected && article === selected}
                                            highlight={true}    
                                            currentSearchTerm={currentSearchTerm}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    }

                    {categories.Total > 0 &&
                        <React.Fragment>
                            {showArticles &&
                                <div className="d-md-none mobile-spacer">
                                    <div className="">
                                        <div className="border"></div>
                                    </div>
                                </div>
                            }
                            <div className="">
                                <h3 className="headline headline-sm">{translateResourceString('/Typeahead/Index/Categories', categories.Total)}</h3>
                                <div
                                    id="typeahead-results-categories"
                                    className="typeahead-results-list"
                                >
                                    {categories.Items.map((category, key) => {
                                        return (
                                            <TypeaheadEntry 
                                                key={key} 
                                                href={category.Url} 
                                                name={category.Name} 
                                                selected={selected && category === selected} 
                                                highlight={true}    
                                                currentSearchTerm={currentSearchTerm}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </React.Fragment>
                    }

                </div>
            </div>
            {/* {searchTerms.length > 0 &&
                <div
                    id="typeahead-results-search-terms"
                    className="typeahead-results-list"
                >
                    <h5>{translationUtility.translateResourceString("/Typeahead/Index/SearchTermResultsHeading")}</h5>
                    {searchTerms.map((searchTerm, key) => {
                        const href = buildSearchTermUrl({ searchSubmitLink, searchTerm });
                        return (
                            <TypeaheadEntry key={key} href={href} name={searchTerm} selected={selected && searchTerm === selected} />
                        )
                    })}
                </div>
            } */}
            {currentSearchTerm &&
                <div className="row p-2">
                    <div className="col-md-12 text-center">
                        <a
                            className="btn btn-r-red-outline btn-r-red-outline--lg"
                            href={buildSearchTermUrl({ searchSubmitLink, searchTerm: currentSearchTerm })}
                            id="typeahead-results-see-all-results"
                        >
                            {translateResourceString("/Typeahead/Index/SeeAllResultsLink")} <i className="fa fa-long-arrow-right" />
                        </a>
                    </div>
                </div>
            }
        </div>
    )
}

TypeaheadResults.propTypes = {
    searchTerms: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string
    })),
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        ImageUrl: PropTypes.string
    })),
    articles: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string
    })),
    currentSearchTerm: PropTypes.string,
    searchSubmitLink: PropTypes.string,
    selected: PropTypes.any
};

export default TypeaheadResults;
