import fetch from 'cross-fetch';
import {serialize} from './urlBuilder.js';

/**
 * 
 * @param {string} clickTrackingApiEndpoint Url for API Call.
 * @param {string} hitId HitId for Find Result.
 * @param {string} trackId TrackId for Find Search.
 * @param {string} twoLetterLanguageCode Two letter language code IE 'en' for Find search.
 * @param {string} siteDefinitionId GUID for site where search took place.
 * @param {string} searchTerm Term used in search.
 * @returns {void}
 */
export function trackClick(clickTrackingApiEndpoint, hitId, trackId, twoLetterLanguageCode, siteDefinitionId, searchTerm) {
    const queryParameters = {
        trackId: trackId,
        twoLetterLanguageCode: twoLetterLanguageCode,
        siteDefinitionId: siteDefinitionId,
        searchTerm: searchTerm,
        hitId: hitId
    };
    const queryString = serialize(queryParameters);
    const url = `${clickTrackingApiEndpoint}${queryString}`;
    fetch(url);
}