import React from 'react';
import { translateResourceString } from '../../../util/translationUtility';
import Category from './Category';

const CategoriesContainer = ({categories, onSelect, openCategories, onToggleCategoryOpen, onCategoryPage}) => {
    return (
        <div className="categories-container">
            <div className="categories-header">
                {translateResourceString("/SearchApp/ProductCategoriesHeader")}
            </div>
            {categories.map(c => 
                <Category 
                    key={`category-${c.CategoryContentLinkId}`}
                    item={c}                    
                    onSelect={onSelect}
                    toggleOpen={() => onToggleCategoryOpen(c.CategoryContentLinkId)}
                    isOpen={openCategories.indexOf(c.CategoryContentLinkId) > -1}
                    onCategoryPage={onCategoryPage}
                />
            )}
        </div>
    )
}

export default CategoriesContainer; 