import React from 'react'
import { Tab } from 'react-tabs'
import PropTypes from 'prop-types'

const ModalTab = ({ 
    Name, 
    IsActive 
}) => {
    return (
        <Tab className={IsActive ? "list-option active" : "list-option"}>
            <h4 className="headline headline-sm">{Name}</h4>
        </Tab>
    );
};

ModalTab.propType = {
    Name: PropTypes.string.isRequired,
    IsActive: PropTypes.bool
};

export default ModalTab;