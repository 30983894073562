import React from 'react'
import PropTypes from 'prop-types'

const ModalTab = ({ 
    Name,
    AddProduct
 }) => {
    return (
        <li className="list-option" onClick={() => AddProduct(Name)}>
            <h4 className="headline headline-sm">{Name}</h4>
        </li>
    );
};

ModalTab.propType = {
    Name: PropTypes.string.isRequired,
    AddProduct: PropTypes.func
};

export default ModalTab;