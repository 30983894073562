import find from 'lodash/find'

import { fetchJson } from '../../GlobalStateContainer'
import { isValidGuid } from '../../../util/guidUtility'
import { translateResourceString } from '../../../util/translationUtility';

import {
    NOTIFY_ERROR,
    USE_BILLING_FOR_SHIPPING_TOGGLE,
    ADD_SHIPPING_ADDRESS,
    ADD_BILLING_ADDRESS,
    REMOVE_SHIPPING_ADDRESS,
    REMOVE_BILLING_ADDRESS,
    UPDATE_ADDRESS,
    SELECT_BILLING_ADDRESS,
    SELECT_SHIPPING_ADDRESS,
    SAVE_ADDRESS_ASYNC,
    DELETE_ADDRESS_ASYNC,
    SELECT_SHIPPING_AND_BILLING_ADDRESS
} from '../../GlobalStateContainer'

import {
    notifyError,
    logError
} from '../UserMessaging'
/*
 *  SYNC ACTION CREATORS
 */

let nextAddressId = -1;

let setAddressIdIfMissing = (newAddress) => {
    if (newAddress.Id === "")
        return Object.assign({}, { Id: (nextAddressId--).toString() }, newAddress);
    else if (newAddress.Id < 0) {
        return notifyError(translateResourceString("/Checkout/Address/Errors/ErrorAdding"), translateResourceString("/Checkout/Address/Errors/NegativeAddressId"));
    }
}

export function addBillingAddress(newAddress) {
    var addressWithIdOrError = setAddressIdIfMissing(newAddress);
    if (addressWithIdOrError && addressWithIdOrError.type && addressWithIdOrError.type === NOTIFY_ERROR)
        return addressWithIdOrError;
    return { type: ADD_BILLING_ADDRESS, address: addressWithIdOrError }
}

export function addShippingAddress(newAddress) {
    var addressWithIdOrError = setAddressIdIfMissing(newAddress);
    if (addressWithIdOrError && addressWithIdOrError.type && addressWithIdOrError.type === NOTIFY_ERROR)
        return addressWithIdOrError;
    return { type: ADD_SHIPPING_ADDRESS, address: addressWithIdOrError }
}

export function removeShippingAddress(addressId) {
    return { type: REMOVE_SHIPPING_ADDRESS, addressId }
}

export function removeBillingAddress(addressId) {
    return { type: REMOVE_BILLING_ADDRESS, addressId }
}

export function selectBillingAddress(addressId) {
    return { type: SELECT_BILLING_ADDRESS, addressId }
}

export function selectShippingAddress(addressId) {
    return { type: SELECT_SHIPPING_ADDRESS, addressId }
}

export function updateAddress(addressId, address) {
    return { type: UPDATE_ADDRESS, addressId, address }
}

export function selectShippingAndBillingAddress(addressId) {
    return { type: SELECT_SHIPPING_AND_BILLING_ADDRESS, addressId }
}

export function UseBillingForShippingToggle() {
    return { type: USE_BILLING_FOR_SHIPPING_TOGGLE }
}


/*
 *  ASYNC ACTION CREATORS
 */

const onPostAddressSucceeded = (responseText, postedAddress) =>
    (isValidGuid(responseText))
        ? updateAddress(postedAddress.Id, Object.assign({}, postedAddress, { Id: responseText }))
        : notifyError(translateResourceString("/Checkout/Address/Errors/ErrorSaving"), `${translateResourceString("/Checkout/Address/Errors/ExpectedGuid")}: '${responseText}'`);

const saveAddressFetchSpec = {
    endpoint(state) { return state.constants.navigation.addAddressEndpoint; },
    init: { method: 'POST', credentials: 'same-origin' },
    notify(address) { return { type: SAVE_ADDRESS_ASYNC, address }; },
    extract(response) { return response.text() },
    onSuccess: onPostAddressSucceeded,
    onError(diagMsg) { return notifyError(translateResourceString("/Checkout/Address/Errors/ErrorSaving"), diagMsg); }
};

export const saveAddress = fetchJson(saveAddressFetchSpec);

const onPostDeleteSucceeded = (responseText, { addressId }, getState) => {
    if (responseText.toUpperCase() !== "OK")
        return notifyError(translateResourceString("/Checkout/Address/Errors/ErrorDeleting"),
            `${translateResourceString("/Checkout/Address/Errors/ExpectedOkDeleting")} '${responseText}'`);
    else {
        const state = getState();
        const postedAddress = find(state.entities.Addresses, address => address.Id === addressId);
        if (!postedAddress)
            return logError(`No address to delete with id ${addressId}`);
        return (postedAddress.AddressType === state.constants.BillingAddressType)
            ? removeBillingAddress(postedAddress.Id)
            : removeShippingAddress(postedAddress.Id);
    }
};

const deleteAddressFetchSpec = {
    endpoint: (state) => state.constants.navigation.deleteAddressEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: ({ addressId }) => ({ type: DELETE_ADDRESS_ASYNC, addressId }),
    extract: (response) => response.text(),
    onSuccess: onPostDeleteSucceeded,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Address/Errors/ErrorDeleting"), diagMsg)
}

export const deleteAddress = fetchJson(deleteAddressFetchSpec)

