import React from 'react';
import PropTypes from 'prop-types';
import { translateResourceString } from '../../../util/translationUtility';

class ProductTile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            SKU,
            Name,
            Url,
            Image,
            onClick,
            SpeciesReactivity,
            ExpressedIn,
            Sizes,
            HostAnimal,
            Clonality,
            CloneId,
            Subclass,
            Application,
            ReferenceCount
        } = this.props;

        const subName = [ HostAnimal, Clonality, CloneId, Subclass ].filter(x => x && x.length > 0).join(' ');

        let referenceCountStyle = "reference-count";
        if (ReferenceCount === 0) {
            referenceCountStyle += " d-none";
        }
 
        return (
            <a href={Url} title={Name} onClick={onClick} className="d-block item-tile product-tile link-wrapper">
                <div className="product-tile-inner">
                    <div class="d-flex justify-content-center img-bg">
                        <img className="lazyload img-fluid align-self-center" data-src={Image?.Url} />
                    </div>
                    <div className="description">
                        <h2 className="headline headline-md color-blue-light">{Name }</h2>
                        <p className="sub-name">{subName}</p>
                        <p className={referenceCountStyle}><span className="fas fa-book-open mr-1"></span>{ReferenceCount} References</p>
                        <p className="size"><span className="spec-heading">{translateResourceString("/SearchApp/ProductTile/Size")}:</span> {Sizes}</p>
                        {Application &&
                            <p className="application">
                                <span className="spec-heading">{translateResourceString("/SearchApp/ProductTile/Application")}:</span> {Application}
                            </p>
                        }
                        {SpeciesReactivity &&
                            <p className="species-reactivity">
                                <span className="spec-heading">{translateResourceString("/SearchApp/ProductTile/Reactivity")}:</span> {SpeciesReactivity}
                            </p>
                        }
                        {ExpressedIn &&
                            <p className="expressed-in">
                                <span className="spec-heading">{translateResourceString("/SearchApp/ProductTile/ExpressedIn")}:</span> {ExpressedIn}
                            </p>
                        }
                    </div>
                    <div className="end">
                        <span className="code"><i className="fas fa-barcode"></i> {SKU}</span>
                        <div className="cta pt-1">
                            <div className="btn btn-r-red-outline">{translateResourceString("/SearchApp/ProductTile/CTA")}</div>
                        </div>
                    </div>
                </div>
            </a>);
    }
}


ProductTile.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    priceViewModel: PropTypes.shape({
        RegularPrice: PropTypes.number,
        SalePrice: PropTypes.number,
        IsSalePrice: PropTypes.bool
    }),
    image: PropTypes.shape({
        Url: PropTypes.string,
        Alt: PropTypes.string
    }),
    SpeciesReactivity: PropTypes.string,
    ExpressedIn: PropTypes.string,
    Sizes: PropTypes.string,
    HostAnimal: PropTypes.string,
    Clonality: PropTypes.string,
    CloneId: PropTypes.string,
    Subclass: PropTypes.string,
    Application: PropTypes.string
};

export default ProductTile;
