// eslint-disable-next-line
import React from 'react'
import find from 'lodash/find'
import { connect } from 'react-redux'
import { translateResourceString } from '../../../util/translationUtility';

import {
    addBillingAddress,
    selectBillingAddress,
    deleteAddress,
    updateAddress
} from "./addressActionCreators"

import CheckoutAddressComponent from "./CheckoutAddressComponent"

const mapBillingAddressStateToProps = (state) => {
    const e = state.entities;
    const c = state.constants;
    const useShippingForBilling = state.ui.useShippingForBilling;
    return {
        addresses: e.BillingAddresses.map(id => find(e.Addresses, address => address.Id === id)),
        allowAdding: c.allowAddingBillingAddresses,
        selectedAddressId: e.SelectedBillingAddressId,
        addressType: c.BillingAddressType,
        addressActionCreators: {
            add: addBillingAddress,
            remove: deleteAddress,
            select: selectBillingAddress,
            update: updateAddress
        },
        selectAddressMessage: translateResourceString("/Checkout/Address/CheckoutBillingAddress/SelectAddressMessage"),
        sectionLabel: translateResourceString("/Checkout/Address/CheckoutBillingAddress/SectionLabel"),
        hidden: useShippingForBilling
    }
};

const mapBillingAddressDispatchToProps = (dispatch) => ({
    onEndEdit: (Id) => dispatch(selectBillingAddress(Id))
})
const CheckoutBillingAddressContainer = connect(
    mapBillingAddressStateToProps,
    mapBillingAddressDispatchToProps
)(CheckoutAddressComponent)

export default CheckoutBillingAddressContainer;