import React from 'react'
import { TabList } from 'react-tabs'
import PropTypes from 'prop-types'
import ModalTab from './ModalTab'
import NewListInput from '../../Components/NewListInput'
import { translateResourceString } from '../../../util/translationUtility'

const ModalTabList = ({
    Lists = [],
    UpdateLists,
    ActiveTabIndex,
    SelectActiveTab = null,
    CreateListEndpoint
}) => {
    return (
        <TabList className="col-12 col-lg-8 p-0 d-flex flex-column" style={{ height: "100%" }}>
            <ul className="p-0 list-modal-lists">
                {
                    Lists.map((list, i) =>
                        <ModalTab key={i} {...list} IsActive={ActiveTabIndex === i ? true : false} />
                    )
                }
            </ul>
            <div className="p-2 mt-1">
                <h4>{translateResourceString("/Wishlists/ViewAndAddModal/CreateListLabel")}</h4>
                <NewListInput 
                    Lists={Lists} 
                    UpdateLists={UpdateLists} 
                    SelectActiveTab={SelectActiveTab}
                    CreateListEndpoint={CreateListEndpoint} />
            </div>
        </TabList>
    );
};

ModalTabList.propType = {
    Lists: PropTypes.array.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ActiveTabIndex: PropTypes.number.isRequired,
    SelectActiveTab: PropTypes.func,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default ModalTabList;
