import PropTypes from 'prop-types'

export const shapeWithIdOfString         = PropTypes.shape({Id: PropTypes.string.isRequired});
export const shapeWithIdOfAny            = PropTypes.shape({Id: PropTypes.any.isRequired});
export const arrayOfShapesWithIdOfString = PropTypes.arrayOf(shapeWithIdOfString);
export const arrayOfShapesWithIdOfAny    = PropTypes.arrayOf(shapeWithIdOfAny);
export const GoogleTagManagerProductFieldObject = PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    price: PropTypes.number,
    brand: PropTypes.string,
    category: PropTypes.string,
    variant: PropTypes.string,
    quantity: PropTypes.number
})
