import React from 'react'
import PropTypes from 'prop-types'

import { TextFormEntry, StateInput } from '../../components'

import { AddressShape } from './propTypes'
import { translateResourceString } from '../../../util/translationUtility';


const requiredFields = ["name", "organizationname", "line1", "country", "city", "state", "postalCode"];
const requiredFieldsShipTo = ["name", "organizationname", "line1", "country", "city", "state", "postalCode", "phone"];

export class AddressEditor extends React.Component {
    constructor(props) {
        super(props);
        const newAddress = {
            AddressLine1: "",
            AddressLine2: "",
            AddressType: props.addressType,
            City: "",
            CountryCode: "USA",
            CountryName: "",
            Id: "",
            Name: "",
            OrganizationName: "",
            Phone: "",
            PostalCode: "",
            State: ""
        };
        this.state = {
            address: props.address || Object.assign({}, newAddress),
            submitAttempted: false,
            countriesStatesMap: props.countriesStatesMap,
            invalidFields: {},
            originalId: props.address && props.address.Id,
            validationRun: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
        this.isInvalid = this.isInvalid.bind(this);
        this.isRequired = this.isRequired.bind(this);
    }

    isRequired(name) {
        if (this.state.address.AddressType === 2) {
            return requiredFieldsShipTo.indexOf(name) > -1;
        } else {
            return requiredFields.indexOf(name) > -1;
        }
    }

    validateField(invalidFields, fieldName, value) {
        if (this.isInvalid(value, fieldName)) {
            invalidFields[fieldName] = true;
        } else {
            invalidFields[fieldName] = false;
        }
    }

    handleChange(event) {
        const address = Object.assign({}, this.state.address);
        const value = event.target.value;
        const name = event.target.name;
        switch (name) {
            case "name":
                address.Name = value;
                break;
            case "organizationname":
                address.OrganizationName = value;
                break;
            case "line1":
                address.AddressLine1 = value;
                break;
            case "line2":
                address.AddressLine2 = value;
                break;
            case "city":
                address.City = value;
                break;
            case "state":
                address.State = value;
                break;
            case "postalCode":
                address.PostalCode = value;
                break;
            case "phone":
                address.Phone = value;
                break;
            default:
                if (typeof console.warn !== 'undefined')
                    console.warn(`Unknown value change with name '${name}' and value '${value}'`);
        }
        const invalidFields = Object.assign({}, this.state.invalidFields);
        this.validateField(invalidFields, name, value);
        this.setState({ address: address, invalidFields: invalidFields });
    }

    handleCountryChange(event) {
        const countryWithCode = this.props.countries.filter((c) => c.Code === event.target.value);
        if (countryWithCode.length > 0) {
            this.setState({
                address: Object.assign({},
                    this.state.address,
                    {
                        CountryCode: event.target.value,
                        CountryName: countryWithCode[0].Code
                    })
            });
        }
        event.preventDefault();
    }

    onSubmit(event) {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            return;
        }
        const keys = this.state.invalidFields ? Object.keys(this.state.invalidFields) : [];
        const invalidFieldNames = keys.filter((key) => this.state.invalidFields[key]);
        let errorMessage = "";
        if (invalidFieldNames.length > 0) {
            errorMessage = `Please fix the following fields: ${invalidFieldNames.join(", ") + "."}`;
            this.setState({ errorMessage: errorMessage });
        } else {
            this.props.onEditOk(this.state.address);
        }
    }

    isInvalid(value, inputName) {
        if ((!value || value.length < 1) && this.isRequired(inputName)) {
            return true;
        }
        return false;
    }

    render() {
        var editorTitle;
        const selectedAddress = this.state.address;
        const countryOptions = this.props.countries.map((country) =>
            <option key={country.Code} value={country.Code}>{country.Name}</option>
        );
        let recipientName = selectedAddress.AddressType === 2 ?
            <TextFormEntry name={'name'} value={selectedAddress.Name} label={translateResourceString("/Address/Name")}
                onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
            /> : null;

        return (
            <form className="addressEditor" onSubmit={this.onSubmit} style={{ margin: 17 }}>
                {this.state.submitted && this.state.errorMessage &&
                    <div className="alert alert-danger" role="alert">
                        <div>{this.state.errorMessage}</div>
                    </div>
                }
                <p className="text-xs">{translateResourceString("/Address/RequiredLegend")}</p>
                <TextFormEntry name={'organizationname'} label={translateResourceString("/Address/OrganizationName")} value={selectedAddress.OrganizationName}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'organizationname'}
                />
                {recipientName}
                <TextFormEntry name={'line1'} label={translateResourceString("/Address/AddressLine1")} value={selectedAddress.AddressLine1}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'address-line1'}
                />
                <TextFormEntry name={'line2'} label={translateResourceString("/Address/AddressLine2")} value={selectedAddress.AddressLine2}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'address-line'}
                />
                <div className={`${this.isInvalid("country") ? "error" : ""} input-container `}>
                    <label htmlFor="country" className="control-label">{translateResourceString("/Address/Country")}*</label>
                    <div>
                        <select
                            value={selectedAddress.CountryCode}
                            onChange={this.handleCountryChange}
                            required
                            className="form-control"
                        >
                            {countryOptions}
                        </select>
                    </div>
                </div>
                <TextFormEntry name={'city'} value={selectedAddress.City} label={translateResourceString("/Address/City")}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'address-level2'}
                />
                <div className={`input-container ${(this.state.address.State === '') ? "error" : ""}`}>
                    <label htmlFor="state" className="control-label">{translateResourceString("/Address/State")}*</label>
                    <div>
                        <StateInput
                            CountryCode={selectedAddress.CountryCode}
                            value={selectedAddress.State}
                            countriesStatesMap={this.state.countriesStatesMap}
                            onChange={this.handleChange}
                            isRequired={this.isRequired}
                        />
                    </div>
                </div>
                <TextFormEntry name='postalCode' value={selectedAddress.PostalCode} label={translateResourceString("/Address/PostalCode")}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'postal-code'}
                />
                <TextFormEntry type='tel' name={'phone'} value={selectedAddress.Phone} label={translateResourceString("/Address/Phone")}
                    onChange={this.handleChange} isInvalid={this.isInvalid} isRequired={this.isRequired}
                    autocomplete={'tel'}
                />
                <div className="form-group row">
                    <div className="col-12">
                        <button type="submit" className="btn btn-r-red" disabled="">{translateResourceString("/Checkout/Address/SaveSelectButton")}</button>
                        <button type="button" className="btn btn-link" style={{ marginLeft: 20 }} onClick={this.props.onEditCancel} value="Cancel">{translateResourceString("/Checkout/Address/CancelButton")}</button>
                    </div>
                </div>
            </form>

        );
    }
}

AddressEditor.propTypes = {
    addressType: PropTypes.number.isRequired,
    address: AddressShape.propTypes,
    countriesStatesMap: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onEditOk: PropTypes.func.isRequired
}

export default AddressEditor
