import React from 'react'
import { connect } from 'react-redux'

import { updatePurchaseOrderNumber } from './paymentMethodActionCreators'

import { Fade } from '../../componentAnimations'
import { translateResourceString } from '../../../util/translationUtility';

const PurchaseOrderComponent = ({
    poNumber,
    selected,
    onChange,
    label = translateResourceString("/Checkout/PaymentInformation/Purchase/PONumber"),
    placeholder = translateResourceString("/Checkout/PaymentInformation/Purchase/PONumberPlaceholderRequired"),
    onPaymentCancel,
    onPaymentIsValid
}) => (
    <div className='col-12'>
        <Fade inProp={selected}>
            <div className="py-2 px-3">
            <div className='form-group'>
                <label htmlFor='ponumber'>{label}</label>
                <input
                    className="form-control"
                    name='ponumber'
                    id='ponumber'
                    type='text'
                    placeholder={placeholder}
                    onChange={e => (e.key === "Enter" && onPaymentIsValid(e)) || onChange(e)}
                    value={poNumber}
                />
            </div>
            <a
                className={`btn btn-r-red`}
                onClick={onPaymentIsValid}
                onKeyPress={e => e.key === "Enter" && onPaymentIsValid(e)}
                style={{ marginRight: 20 }}
                tabIndex={0}
            >
                {translateResourceString("/Checkout/PaymentInformation/Braintree/UseMethodButton")}
            </a>
            <a
                className={`btn btn-r-red-outline`}
                onClick={(e) => onPaymentCancel(e)}
                tabIndex={0}
            >
                {translateResourceString("/Checkout/PaymentInformation/Braintree/CancelButton")}
                </a>
             </div>
        </Fade>
    </div>
)

const mapStateToProps = (state, ownProps) => ({
    poNumber: state.entities.PaymentRecord.POValue || "",
    selected: state.entities.SelectedPaymentOptionId === state.constants.purchaseOrderPaymentId,
    ...ownProps
})

const mapDispatchToProps = dispatch => ({
    onChange: (e) => (e && e.target && dispatch(updatePurchaseOrderNumber(e.target.value)))
})

const PurchaseOrderComponentContainer = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderComponent)

export default PurchaseOrderComponentContainer
