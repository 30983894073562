import {
    fetchJson,
    UPDATE_ITEM_QUANTITY,
    DELETE_ITEM,
    UPDATING_ITEM_QUANTITY_ASYNC,
    DELETING_ITEM_ASYNC
} from '../../GlobalStateContainer'

import { notifyError } from '../UserMessaging'
import { translateResourceString } from '../../../util/translationUtility';

/* CART */
export function updateItemQuantity(itemId, newQuantity) {
    return { type: UPDATE_ITEM_QUANTITY, itemId, newQuantity }
}

export function deleteItem(itemId) {
    return { type: DELETE_ITEM, itemId }
}



const onUpdateQuantitySuccess = ({ error, message }, { id, qty }, getState) => {
    if (!error) {
        return updateItemQuantity(id, qty);
    } else {
        return notifyError(translateResourceString("/Checkout/Cart/Errors/UpdateQuantityFailed"), message);
    }
}

const notifyUpdatingItem = function ({ id, qty }) {
    return { type: UPDATING_ITEM_QUANTITY_ASYNC, id, qty };
}
const updateItemQuantityAsyncSpec = {
    endpoint: (state) => state.constants.navigation.updateItemQuantityEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: notifyUpdatingItem,
    extract: (response) => response.json(),
    onSuccess: onUpdateQuantitySuccess,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Cart/Errors/UpdateQuantityFailed"), diagMsg)
}

export const updateItemQuantityAsync = fetchJson(updateItemQuantityAsyncSpec)


const ondeleteSuccess = ({ error, message }, { id }, getState) => {
    if (!error) {
        return deleteItem(id);
    } else {
        return notifyError(translateResourceString("/Checkout/Cart/Errors/DeleteItemFailed"), message);
    }
}

const deleteItemAsyncSpec = {
    endpoint: (state) => state.constants.navigation.deleteItemEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: ({ id }) => ({ type: DELETING_ITEM_ASYNC, id }),
    extract: (response) => response.json(),
    onSuccess: ondeleteSuccess,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Cart/Errors/DeleteItemFailed"), diagMsg)
}

export const deleteItemAsync = fetchJson(deleteItemAsyncSpec)