import React from 'react';
import PropTypes from 'prop-types';
import Order from './Order';
import { TranslationUtility } from '../util/translationUtility';

class Reorder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCount: props.OrderCount,
            orders: []
        };
        this.translationUtility = new TranslationUtility(props.LocalizationItems);
    }

    componentDidMount() {
        require('./reorderApp.css');
        fetch(`${this.props.OrdersApiEndpoint}?skip=0&take=20`)
            .then(response => response.json())
            .then(json => this.setState({
                orders: json
            }));
    }

    render() {
        const { orders } = this.state;
        return (
            <div className="reorder-container">
                {orders.map((order, i) =>
                    <Order
                        key={i}
                        {...order}
                        {...this.props}
                        startOpen={i === 0}
                        translationUtility={this.translationUtility}
                    />
                )}
            </div>

        );
    }
}

Reorder.propTypes = {
    OrdersApiEndpoint: PropTypes.string,
    ProductsApiEndpoint: PropTypes.string,
    AddToCartEndpoint: PropTypes.string,
    OrderCount: PropTypes.number
};

export default Reorder;