import React from 'react';
import PropTypes from 'prop-types';

import { NumberInputSelect } from '../../components';
import { translateResourceString } from '../../../util/translationUtility';

const maxDropdownQuantity = 10;

class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.onQuantityChange = this.onQuantityChange.bind(this);
    }


    onQuantityChange(qty) {
        if (qty > 0) {
            this.props.onQuantityChange(this.props.item.Id, Number(qty));
        } else {
            this.props.onDelete(this.props.item.Id, this.props.item.Quantity);
        }
    }

    render() {
        const item = this.props.item;


        return (
            <div className="media cart-item">
                <img alt={item.Name} className="mr-2" src={item.ImageUrl} style={{ "width": "120px" }} />
                <div className="d-flex justify-content-between w-100">
                    <div className="item-info pr-1">
                        <div className="item-name">
                            <h4 className="headline headline-sm mb-0">{item.Name}</h4>
                        </div>
                        <div className="item-meta d-flex mb-2">
                            <label className="sku mb-2">{translateResourceString("/Checkout/Cart/ItemSkuLabel")}</label>&nbsp;{item.SKU}
                        </div>
                    </div>
                    <div className="d-flex">
                        {item.IsSalePrice ?
                            <div>
                                {/*{translateResourceString("/Checkout/Cart/ItemPriceLabel")}:*/}
                                <div className='strikethrough mr-2'>
                                    <span>
                                        {item.OriginalPrice}
                                    </span>
                                </div>
                                <div className='price-regular fw-600 mb-2 mr-2'>
                                    {this.props.item.Price}
                                </div>
                            </div>
                            :
                            <div className='price-regular fw-600 mb-2 mr-2'>
                                {/*{translateResourceString("/Checkout/Cart/ItemPriceLabel")}:*/} {this.props.item.Price}
                            </div>
                        }

                        {!this.props.item.QuoteProduct ?
                            <NumberInputSelect
                                className="input-container d-flex"
                                maxDropdownQuantity={maxDropdownQuantity}
                                componentClassName={"checkout-cart-item-quantity"}
                                quantity={this.props.item.Quantity}
                                onChange={this.onQuantityChange}
                                onDelete={(e) => {
                                    typeof e !== 'undefined' && typeof e.preventDefault !== 'undefined' && e.preventDefault();
                                    this.props.onDelete(item.Id, this.props.item.Quantity);
                                }}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

CartItem.propTypes = {
    item: PropTypes.shape({
        Name: PropTypes.string.isRequired,
        ImageUrl: PropTypes.string.isRequired,
        SKU: PropTypes.string.isRequired,
        OriginalPrice: PropTypes.string,
        IsSalePrice: PropTypes.bool,
        Quantity: PropTypes.number.isRequired
    }).isRequired,
    onDelete: PropTypes.func,
    onQuantityChange: PropTypes.func.isRequired
}

export default CartItem;
