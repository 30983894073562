import React from 'react'
import PropTypes from 'prop-types'

const ProductItem = ({ 
    Name, 
    ImageUrl,
    Link 
}) => {
    return (
        <div>
            <a href={Link} title="12345" target="_blank" rel="noopener noreferrer">
                <img alt="12345" className="img-fluid" src={ImageUrl} />
            </a>
            <div className="item-name">
                <a href={Link} title="12345" target="_blank" rel="noopener noreferrer">
                    <h4 className="small">{Name}</h4>
                </a>
            </div>
        </div>
    );
};

ProductItem.propType = {
    Name: PropTypes.string,
    ImageUrl: PropTypes.string,
    Link: PropTypes.string
};

export default ProductItem;