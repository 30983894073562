import React from 'react'
import PropTypes from 'prop-types'

import { optionListItemStyle } from '../styles'

class OptionListItemButtonWithOwnComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    this.props.onClick(e, this.props.id);
  }

  render() {
    return (
      <li
        style={optionListItemStyle}
      >
        <button
          className={`btn btn-${this.props.isSelected ? 'info' : 'default'}`}
          onClick={e => this.onClick(e)}>
          {this.props.label}
        </button>
        {this.props.children}
      </li>
    );
  }
}

OptionListItemButtonWithOwnComponent.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

export default OptionListItemButtonWithOwnComponent