// BBS DDD 2020-06-02 TODO: replace lodash usage here. With vanilla js or other lib?
import uniqBy from 'lodash/uniqBy'
import find from 'lodash/find'

import { BraintreePaymentComponent, PurchaseOrderComponent } from '../Features/PaymentMethod'
import { translateResourceString, addResourceStrings } from '../../util/translationUtility';
import { INFO_USER_ALERT } from '../Features/UserMessaging/userAlertTypes'
import {CheckoutShipment, UseMyAccountComponent} from "../Features/ShippingMethod";

// https://lodash.com/docs/4.17.10#uniqBy
const isAllUniqueValues = (list, iteratee = x => x) => {
    const len = list.length;
    const uniqLength = uniqBy(list, iteratee).length;
    return len === uniqLength;
}


//const addressesAreInvalid = (addresses) => {
//  if (!isAllUniqueValues(addresses.map(address => address.Id))) {
//    console.error('not all Ids unique for ShippingAddresses and BillingAddresses');
//    return true;
//  }
//}

const paymentOptionsAreInvalid = (paymentOptions) => {
    if (!isAllUniqueValues(paymentOptions, po => po.Id)) {
        console.error('not all Ids unique for PaymentOptions');
        return true;
    }
}

const cartItemsAreInvalid = (cartItems) => {
    if (!isAllUniqueValues(cartItems, ci => ci.Id)) {
        console.error('not all Ids unique for CartItems');
        return true;
    }
}

// Perhaps https://validatejs.org/ would be useful here
const initialDataIsInvalid = (initialData) => {
    if (paymentOptionsAreInvalid(initialData.PaymentOptions)) {
        return true;
    }
    if (cartItemsAreInvalid(initialData.CartItems)) {
        return true;
    }
}

const mapTopLevelPropsToInitialStoreState = props => {
    // validate
    if (initialDataIsInvalid(props.initialData)) {
        return {};
    }

    addResourceStrings(props.localizationItems);

    // Add controls to payment if they have one
    const paymentOptions = props.initialData.PaymentOptions.map(po => {
        if (po.Id === props.BraintreeClientSettings.BraintreePaymentOptionId) {
            po.paymentOptionComponent = BraintreePaymentComponent;
        } else if (po.Id === props.purchaseOrderPaymentId) {
            po.paymentOptionComponent = PurchaseOrderComponent;
        }
        return po;
    });

    const shippingOptions = props.initialData.ShippingOptions.map(so => {
       if(so.Id === props.initialData.UseMyAccountShippingSettings.UseMyAccountShippingMethodId){
            so.UseMyAccountComponent = UseMyAccountComponent;
       }
       else {
           so.UseMyAccountComponent = null;
       }
       return so;
    });

    let selectedPaymentOption = find(paymentOptions, po => po.IsSelected);
    if (!selectedPaymentOption) {
        selectedPaymentOption = paymentOptions[0];
    }

    const customerCheckoutMessage = props.initialData.CustomerCheckoutMessage === null ? null : {
        Id: 0,
        type: INFO_USER_ALERT,
        text: props.initialData.CustomerCheckoutMessage
    }

    const allowBillingEqualsShipping = props.initialData.AllowBillingEqualsShipping;
    const selectedShippingAddressId = props.initialData.SelectedShippingAddressId;
    const selectedBillingAddressId = (allowBillingEqualsShipping && selectedShippingAddressId)
        ? (selectedShippingAddressId)
        : (props.initialData.BillingAddresses[0] && props.initialData.BillingAddresses[0].Id);
    return {
        // BBS DDD 2017-11-19 The state of the ui. Make all ui updates here.
        ui: {
            PhoneNumber: props.initialData.PhoneNumber,
            PhoneText: props.initialData.PhoneText,
            useShippingForBilling: allowBillingEqualsShipping &&
                selectedBillingAddressId === selectedShippingAddressId,
            messages: customerCheckoutMessage === null ? [] : [customerCheckoutMessage],
            completeCheckoutButtonText: "Place Order",
            isPlaceOrderPending: false
        },
        // BBS DDD 2017-11-19 The state of the checkout. Make all business
        // updates here
        entities: {
            Addresses: props.initialData.BillingAddresses.concat(props.initialData.ShippingAddresses),
            BillingAddresses: props.initialData.BillingAddresses.map(addr => addr.Id),
            SelectedBillingAddressId: selectedBillingAddressId,
            ShippingAddresses: props.initialData.ShippingAddresses.map(addr => addr.Id),
            SelectedShippingAddressId: props.initialData.SelectedShippingAddressId,
            CartItems: props.initialData.CartItems,
            Coupons: props.initialData.CouponViewModels,
            PaymentOptions: paymentOptions,
            SelectedPaymentOptionId: '',
            ShippingOptions: shippingOptions,
            isRefreshingShippingOptions: false,
            SelectedShippingOptionId: props.initialData.SelectedShippingMethodId,
            useMyAccountShippingSettings: {
                UseMyAccountShippingMethodId: props.initialData.UseMyAccountShippingSettings.UseMyAccountShippingMethodId,
                AccountNumber: props.initialData.UseMyAccountShippingSettings.AccountNumber,
                SelectedCarrier: props.initialData.UseMyAccountShippingSettings.SelectedCarrier,
                Carriers: props.initialData.UseMyAccountShippingSettings.Carriers
            },
            Summary: {
                Total: 0,
                SubTotal: 0,
                ShippingTotal: null,
                Currency: 'USD',
                TotalRemaining: 0,
                DiscountTotal: 0,
                HandlingTota: 0
            },
            isRefreshingSummary: true,
            PaymentRecord: {}
        },
        // BBS DDD 2017-11-19 Data definitions and other things
        constants: {
            // Locations of server resources
            navigation: {
                MyAccountLink: '',
                checkoutUrl: props.checkoutUrl,
                ReceiptUrl: props.initialData.ReceiptUrl,
                addCouponEndpoint: props.addCouponEndpoint,
                removeCouponEndpoint: props.removeCouponEndpoint,
                couponsEndpoint: props.couponsEndpoint,
                addAddressEndpoint: props.addAddressEndpoint,
                deleteAddressEndpoint: props.deleteAddressEndpoint,
                cartSummaryEndpoint: props.cartSummaryEndpoint,
                braintreeClientTokenEndpoint: props.braintreeClientTokenEndpoint,
                placeOrderEndpoint: props.placeOrderEndpoint,
                orderConfirmationUrl: props.orderConfirmationUrl,
                updateItemQuantityEndpoint: props.updateQuantityEndpoint,
                deleteItemEndpoint: props.deleteItemEndpoint,
                refreshShippingOptionsEndpoint: props.refreshShippingOptionsEndpoint,
                setShippingMethodEndpoint: props.setShippingMethodEndpoint,
                setShippingAccountCarrierEndpoint: props.setShippingAccountCarrierEndpoint
            },
            ShippingAddressType: props.ShippingAddressType,
            BillingAddressType: props.BillingAddressType,
            allowAddingBillingAddresses: props.allowAddingBillingAddresses,
            allowAddingShippingAddresses: props.allowAddingShippingAddresses,
            countriesStatesMap: props.countriesStatesMap,
            countries: props.countries,
            braintreeSettings: {
                usePaypal: props.BraintreeClientSettings.BraintreePaypalEnabled,
                usePaypalCredit: props.BraintreeClientSettings.BraintreePaypalCreditEnabled,
                braintreePaypalFlow: props.BraintreeClientSettings.BraintreePaypalFlow,
                braintreePaymentId: props.BraintreeClientSettings.BraintreePaymentOptionId,
            },
            allowBillingEqualsShipping: props.initialData.allowBillingEqualsShipping,
            purchaseOrderPaymentId: props.purchaseOrderPaymentId
        }
    }
}


export default mapTopLevelPropsToInitialStoreState
