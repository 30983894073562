export function buildAttributesQueryString(attributes) {
    let attributeString = attributes.map(x => `${x.name};${x.value}`).reduce((prev, current) => prev + '|' + current, '');
    return attributeString;
}

export function buildQueryString(searchString, attributes, productPaginationViewModel, articlePaginationViewModel, view, selectedCategory, articleAttributes) {
    let queryParams = {};
    if (searchString)
        queryParams.searchString = searchString;
    if (productPaginationViewModel.CurrentPageIndex)
        queryParams.p = productPaginationViewModel.CurrentPageIndex;
    if (productPaginationViewModel.CurrentItemsPerPage)
        queryParams.q = productPaginationViewModel.CurrentItemsPerPage;
    if (productPaginationViewModel.CurrentSortBy)
        queryParams.s = productPaginationViewModel.CurrentSortBy;
    const attributesQueryString = buildAttributesQueryString(attributes);
    if (attributesQueryString && attributesQueryString.length > 0)
        queryParams.c = attributesQueryString;
    if (articlePaginationViewModel && articlePaginationViewModel.CurrentPageIndex)
        queryParams.articlePageIndex = articlePaginationViewModel.CurrentPageIndex;
    if (articlePaginationViewModel && articlePaginationViewModel.CurrentItemsPerPage)
        queryParams.articlePageSize = articlePaginationViewModel.CurrentItemsPerPage;
    if (articlePaginationViewModel && articlePaginationViewModel.CurrentSortBy)
        queryParams.t = articlePaginationViewModel.CurrentSortBy;
    if (view)
        queryParams.view = view;
    if (selectedCategory)
        queryParams.categoryContentLinkId = selectedCategory.CategoryContentLinkId;
    const articleAttributesQueryString = buildAttributesQueryString(articleAttributes);
    if (articleAttributesQueryString && articleAttributesQueryString.length > 0)
        queryParams.r = articleAttributesQueryString;
    return serialize(queryParams);
}

export function serialize(obj) {
    return '?' + Object.keys(obj).reduce(function (a, k) { a.push(k + '=' + encodeURIComponent(obj[k])); return a; }, []).join('&');
}