import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { translateResourceString } from '../../../util/translationUtility'

const PrintModal = ({
    PrintModalIsOpen,
    TogglePrintModal,
    HidePrices
}) => {

    const printSetting = useRef(null)
    const showPriceValue = "ShowPrice"
    const hidePriceValue = "HidePrice"

    const printAndClose = () => {
        if (printSetting.current.value === hidePriceValue) {
            HidePrices(true)
            setTimeout(() => {
                window.print()
                HidePrices(false)
            }, 10)
        } else {
            window.print()
        }

        TogglePrintModal(false)
    }

    return (
        <Modal isOpen={PrintModalIsOpen} toggle={() => TogglePrintModal(false)} centered={true} wrapClassName="test" contentClassName="edit-modal" role="document">
            <ModalHeader toggle={() => TogglePrintModal(false)} wrapTag="div" tag="span" className="headline headline-sm">
                {translateResourceString("/Wishlists/PrintModal/Header")}
            </ModalHeader>
            <ModalBody className="input-container">
                <label>{translateResourceString("/Wishlists/PrintModal/OptionsLabel")}</label>
                <select ref={printSetting}>
                    <option value={showPriceValue}>{translateResourceString("/Wishlists/PrintModal/ShowPricingOption")}</option>
                    <option value={hidePriceValue}>{translateResourceString("/Wishlists/PrintModal/HidePricingOption")}</option>
                </select>
            </ModalBody>
            <ModalFooter>
                <Button color="alt" size="xs" onClick={() => TogglePrintModal(false)}>{translateResourceString("/Wishlists/PrintModal/CancelButton")}</Button>
                <Button color="primary" size="xs" className="ml-1" onClick={() => printAndClose()}>{translateResourceString("/Wishlists/PrintModal/PrintButton")}</Button>
            </ModalFooter>
        </Modal>
    );
};

PrintModal.propType = {
    PrintModalIsOpen: PropTypes.bool.isRequired,
    TogglePrintModal: PropTypes.func.isRequired,
    HidePrices: PropTypes.func.isRequired
};

export default PrintModal;