
import {
  ADD_SHIPPING_ADDRESS,
  ADD_BILLING_ADDRESS,
  REMOVE_SHIPPING_ADDRESS,
  REMOVE_BILLING_ADDRESS,
  SELECT_BILLING_ADDRESS,
  SELECT_SHIPPING_ADDRESS,
  UPDATE_ADDRESS,
  SELECT_SHIPPING_AND_BILLING_ADDRESS,
  USE_BILLING_FOR_SHIPPING_TOGGLE
} from '../../GlobalStateContainer'

import {
  addToList,
  removeFromList,
  removeFromListById,
  replaceInList,
  replaceInListById
} from '../../../util/listUtility'

export function Addresses(state = [], action) {
  switch (action.type) {
    case ADD_BILLING_ADDRESS:
      return addToList(state, action.address);
    case ADD_SHIPPING_ADDRESS:
      return addToList(state, action.address);
    case REMOVE_BILLING_ADDRESS:
      return removeFromListById(state, action.addressId);
    case REMOVE_SHIPPING_ADDRESS:
      return removeFromListById(state, action.addressId);
    case UPDATE_ADDRESS:
      return replaceInListById(state, action.addressId, action.address);
    default:
      return state;
  }
}

export function BillingAddresses(state = [], action) {
  switch (action.type) {
    case ADD_BILLING_ADDRESS:
      return addToList(state, action.address.Id);
    case REMOVE_BILLING_ADDRESS:
      return removeFromList(state, action.addressId);
    case SELECT_SHIPPING_AND_BILLING_ADDRESS:
      return addToList(state, action.addressId)
    case UPDATE_ADDRESS:
      return replaceInList(state, action.addressId, action.address.Id);
    default:
      return state;
  }
}

export function ShippingAddresses(state = [], action) {
  switch (action.type) {
    case ADD_SHIPPING_ADDRESS:
      return addToList(state, action.address.Id);
    case REMOVE_SHIPPING_ADDRESS:
      return removeFromList(state, action.addressId);
    case UPDATE_ADDRESS:
      return (state.includes(action.addressId) && action.addressId !== action.address.ID)
        ? replaceInList(state, action.addressId, action.address.Id)
        : state;
    default:
      return state;
  }
}

export function SelectedBillingAddressId(state = '', action) {
  switch (action.type) {
    case REMOVE_BILLING_ADDRESS:
      return state === action.addressId ? '' : state;
    case SELECT_BILLING_ADDRESS:
      return action.addressId;
    case SELECT_SHIPPING_AND_BILLING_ADDRESS:
      return action.addressId;
    case UPDATE_ADDRESS:
      return state === action.addressId ? action.address.Id : state;
    default: 
      return state;
  }
}

export function SelectedShippingAddressId(state = '', action) {
  switch (action.type) {
    case REMOVE_SHIPPING_ADDRESS:
      return state === action.addressId ? '' : state;
    case SELECT_SHIPPING_ADDRESS:
      return action.addressId;
    case SELECT_SHIPPING_AND_BILLING_ADDRESS:
      return action.addressId;
    case UPDATE_ADDRESS:
      return state === action.addressId ? action.address.Id : state;
    default:
      return state;
  }
}

export function useShippingForBilling(state = true, action) {
  switch (action.type) {
    case USE_BILLING_FOR_SHIPPING_TOGGLE:
      return !state;
    default:
      return state;
  }
}
