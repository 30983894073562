import React from 'react';
import PropTypes from 'prop-types';
import Product from './Product';
import { hiddenAnchorStyle } from "../checkout/components";

class Order extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productsToShowCount: props.startOpen ? 
                Math.min(4, props.LineItemCount) 
                : 0,
            
            products: [],
            reordering: false,
            fetchedAll: false,
            fetching: false
        };

        this.fetchProducts = this.fetchProducts.bind(this)
        this.handleViewAllItemsInOrder = this.handleViewAllItemsInOrder.bind(this)
        this.handleReorder = this.handleReorder.bind(this);
        this.handleReorderError = this.handleReorderError.bind(this);
        this.handleReorderSuccess = this.handleReorderSuccess.bind(this);
    }

    componentDidMount() {
        const {
            productsToShowCount,
            products
        } = this.state;
        if(productsToShowCount > 0 && products.length === 0) {
            if(productsToShowCount >= this.props.LineItemCount) {
                this.setState({
                    fetchedAll: true
                });
            }
            this.fetchProducts(productsToShowCount);
        }
    }

    fetchProducts(productToFetchCount) {
        const {
            ProductsApiEndpoint,
            OrderNumber
        } = this.props;
        this.setState({
            fetching: true
        });
        fetch(`${ProductsApiEndpoint}?orderNumber=${OrderNumber}&skip=0&take=${productToFetchCount}`)
        .then(response => response.json())
        .then(json => this.setState({
            products: json,
            fetching: false
        }));
    }

    handleViewAllItemsInOrder() {
        if(!this.state.fetchedAll) {
            this.fetchProducts(this.props.LineItemCount);
        }

        this.setState({
            productsToShowCount: this.props.LineItemCount,
            fetchedAll: true
        });
    }

    handleReorder() {
        const {
            ReorderCartEndpoint,
            OrderNumber 
        } = this.props;

        this.setState({
            reordering: true
        });

        fetch(ReorderCartEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'fetch'
            },
            body: JSON.stringify({
                orderNumber: OrderNumber,
            })
        })
        .then(response => {
            this.setState({reOrdering: false});
            return response.json();
        })
        .then(json => {
            if(json && json.error === false) {
                this.handleReorderSuccess();
            } else {
                if(json && json.message) {
                    console.log(json.message);
                }
                this.handleReorderError();
            }
        })
        .catch(
            error => {
                console.log(error);
                this.handleReorderError();
            }
        )
    }

    handleReorderError() {
        this.setState({
            error: true,
            reordering: false
        });
    }

    handleReorderSuccess() {
        this.setState({
            error: false,
            reordering: false
        });

        if(window.MiniCart && window.MiniCart.refreshAndShow) {
            window.MiniCart.refreshAndShow();
        }
    }

    render() {
        const {
            OrderNumber,
            LineItemCount,
            OrderDate,
            Total,
            AddToCartEndpoint,
            translationUtility
        } = this.props;
        const {
            products,
            productsToShowCount,
            reordering,
            fetching
        } = this.state;
        return (
            <div className="card order-container">
                <div className="card-header">
                    <div className="float-left">{translationUtility.translateResourceString('/Reorder/OrderNumber')}: {OrderNumber}</div>
                    <div className="float-right">{OrderDate}</div>
                </div>
                <div className="card-body">
                    {productsToShowCount > 0 
                        ? <div>    
                            <div className="row">
                                <div className="col mb-2">
                                    {translationUtility.translateResourceString("/Reorder/Showing")} {products.length} {translationUtility.translateResourceString("/Reorder/Of")} {LineItemCount} {translationUtility.translateResourceString("/Reorder/Items")} {productsToShowCount < LineItemCount ? <button className="btn btn-link" style={hiddenAnchorStyle} onClick={this.handleViewAllItemsInOrder}>{translationUtility.translateResourceString("/Reorder/ViewAllItemsLink")}</button> : ""} 
                                    <button className="float-right btn btn-link" style={hiddenAnchorStyle} onClick={() => this.setState({productsToShowCount: 0})}>{translationUtility.translateResourceString("/Reorder/HideItemsLink")}</button>
                                </div>
                            </div>
                            <div className="row">
                                {products.map((product, i) => <Product {...product} key={i} addToCartEndpoint={AddToCartEndpoint} translationUtility={translationUtility} />)}
                            </div>
                            {productsToShowCount > 0 && productsToShowCount === LineItemCount && products.length !== LineItemCount && !fetching &&
                                <div className="row">
                                    <div className="col-12">
                                        {translationUtility.translateResourceString("/Reorder/UnableToLoadAllItems")}
                                    </div>
                                </div>
                            }
                        </div>
                        : <button className="btn btn-r-red-outline" onClick={this.handleViewAllItemsInOrder}>{translationUtility.translateResourceString("/Reorder/ViewAllItemsInOrderButton")}</button>
                    }
                </div>
                <div className="card-footer">
                    <div className="float-left">{translationUtility.translateResourceString("/Reorder/TotalLabel")}: {Total}</div>
                    <button disabled={reordering || products.length == 0} className="float-right btn btn-r-red" onClick={this.handleReorder}>{translationUtility.translateResourceString("/Reorder/ReorderButton")}</button>
                </div>
            </div>
        )
    }
}

Order.propTypes = {
    OrderNumber: PropTypes.string,
    LineItemCount: PropTypes.number,
    OrderDate: PropTypes.string,
    Total: PropTypes.string,
    ProductsApiEndpoint: PropTypes.string,
    AddToCartEndpoint: PropTypes.string
};

export default Order;
