import React, { useRef } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { translateResourceString } from '../../util/translationUtility'

const NewListInput = ({
    Lists,
    UpdateLists,
    ToggleNewListModal = null,
    SelectActiveTab = null,
    AddProduct = null,
    CreateListEndpoint
}) => {
    
    const newListName = useRef(null)

    const createList = (listName) => {
        if (!Lists.some(list => list.Name === listName)) {
            UpdateLists([{ Name: listName, Items: [] }, ...Lists])

            if (SelectActiveTab) SelectActiveTab(0)
            newListName.current.value = ""

            if (ToggleNewListModal != null) {
                ToggleNewListModal(false)
            }
             
            if (AddProduct != null) {
                AddProduct(listName)
            }
        }
    }

    const Create = (endpoint, listName) => {
        axios.post(endpoint, {
            listName: listName
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        createList(listName)
                        break
                    case 'Conflict':
                        alert(translateResourceString("/Wishlists/ErrorMessages/CreateList/Conflict"))
                        break
                    case 'BadRequest':
                        alert(translateResourceString("/Wishlists/ErrorMessages/CreateList/BadRequest"))
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <div className="input-group">
            <input type="text" className="form-control" placeholder={translateResourceString("/Wishlists/ViewAndAddModal/ListNamePlaceholderText")} ref={newListName} />
            <div className="input-group-append">
                <Button className="btn-xs" color="primary" onClick={() => Create(CreateListEndpoint, newListName.current.value)}>{translateResourceString("/Wishlists/ViewAndAddModal/AddButton")}</Button>
            </div>
        </div>
    );
};

NewListInput.propType = {
    Lists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string
        })
    ).isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ToggleNewListModal: PropTypes.func,
    SelectActiveTab: PropTypes.func,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default NewListInput;