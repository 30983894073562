import React from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
const plus = <i className="accordion-icon-plus" />;
const minus = <i className="accordion-icon-minus" />;
const showMoreThreshold = 10;

class Facet extends React.Component {
    componentWillMount() {
        this.setState({ expanded: true });
    }

    render() {
        const {
            name,
            displayName,
            values,
            onAttributeChange,
            expanded,
            onToggleFacetExpanded,
            updateOnToggle,
            showMore,
            onToggleShowMore,
            disableToggle
        } = this.props;
        const symbol = disableToggle ? null
            : expanded ? minus : plus;
        const sizeToShow = showMore
            ? values.length
            : Math.min(showMoreThreshold, values.length);
        const showShowMore = !showMore && values.length > showMoreThreshold;
        const Count = ({ number }) => <sup className="count">({number})</sup>
        return (
            <div className="facet">
                <div className="facet-heading" onClick={() => disableToggle ? false : onToggleFacetExpanded(name)}>
                    {symbol}
                    <span className="facet-display-name">
                        {displayName}
                    </span>
                </div>
                <Collapse isOpen={expanded || disableToggle}>
                    <div className="facet-values">

                        {values.slice(0, sizeToShow).map((value, k) => {
                            const key = `${name}-${k}`;
                            return (
                                <div key={key} className="d-flex facet-value pl-1">
                                    <input id={key} name={key} type="checkbox" checked={value.Checked} onChange={() => {
                                        // value.Checked = !value.Checked;
                                        onAttributeChange(name, value.Name);
                                    }}
                                    />
                                    <label htmlFor={key}>{value.Name}<Count number={value.Count} /></label>
                                </div>
                            )
                        })
                        }
                        {showShowMore && <a className="show-more" style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }} onClick={() => onToggleShowMore(name)}>Show More...</a>}
                    </div>

                </Collapse>
            </div>
        );
    }
}

Facet.propTypes = {
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Checked: PropTypes.bool,
        Count: PropTypes.number
    })).isRequired,
    onAttributeChange: PropTypes.func,
    expanded: PropTypes.bool,
    onToggleFacetExpanded: PropTypes.func,
    updateOnToggle: PropTypes.bool,
    showMore: PropTypes.bool,
    onToggleShowMore: PropTypes.func,
};

export default Facet;