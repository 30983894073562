export class TranslationUtility {
    constructor(localizationItems) {
        this.localizationItems = localizationItems;
    }

    translateResourceString(itemKey) {
        const translation = this.localizationItems.find(item => {
            return item.Key === itemKey;
        });
        if (translation !== undefined) {
            return translation.Value;
        }
        return itemKey;
    }

    translateResourceString(itemKey, ...formatChars) {
        const translation = this.localizationItems.find(item => {
            return item.Key === itemKey;
        });
        if (translation !== undefined) {
            return translation.Value.formatUnicorn(...formatChars);
        }
        return itemKey;
    }
}

export const translateResourceString = (itemKey, ...formatChars) => {
    if (window.clientTranslationStrings) {
        var translation = window.clientTranslationStrings[itemKey];
        if (translation !== null && translation !== undefined) {
            return window.clientTranslationStrings[itemKey].formatUnicorn(...formatChars);
        }
    }
    return itemKey;
}

export const addResourceStrings = (resourceStrings) => {
    if (!window.clientTranslationStrings) {
        window.clientTranslationStrings = resourceStrings
    } else {
        window.clientTranslationStrings = { ...window.clientTranslationStrings, ...resourceStrings };
    }
}

// String.format implementation literally from Stack Overflow: https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/18234317#18234317
String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};