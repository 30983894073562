// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'

import { RadioInput } from '../../components'

import AddressDisplay from './AddressDisplay'

import { AddressShape } from './propTypes'

let ActiveAddressInAddressSelectorList = (
  {
    address,
    isSelected,
    onSelected,
    onRadioSelected,
    onBeginEdit,
    onDelete,
    selectAddressMessage
  }) => (
    <div
      className='row'
      style={{backgroundColor: '#eee', paddingTop: '1rem', paddingBottom:  '1rem' }}
    >

        <div className='col-8 d-flex'>
            <RadioInput selected={isSelected} onClick={() => onRadioSelected(address.Id)} />
            <span>
                <AddressDisplay address={address} selected={isSelected} onClick={() => onSelected(address.Id)} compact={false} style={{ cursor: 'pointer' }}/>
                </span>
        </div>
        <div className='col-4 d-flex pl-1 justify-content-end align-items-start'>   
        <button className='btn btn-r-red-outline mr-1'
          onClick={(e) => e.preventDefault() || onBeginEdit(address.Id)}
        >
                <i className="fas fa-pencil-alt"></i>
        </button>

        <button className='btn btn-r-red-outline'
          onClick={(e) => e.preventDefault() || onDelete(address.Id)}
        >
                <i className="fas fa-trash"></i>
        </button>
            </div>
        </div>
)

ActiveAddressInAddressSelectorList.propTypes = {
  address: PropTypes.shape(AddressShape).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRadioSelected: PropTypes.func.isRequired,
  onBeginEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectAddressMessage: PropTypes.string.isRequired
}

export default ActiveAddressInAddressSelectorList
