import React from 'react';
import Loading from './Loading';
import PropTypes from 'prop-types';

const WithLoading = function(Component) {
    let withLoading = function({ isLoading, ...rest }) {
        return (
            isLoading
                ? <Loading>
                    <Component {...rest} />
                  </Loading>
                : <Component {...rest} />
        );
    };
    withLoading.displayName = `WithLoading${Component.displayName}`;
    return withLoading;
};

WithLoading.propTypes = {
    Component: PropTypes.shape({
        isLoading: PropTypes.bool
    })
};

export default WithLoading;