import React, { useState } from 'react';
import Facets from '../Facets/Facets';
import PropTypes from 'prop-types';
import WithClickTracking from '../../components/WithClickTracking';
import Article from './Article';
import MoreLink from '../../components/MoreLink';
import { translateResourceString } from '../../../util/translationUtility';
import PaginationContainer from '../Pagination/PaginationContainer';
import DropdownOption from '../../components/DropdownOption';

class ArticleSearchResultsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFacetsVisible: false,
        };
    }

    toggleFacetsVisibility = () => {
        this.setState(prevState => ({
            isFacetsVisible: !prevState.isFacetsVisible
        }));
    };

    render() {
        const { articles,
            paginationViewModel,
            onCurrentItemsPerPageChange,
            onResultClick,
            searchString,
            onToggleFacetExpanded,
            onToggleShowMoreFacet,
            attributes,
            onAttributeChange,
            expandedArticleFacets,
            onCurrentSortByChange,
            onCurrentItemsPerPageChangeHref
        } = this.props;
        const nextRequest = Math.min(paginationViewModel.CurrentItemsOnPage + paginationViewModel.ItemsPerPageIncrement, paginationViewModel.ResultsTotal);
        const showMore = paginationViewModel.ResultsTotal > paginationViewModel.CurrentItemsOnPage;
        const { isFacetsVisible } = this.state;
        let article_sort_options = [
            new DropdownOption(translateResourceString("/SearchApp/SortOptions/Relevancy/Display"), 'Relevancy', translateResourceString("/SearchApp/SortOptions/Relevancy/FullDisplay")),
            new DropdownOption(translateResourceString("/SearchApp/SortOptions/NameAZ/Display"), 'NameAZ', translateResourceString("/SearchApp/SortOptions/NameAZ/FullDisplay")),
            new DropdownOption(translateResourceString("/SearchApp/SortOptions/NameZA/Display"), 'NameZA', translateResourceString("/SearchApp/SortOptions/NameZA/FullDisplay")),
        ]

        return (
            <div>
                <div className="row">
                    <div className="col-12 mb-3 d-block d-md-none">
                        <button
                            className="btn btn-refine"
                            onClick={this.toggleFacetsVisibility}
                            aria-expanded={isFacetsVisible}>
                            {isFacetsVisible ? 'Hide Facets' : 'Refine'}
                        </button>
                    </div>
                    <div className={`facets-wrapper ${isFacetsVisible ? 'd-block' : 'd-none d-md-block'} col-md-4 col-lg-3 mb-3`}>
                        <Facets
                            attributes={attributes}
                            onAttributeChange={onAttributeChange}
                            expandedFacets={expandedArticleFacets}
                            onToggleFacetExpanded={onToggleFacetExpanded}
                            showMoreFacets={[]}
                            onToggleShowMoreFacet={onToggleShowMoreFacet}
                            disableToggle={true}
                        />
                    </div>
                    <div className="article-search-article-item-wrapper col-md-8 col-lg-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="pagination-wrapper">
                                <PaginationContainer
                                    paginationViewModel={paginationViewModel}
                                    attributes={attributes}
                                    onAttributeChange={onAttributeChange}
                                    view="Article"
                                    sortOptions={article_sort_options}
                                    onCurrentSortByChange={onCurrentSortByChange}
                                />
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="article-list-wrapper col-12 pt-3 pb-3">
                                {articles.map((article, i) => {
                                    return (
                                        <div key={i} className="row">
                                            <div className="col-md-12">
                                                <ArticleWithClickTracking {...article} onResultClick={onResultClick} />
                                            </div>
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </div>
                        {showMore &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <MoreLink
                                        disabled={false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onCurrentItemsPerPageChange(nextRequest);
                                        }}
                                        href={onCurrentItemsPerPageChangeHref}
                                    >
                                        {translateResourceString("/SearchApp/LoadMoreResources")}
                                    </MoreLink>
                                </div>
                            </div>
                        }
                    </div>

                </div>


            </div>
        );
    }
}

const ArticleWithClickTracking = WithClickTracking(Article);

ArticleSearchResultsContainer.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    paginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onPerPageChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onCurrentItemsPerPageChangeHref: PropTypes.string
};

export default ArticleSearchResultsContainer;
