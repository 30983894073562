import React from 'react'

const defaultStyle = {};
const styleWithNoBorder = {border: 'none', padding: 0, backgroundColor: 'white'};
const CheckoutSectionBody = ({ children, hideBodyBorder }) => (
  <div
        style={hideBodyBorder ? styleWithNoBorder : defaultStyle}
  >
    {children}
  </div>
)

export default CheckoutSectionBody