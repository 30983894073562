import {
    UPDATING_COUPONS
} from '../../GlobalStateContainer'

export const Coupons = function (state = [], action) {
    switch (action.type) {
        case UPDATING_COUPONS:
            console.log('UPDATING_COUPONS reducer', action);
            return action.coupons;
        default:
            return state;
    }
}
