import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

const Notification = ({
    ToggleNotification,
    IsOpen,
    ListName,
    Text
}) => {
    const message = `${Text} ${ListName} wishlist.`

    return (
        <Alert color="primary" isOpen={IsOpen} toggle={() => ToggleNotification({ IsOpen: false })}>
            <p>{message}</p>
        </Alert>          
    );
};

Notification.propType = {
    ToggleNotification: PropTypes.func.isRequired,
    IsHidden: PropTypes.bool.isRequired,
    ListName: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired
};

export default Notification;