import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import NewListInput from '../../Components/NewListInput';

const NewListModal = ({
    Lists,
    UpdateLists,
    IsOpen,
    ToggleNewListModal,
    SelectActiveTab = null,
    CreateListEndpoint
}) => {
    return (
        <Modal isOpen={IsOpen} toggle={() => ToggleNewListModal(false)} centered={true} contentClassName="list-modal new-list-modal form-gray p-0" role="document">
            <ModalHeader toggle={() => ToggleNewListModal(false)} wrapTag="div" tag="span" className="headline headline-sm">
                Create a List
                </ModalHeader>
            <ModalBody>
                <NewListInput 
                    Lists={Lists} 
                    UpdateLists={UpdateLists} 
                    ToggleNewListModal={ToggleNewListModal} 
                    SelectActiveTab={SelectActiveTab}
                    CreateListEndpoint={CreateListEndpoint} />
            </ModalBody>
        </Modal>
    );
};

NewListModal.propType = {
    Lists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string
        })
    ).isRequired,
    UpdateLists: PropTypes.func.isRequired,
    IsOpen: PropTypes.bool.isRequired,
    ToggleNewListModal: PropTypes.func.isRequired,
    SelectActiveTab: PropTypes.func,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default NewListModal;
