// eslint-disable-next-line
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ActiveAddressInAddressSelectorList from './ActiveAddressInAddressSelectorList'
import { translateResourceString } from '../../../util/translationUtility';

import { AddressShape } from './propTypes'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    const confirmDelete = () => {
        if (typeof window.confirm !== 'undefined')
            return window.confirm(translateResourceString("/Checkout/Address/DeletionConfirmationMessage"));
        else
            return false;
    }

    return {
        onSelected: id => {
            ownProps.onSelected(id);
            dispatch(ownProps.selectAddressActionCreator(id));
        },
        onRadioSelected: id => {
            dispatch(ownProps.selectAddressActionCreator(id));
            ownProps.onRadioSelected(id);
        },
        onDelete: id => {
            if (confirmDelete()) {
                ownProps.onDeleted(id);
                dispatch(ownProps.deleteAddressActionCreator({ addressId: id }));
            }
        }
    };
}

const AddressSelectorListActiveAddressContainer = connect(mapStateToProps, mapDispatchToProps)(ActiveAddressInAddressSelectorList)

AddressSelectorListActiveAddressContainer.propTypes = {
    address: PropTypes.shape(AddressShape).isRequired,
    selectAddressActionCreator: PropTypes.func.isRequired,
    deleteAddressActionCreator: PropTypes.func.isRequired,
    onBeginEdit: PropTypes.func.isRequired,
    selectAddressMessage: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onDeleted: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    onRadioSelected: PropTypes.func.isRequired
}

export default AddressSelectorListActiveAddressContainer