import PropTypes from 'prop-types'

export class CartItem { } 

CartItem.propTypes = PropTypes.shape({
  Id: PropTypes.number.isRequired,
  Price: PropTypes.string.isRequired,
  SKU: PropTypes.string,
  Quantity: PropTypes.number.isRequired
})

