import React from 'react'
import { Tab } from 'react-tabs'
import PropTypes from 'prop-types'
import EditListModalLink from './Features/EditList/EditListModalLink'

const WishlistsTab = ({ 
    Name, 
    IsActive, 
    ToggleEditListModal 
}) => {
    return (
        <Tab className={IsActive ? "list-option active" : "list-option"}>
            <h4 className="headline headline-sm ">{Name}</h4>
            <EditListModalLink
                AdditionalClasses="btn-r-red-outline"
                ToggleEditListModal={ToggleEditListModal}
                Name={Name} />
        </Tab>
    );
};

WishlistsTab.propType = {
    Name: PropTypes.string.isRequired,
    IsActive: PropTypes.bool,
    ToggleEditListModal: PropTypes.func
};

export default WishlistsTab;
