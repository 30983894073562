import React from 'react';
import range from 'lodash/range'
import PropTypes from 'prop-types';

const moreThanMaxSymbol = 'more';
const blankValue = 'blankValue';

/*
 * BBS DDD 2017-12-23 Switches between a dropdown and number input if mobile.
 * otherwise just show number input.
 *
 * This is a little convoluted.
 */
class NumberInputSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.state = { useInput: props.quantity > props.maxDropdownQuantity }
  }

  /*
   * When input changes, we receive the event from the input
   */
  onInputChange(e) {
    this.onQuantityChange(e.target.value);
  }

  /*
   * When the user moves away from the input, we receive the event from the input
   */
  onInputBlur(e) {
    const value = e.target.value;
    if (!isNaN(value)) {
      this.onQuantityChange(+value);
      return;
    }
  }

  /*
   * When we want to notify the outside world we've changed, call this.
   */
  onQuantityChange(val) {
    if (!isNaN(val)) this.props.onChange(+val);
  }

  /*
   * When the select input changes, call this with the new value selected by the user
   */
  onSelectChange(val) {
    if (val === blankValue) {
      return;
    }

    if (val === moreThanMaxSymbol) {
      this.setState({ useInput: true });
      this.onQuantityChange(this.props.maxDropdownQuantity + 1);
    } else {
      this.onQuantityChange(val);
    }
  }

  render() {
    const isMoreThanMax = this.props.quantity > this.props.maxDropdownQuantity;
    const useInput = (isMoreThanMax || this.state.useInput);
      return (
          <div className="input-container d-flex">
          <span className={this.props.componentClassName}>
            <input
                min="0"
                onInput={this.onInputChange}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                type="number"
                value={this.props.quantity}
                style={{ width: 65}}
                className="form-control"
              />
              
              </span>
              <div>
                  {this.props.onDelete &&
                      <button
                          className={'btn btn-link p-0 item-delete' + (this.state.useInput ? '' : ' ')}
                          onClick={(e) => { e.preventDefault(); this.props.onDelete(); }}
                          style={{ marginLeft: '2rem' }}
                      >
                      <span aria-hidden="true" className="fas fa-trash" />
                          <span className="sr-only">Delete</span>
                      </button>
                  }
              </div>
          </div>
    )
  }
}

NumberInputSelect.propTypes = {
  componentClassName: PropTypes.string,
  maxDropdownQuantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired
}

export default NumberInputSelect
