import React from 'react';
import PropTypes from 'prop-types';

class MobileSelectedFacets extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('./MobileSelectedFacets.css');
    }

    render() {
        const { attributes, onAttributeChange, onClearAttributes } = this.props;

        const selectedAttributeValueArrays = attributes.map(function (attribute) {
            return attribute.Values.filter(
                value => value.Checked).map(value => {
                    return {
                        attributeName: attribute.Name,
                        attributeValueName: value.Name
                    };
                });
        });

        const mergedAttributeValueArrays = [].concat.apply([], selectedAttributeValueArrays);
        const showMobileSelectedFacets = mergedAttributeValueArrays.length > 0;

        return (
            <div>
                {showMobileSelectedFacets &&
                    <div className="mobile-selected-facets">
                        <a onClick={onClearAttributes}>
                            Clear Filters
                            </a>
                        {
                            mergedAttributeValueArrays.map((attribute, i) => {
                                return (
                                    <div className="mobile-selected-facet" key={i}>
                                        {attribute.attributeValueName} <a onClick={() => onAttributeChange(attribute.attributeName, attribute.attributeValueName)}><i className="fa fa-times" /></a>
                                    </div>
                                );
                            })
                        }
                        <div className="clear"/>
                    </div>
                }
            </div>
        );
    }
}

MobileSelectedFacets.propTypes = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })).isRequired,
    onAttributeChange: PropTypes.func.isRequired,
    onClearAttributes: PropTypes.func.isRequired
};

export default MobileSelectedFacets;