import {
    NOTIFY_ERROR,
    DISMISS_MESSAGE,
    DISMISS_ALL_MESSAGES,
    NOTIFY_COMPLETE_CHECKOUT_ERROR
} from '../../GlobalStateContainer'

import {
    ERROR_USER_ALERT,
    UNKNOWN_USER_ALERT
} from './userAlertTypes'

import {
    addToList,
    replaceInListById
} from '../../../util/listUtility'

import { translateResourceString } from '../../../util/translationUtility';

const mapActionTypeToUserAlertType = (actionType) => {
    switch (actionType) {
        case NOTIFY_ERROR:
        case NOTIFY_COMPLETE_CHECKOUT_ERROR:
            return ERROR_USER_ALERT;
        default:
            return UNKNOWN_USER_ALERT;
    }
}

export function messages(state = [], action) {
    switch (action.type) {
        case NOTIFY_ERROR:
            return addToList(
                state,
                {
                    type: mapActionTypeToUserAlertType(action.type),
                    text: action.message,
                    Id: action.messageId
                });
        case NOTIFY_COMPLETE_CHECKOUT_ERROR:
            return addToList(
                state,
                {
                    type: mapActionTypeToUserAlertType(action.type),
                    text: action.message || translateResourceString("/Checkout/UserMessaging/CompleteCheckoutError"),
                    Id: action.messageId
                });
        case DISMISS_MESSAGE:
            return replaceInListById(
                state,
                action.messageId,
                Object.assign({}, state.filter(m => m.Id === action.messageId)[0], { dismissed: true }))
        case DISMISS_ALL_MESSAGES:
            return [];
        default:
            return state;
    }
}
