import {
  UPDATE_ITEM_QUANTITY,
  DELETE_ITEM
} from '../../GlobalStateContainer'


import { removeFromListById } from '../../../util/listUtility'

export const CartItems = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ITEM_QUANTITY:
      return state.map(item =>
        (item.Id === action.itemId)
          ? Object.assign({}, item, { Quantity: action.newQuantity })
          : item);
    case DELETE_ITEM:
      return removeFromListById(state, action.itemId);
    default:
      return state;
  }
}
