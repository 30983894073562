import React from 'react'
import PropTypes from 'prop-types'

class RadioInput extends React.Component {

  componentDidMount() {
    require('./RadioInput.css');
  }

  render() {

    return (
      <input
        className="Radio"
        type='radio'
        checked={this.props.selected}
        onClick={() => this.props.onClick()}
        readOnly />
    )
  }
}

RadioInput.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default RadioInput