import React from 'react'
import PropTypes from 'prop-types'

import CheckoutSectionHeader from './CheckoutSectionHeader'
import CheckoutSectionBody from './CheckoutSectionBody'

const CheckoutSection = ({ label, children, classname, hidden = false, hideBodyBorder = false }) => (
    <div>
        <CheckoutSectionHeader label={label} />
        <div
            className={classname || label.toLowerCase().replace(' ', '-') + (hidden ? " d-none " : " bg-light-gray p-2")}
            style={{ marginBottom: "2rem" }}
        >
            <CheckoutSectionBody hideBodyBorder={hideBodyBorder}>
                {children}
            </CheckoutSectionBody>
        </div>
    </div>
)

CheckoutSection.propTypes = {
  label: PropTypes.string.isRequired,
  classname: PropTypes.string,
  hidden: PropTypes.bool,
  hideBodyBorder: PropTypes.bool
}

export default CheckoutSection
