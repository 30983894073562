import React, { useState } from 'react';
import Facets from '../Facets/Facets';
import PaginationContainer from '../Pagination/PaginationContainer';
import ProductTileContainer from './ProductTileContainer';
import PropTypes from 'prop-types';
import MoreButton from '../../components/MoreButton';
import MoreLink from '../../components/MoreLink';
import MobileSelectedFacets from '../Facets/MobileSelectedFacets';
import CategoriesList from '../Categories/CategoriesList';
import { translateResourceString } from '../../../util/translationUtility';
import DropdownOption from '../../components/DropdownOption';

const ProductSearchResultsContainer = ({ attributes,
    onAttributeChange,
    onClearAttributes,
    paginationViewModel,
    onCurrentItemsPerPageChange,
    onCurrentSortByChange,
    products,
    recommendedProducts,
    onResultClick,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    showMoreFacets,
    onToggleShowMoreFacet,
    promotionalArea,
    categories,
    onCategoryPage,
    onCategorySelect,
    selectedCategory,
    openCategories,
    onToggleCategoryOpen,
    searchString,
    onCurrentItemsPerPageChangeHref
}) => {
    //const showClearAttributes = attributes.filter(
    //    attribute => attribute.Values.filter(
    //        value => value.Checked).length > 0).length > 0;
    const perMoreAmount = 10;
    const nextRequest = Math.min(paginationViewModel.CurrentItemsOnPage + perMoreAmount, paginationViewModel.ResultsTotal);
    const showMore = paginationViewModel.ResultsTotal > paginationViewModel.CurrentItemsOnPage;
    var recommendationsHeading = "Trending in Category";
    const promotionalAreaHtml = {
        __html: promotionalArea
    };
    const [isFacetsVisible, setIsFacetsVisible] = useState(false);
    const toggleFacetsVisibility = () => {
        setIsFacetsVisible(prevState => !prevState);
    };
    let product_sort_options = [
        new DropdownOption(translateResourceString("/SearchApp/SortOptions/Relevancy/Display"), 'Relevancy', translateResourceString("/SearchApp/SortOptions/Relevancy/FullDisplay")),
        new DropdownOption(translateResourceString("/SearchApp/SortOptions/NameAZ/Display"), 'NameAZ', translateResourceString("/SearchApp/SortOptions/NameAZ/FullDisplay")),
        new DropdownOption(translateResourceString("/SearchApp/SortOptions/NameZA/Display"), 'NameZA', translateResourceString("/SearchApp/SortOptions/NameZA/FullDisplay")),
        new DropdownOption(translateResourceString("/SearchApp/SortOptions/References/Display"), 'References', translateResourceString("/SearchApp/SortOptions/References/FullDisplay")),
    ];

    return (
        <div>
            <div className="row">
                <div className="col-12 mb-3 d-block d-md-none">
                    <button
                        className="btn btn-refine"
                        onClick={toggleFacetsVisibility}
                        aria-expanded={isFacetsVisible}>
                        Refine
                    </button>
                </div>
                <div className={`facets-wrapper ${isFacetsVisible ? 'd-block' : 'd-none d-md-block'} col-md-4 col-lg-3 mb-3`}>
                    <CategoriesList
                        categories={categories}
                        onCategoryPage={onCategoryPage}
                        onSelect={onCategorySelect}
                        openCategories={openCategories}
                        onToggleCategoryOpen={onToggleCategoryOpen}
                    />
                    <Facets
                        attributes={attributes}
                        onAttributeChange={onAttributeChange}
                        onClearAttributes={onClearAttributes}
                        expandedFacets={expandedFacets}
                        onToggleFacetExpanded={onToggleFacetExpanded}
                        showMoreFacets={showMoreFacets}
                        onToggleShowMoreFacet={onToggleShowMoreFacet}
                    />
                    {promotionalArea && <div className="promotional-area" dangerouslySetInnerHTML={promotionalAreaHtml}></div>}
                </div>
                <div className="product-list-wrapper col-md-8 col-lg-9">
                    {recommendedProducts && recommendedProducts.length > 0 &&
                        <div className="row">
                            <div className="product-list col-12 js-recommendations-list">
                                <div className="product-section-header">
                                    <h4 className="heading-orange">{recommendationsHeading}</h4>
                                </div>
                                <ProductTileContainer products={recommendedProducts} onResultClick={onResultClick} />
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12">
                            <PaginationContainer
                                paginationViewModel={paginationViewModel}
                                onCurrentSortByChange={onCurrentSortByChange}
                                onShowMobileFacetsChange={onShowMobileFacetsChange}
                                selectedCategory={selectedCategory}
                                onSelectCategory={onCategorySelect}
                                attributes={attributes}
                                onAttributeChange={onAttributeChange}
                                view="Product"
                                sortOptions={product_sort_options}
                            />
                        </div>
                    </div>
                    {/* Not needed */}
                    {/* <div className="row d-md-none">
                        <div className="col-12">
                            <MobileSelectedFacets
                                attributes={attributes}
                                onAttributeChange={onAttributeChange}
                                onClearAttributes={onClearAttributes}
                            />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="product-list col-12 js-product-list">
                            <ProductTileContainer products={products} onResultClick={onResultClick} />
                        </div>
                    </div>
                    {showMore &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <MoreLink
                                    disabled={false}
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        onCurrentItemsPerPageChange(nextRequest);
                                    }}
                                    href={onCurrentItemsPerPageChangeHref}
                                >
                                    {translateResourceString("/SearchApp/LoadMoreProducts")}
                                </MoreLink>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

ProductSearchResultsContainer.propType = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentSortByChange: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    recommendedProducts: PropTypes.arrayOf(PropTypes.shape({
        RecommendationId: PropTypes.number,
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    onShowMobileFacetsChange: PropTypes.func.isRequired,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
    promotionalArea: PropTypes.string,
    onCurrentItemsPerPageChangeHref: PropTypes.string
};

export default ProductSearchResultsContainer;
