import { NOTIFY_ERROR, NOTIFY_COMPLETE_CHECKOUT_ERROR, LOG_ERROR, DISMISS_MESSAGE, DISMISS_ALL_MESSAGES } from '../../GlobalStateContainer'

/* ERROR */
let messageId = 1;

export function notifyError(message, diagnosticMessage) {
    diagnosticMessage && console.error(diagnosticMessage) && console.trace && console.trace();
    return { type: NOTIFY_ERROR, message, messageId: messageId++ }
}

export function notifyCompleteCheckoutError(message, diagnosticMessage) {
    diagnosticMessage && console.error(diagnosticMessage) && console.trace && console.trace();
    return { type: NOTIFY_COMPLETE_CHECKOUT_ERROR, message, messageId: messageId++ }
}

export function logError(diagnosticMessage) {
    diagnosticMessage && console.error(diagnosticMessage) && console.trace && console.trace();
    return { type: LOG_ERROR, diagnosticMessage }
}

export function dismissMessage(messageId) {
    return { type: DISMISS_MESSAGE, messageId }
}

export function dismissAllMessages() {
    return { type: DISMISS_ALL_MESSAGES }
}