import {
  SELECT_PAYMENT_OPTION,
  BRAINTREEE_PAYMENT_DATA_UPDATE,
  PURCHASE_ORDER_PAYMENT_DATA_UPDATE
} from '../../GlobalStateContainer'
import {checkoutSteps, sendCheckoutStepToDataLayer} from "../../../util/gtm";
import { find } from 'lodash';
/* PAYMENT METHOD */

export const selectPaymentOption = (paymentMethodId, displayName) => {
  return { type: SELECT_PAYMENT_OPTION, paymentMethodId, displayName }
}

export const sendPaymentMethodToGtmDatalayer = () => (dispatch, getState) => {
  const state = getState();
  const selected = find(state.entities.PaymentOptions, po => po.Id === state.entities.SelectedPaymentOptionId);
  if(selected)
    sendCheckoutStepToDataLayer(
      checkoutSteps.confirmPaymentMethod,
      state.entities.CartItems.map(ci => ci.GoogleTagManagerProductFieldObject),
      state.constants.navigation.checkoutUrl,
      selected ? selected.Name : "");
}
export function updateBraintreePaymentData(payload) {
  return { type: BRAINTREEE_PAYMENT_DATA_UPDATE, payload }
}

export function updatePurchaseOrderNumber(number) {
  return { type: PURCHASE_ORDER_PAYMENT_DATA_UPDATE, number }
}
