export function buildSelectedAttributesFromModel(attributes) {
    const selectedAttributes = [];

    attributes.forEach(attribute => {
        if (attribute.Values) {
            const attributeValues = attribute.Values.filter(
                value => value.Checked && value.Checked === true);

            attributeValues.forEach(attributeValue => {
                selectedAttributes.push({
                    name: attribute.Name,
                    value: attributeValue.Name
                });
            });
        }
    });

    return selectedAttributes;
}

export function buildFacetNamesFromModel(attributes) {
    if (!attributes) {
        return [];
    }

    return attributes.filter(attribute => attribute.Name)
        .map(attribute => attribute.Name);
}

export function buildStateFromModel({
    SearchString,
    Products,
    Recommendations,
    View,
    FindClickTracking,
    Suggestions,
    Articles,
    PromotionalArea,
    ZeroProductResultsMessage,
    PopularCategories,
    ZeroResourceResultsMessage,
    FeaturedResources,
    ResourceTypes
}) {
    const selectedCategory = Products && Products.CategoryFilters && Products.CategoryFilters.length > 0 ? getSelectedCategory(Products.CategoryFilters) : null;

    const selectedParentCategory = Products && Products.CategoryFilters 
        ? Products && Products.CategoryFilters.find(c => c.Selected || c.Children.find(c => c.Selected))
        : null;

    const defaultOpenCategory = selectedParentCategory
        ? selectedParentCategory.CategoryContentLinkId
        : null;

    const productAttributes = getAttributes(Products);
    const articleAttributes = getAttributes(Articles);
    const selectedAttributes = buildSelectedAttributesFromModel(productAttributes);

    const state = {
        // Search and other search related state
        searchString: SearchString ? SearchString : SearchString,
        suggestions: Suggestions,
        promotionalArea: PromotionalArea,
        view: View,
        isLoading: false,
        findClickTrackingViewModel: FindClickTracking,
        showMobileFacets: false,
        zeroProductResultsMessage: ZeroProductResultsMessage,
        zeroResourceResultsMessage: ZeroResourceResultsMessage,

        // Products
        selectedAttributes: selectedAttributes,
        attributes: productAttributes,
        expandedFacets: expandedFacets(productAttributes, selectedAttributes),
        showMoreFacets: [],
        products: Products && Products.ProductItems ? Products.ProductItems : [],
        recommendedProducts: Recommendations ? getRecommendedProducts(Recommendations) : [],
        productPaginationViewModel: Products && Products.PaginationViewModel ? Products.PaginationViewModel : null,
        popularCategories: PopularCategories,
        onCurrentProductsPerPageChangeHref: "",
        
        // Product - Category Filters
        categories: Products && Products.CategoryFilters ? Products.CategoryFilters : [],
        selectedCategory: selectedCategory,
        openCategories: defaultOpenCategory ? [defaultOpenCategory] : [],

        // Article
        articles: Articles && Articles.ArticleItemViewModels ? Articles.ArticleItemViewModels : [],
        articleAttributes: articleAttributes,
        selectedArticleAttributes: buildSelectedAttributesFromModel(articleAttributes),
        articleShowMoreFacets: [],
        expandedArticleFacets: expandedArticleFacets(articleAttributes),
        articlePaginationViewModel: Articles && Articles.PaginationViewModel ? Articles.PaginationViewModel : null,
        featuredResources: FeaturedResources,
        resourceTypes: ResourceTypes,
        onCurrentArticlesPerPageChangeHrf: ""
    };

    return state;
}

function getRecommendedProducts(recommendations) {
    return recommendations.slice(0, 3).map(({ RecommendationId, TileViewModel }) => {
        return {
            RecommendationId,
            Name: TileViewModel.Name,
            Url: TileViewModel.Url,
            Image: TileViewModel.Image,
            Description: TileViewModel.Description
        };
    });
}

export function getSelectedCategory(categories) {
    const flattenedCategories = flattenCategories(categories);
    const selected = flattenedCategories.filter(category => category.Selected && category.Selected === true);
    return selected.length > 0 ? selected[0] : null;
}

function flattenCategories(categories) {
    const flattenedCategories = [];
    categories.forEach(category => {
        flattenedCategories.push(category);
        if (category.Children && category.Children.length > 0) {
            flattenedCategories.push(...flattenCategories(category.Children.map(child => ({ ...child, ParentCategory: category }))));
        }
    });
    return flattenedCategories;
}

export function getAttributes(viewModel) {
    return viewModel && viewModel.Filters && viewModel.Filters.Attributes ? viewModel.Filters.Attributes : [];
}

export function expandedFacets(attributes, selectedAttributes) {
    const selectedAttributeNames = selectedAttributes.map(attribute => attribute.name);
    const orderedFacets = selectedAttributeNames
        .concat(attributes.filter(a => selectedAttributeNames.indexOf(a.Name) < 0).map(a => a.Name));
    const minLength = selectedAttributes.length;
    const maxLength = Math.min(3, attributes.length);
    const length = Math.max(maxLength, minLength);
    return orderedFacets.splice(0, length);
}

export function expandedArticleFacets(articleAttributes){
    return articleAttributes.map(attribute => attribute.Name);
}