import React from 'react';
import PropTypes from 'prop-types';

const WithClickTracking = function(Component) {
    let withClickTracking = function({ onResultClick, HitId, ...rest }) {
        return (
            onResultClick && HitId 
                ? <Component onClick={() => onResultClick(HitId)} {...rest} />
                : <Component {...rest}  />
        );
    };
    withClickTracking.displayName = `WithClickTracking${Component.displayName}`;
    return withClickTracking;
};

WithClickTracking.propTypes = {
    Component: PropTypes.shape({
        onResultClick: PropTypes.func,
        HitId: PropTypes.string
    })
};

export default WithClickTracking;