import React from 'react';
import ProductSearchResultsContainer from './ProductSearchResultsContainer';
import ProductSearchResultsNonFound from './ProductSearchResultsNonFound';
import PropTypes from 'prop-types';
import { translateResourceString } from '../../../util/translationUtility';

const ProductSearchResults = ({
    attributes,
    articlePaginationViewModel,
    onAttributeChange,
    onClearAttributes,
    paginationViewModel,
    onCurrentItemsPerPageChange,
    onCurrentSortByChange,
    onCurrentPageIndexChange,
    onChangeView,
    products,
    recommendedProducts,
    onResultClick,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    categoryName,
    categoryImageUrl,
    categoryImageAltText,
    categoryDescription,
    showMoreFacets,
    onToggleShowMoreFacet,
    promotionalArea,
    categories,
    onCategoryPage,
    onCategorySelect,
    selectedCategory,
    openCategories,
    onToggleCategoryOpen,
    searchString,
    zeroProductResultsMessage,
    popularCategories,
    suggestions,
    onCurrentItemsPerPageChangeHref
}) => {
    return (
        <div className="pt-4">
            {searchString &&
                <h3 className="search-results-text headline headline-xl mb-4">{paginationViewModel.ResultsTotal} {translateResourceString("/SearchApp/ProductResultsText")} '<span className="search-string">{searchString}</span>'</h3>
            }
            {onCategoryPage || products.length > 0 ?
                <ProductSearchResultsContainer
                    articlePaginationViewModel={articlePaginationViewModel}
                    attributes={attributes}
                    onAttributeChange={onAttributeChange}
                    onClearAttributes={onClearAttributes}
                    paginationViewModel={paginationViewModel}
                    onCurrentItemsPerPageChange={onCurrentItemsPerPageChange}
                    onCurrentSortByChange={onCurrentSortByChange}
                    onCurrentPageIndexChange={onCurrentPageIndexChange}
                    products={products}
                    recommendedProducts={recommendedProducts}
                    onResultClick={onResultClick}
                    onShowMobileFacetsChange={onShowMobileFacetsChange}
                    expandedFacets={expandedFacets}
                    onToggleFacetExpanded={onToggleFacetExpanded}
                    categoryName={categoryName}
                    categoryImageUrl={categoryImageUrl}
                    categoryImageAltText={categoryImageAltText}
                    categoryDescription={categoryDescription}
                    showMoreFacets={showMoreFacets}
                    onToggleShowMoreFacet={onToggleShowMoreFacet}
                    promotionalArea={promotionalArea}
                    categories={categories}
                    onCategoryPage={onCategoryPage}
                    onCategorySelect={onCategorySelect}
                    selectedCategory={selectedCategory}
                    openCategories={openCategories}
                    onToggleCategoryOpen={onToggleCategoryOpen}
                    searchString={searchString}
                    onCurrentItemsPerPageChangeHref={onCurrentItemsPerPageChangeHref}
                />
                : <ProductSearchResultsNonFound 
                    zeroProductResultsMessage={zeroProductResultsMessage}
                    popularCategories={popularCategories}
                    suggestions={suggestions}
                    articlePaginationViewModel={articlePaginationViewModel}
                    onChangeView={onChangeView}
                    searchString={searchString}
                 />
            }
        </div>
    );
};

ProductSearchResults.propType = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    articlePaginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number,
        ItemsPerPageIncrement: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentSortByChange: PropTypes.func,
    onCurrentPageIndexChange: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    recommendedProducts: PropTypes.arrayOf(PropTypes.shape({
        RecommendationId: PropTypes.number,
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
    promotionalArea: PropTypes.string,
    onCategoryPage: PropTypes.bool,
    onCategorySelect: PropTypes.func,
    onChangeView: PropTypes.func,
    onCurrentItemsPerPageChangeHref: PropTypes.string
};

export default ProductSearchResults;
