import {
    UPDATING_COMMENTS
} from '../../GlobalStateContainer'

export const Comments = function (state = [], action) {
    switch (action.type) {
    case UPDATING_COMMENTS:
            console.log('UPDATING_COMMENTS reducer', action);
            return Object.assign({}, state, { userCommentsValue: action.comments });
    default:
        return state;
    }
}

