// https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
export const sendCheckoutStepToDataLayer = (checkoutStep, products, location, option) => {
    if (window && window.dataLayer) {
        try {
            const actionField = {
                'step': checkoutStep
            };
            const currencyCode = products[0].currencyCode ? products[0].currencyCode : products[0].CurrencyCode;

            if(option)
                actionField.option = option;

            window.dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'currencyCode': currencyCode,
                    'checkout': {
                        'actionField': actionField,
                        'products': products.map((product) => {
                                const p =
                                    {
                                        'name': product.name || product.Name,
                                        'id': product.id || product.Id,
                                        'price': product.price || product.Price,
                                        'brand': product.brand || product.Brand,
                                        'category': product.category || product.Category,
                                        'variant': product.variant || product.Variant,
                                    }
                                    const quantity = product.quantity || product.Quantity;
                                    if(quantity)
                                        p.quantity = parseInt(quantity);
                                return p;
                            }
                        )
                    },
                    'eventCallback': function () {
                        document.location = location;
                    }
                }
            });
        } catch (e) {
            console.log("Error pushing to gtm", e)
        }
    }
}

export const addToList = function (quantity, product) {
    window.dataLayer.push({
        'event': 'add_to_wishlist',
        'ecommerce': {
            'currencyCode': product.currencyCode || product.CurrencyCode,
            'value': product.price || product.Price,
            'items': [{
                'item_id': product.id || product.Id,
                'item_name': product.name || product.Name,
                'item_brand': product.brand || product.Brand,
                'item_category': product.category || product.Category,
                'item_variant': product.variant || product.Variant,
                'price': product.price || product.Price,
                'quantity': quantity
            }]
        }
    });
}

export const checkoutSteps = {
    pageLoad: 1,
    selectShippingMethod: 2,
    applyCoupon: 3,
    confirmPaymentMethod: 4
}
