import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'

// BBS DDD 2017-11-20 Assumes all list elements that 
// are composite will have an "Id" element

// If we change something, then return a new list, not the list we 
// got as an argument. If we change nothing, then return the list
// we got as an argument.

// Adds to list at front
export const addToList = (list, newElement) => {
  if (newElement.hasOwnProperty("Id"))
    return [newElement].concat(list.filter(elt => elt.Id !== newElement.Id));
  return [newElement].concat(list.filter(elt => elt !== newElement));
}

// Returns new list if element exists, or same list
export const removeFromList = (list, elementToRemove) => {
    return list.filter(elt => elt !== elementToRemove);
}

export const removeFromListById = (list, id) => {
    return list.filter(elt => elt.Id !== id);
}

// Returns new list if element exists, or same list
export const replaceInList = (list, valueToReplace, newValue) => {
  if (!includes(list, valueToReplace))
    return list;
  return list.map(elt => elt === valueToReplace ? newValue : elt);
}

// Returns new list if element exists, or same list
export const replaceInListById = (list, elementToUpdateId, newElement) => {
  if (findIndex(list, elt => elt.Id === elementToUpdateId) < 0)
    return list;
  return list.map(elt => elt.Id === elementToUpdateId ? newElement : elt);
 }